import { Utils } from "../Utils/Utils"

export const getDataMapboxVectorTile = async (place, succesCallback, failureCallback) => {
  const urlData = `${Utils.URL_SERVER}/data/${place}.json`;
  fetch(urlData)
    .then(async (response) => {
        // handle the response
        const data = await response.text();
        succesCallback(JSON.parse(data));
    })
    .catch(error => {
        // handle the error
        failureCallback(null);
    });
}

export const getSearchFeatureData = async (succesCallback, failureCallback) => {
    const urlData = Utils.URL_SEARCH;
    fetch(urlData)
      .then(async (response) => {
          // handle the response
          const data = await response.text();
          succesCallback(JSON.parse(data));
      })
      .catch(error => {
          // handle the error
          failureCallback(null);
      });
  }