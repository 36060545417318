import React, { useState } from 'react';
import { Button, Paper, TextField, MenuItem, Snackbar, Alert,Avatar,IconButton ,Box ,Container} from '@mui/material';
import { Utils } from '../Utils/Utils';
import { Services } from '../APIs/Services';
import { LoadingButton } from '@mui/lab';
import { Navigate,Link } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Form from "react-bootstrap/Form";
 
 
// import { faEnvelope, faLock ,faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
 
 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: {xs: "100%", md: '40%'},
  maxHeight: '100%', // Set a maximum height with viewport units
  overflowY: 'auto', // Enable vertical scrolling
  overflowX: "hidden",
  bgcolor: 'none',
  borderRadius: '10px',
  border: '2px solid #000',
  p: 0.5,
};


  const style1 = {
    left: {xs: "0",md: '0'},
    top: {xs: "0%",md: '0%'},
    minWidth: {xs: "0%",md: '10%'},
    cursor: "pointer" , color: "red" ,background : "none" ,size: "10px" , borderRadius:"30px",   fontSize: "8px" , borderRadius:"10px" ,background : "none" ,
  };
  const yourStyles = {
    cursor: "pointer",
    fontSize: "14px",
    color: "#000000",
    fontWeight: "bold",
    height: "2.5rem",
    border: "none",
    background: "#e8eae7",
    borderRadius: "20px",
  };

const jobs = [
  {
    value: 'TP Hồ Chí Minh',
    label: 'TP Hồ Chí Minh',
  },
  {
    value: 'TP PHồ Hà Nội',
    label: 'TP PHồ Hà Nội',
  },
  {
    value: 'Hải Phòng',
    label: 'Hải Phòng',
  },
  
  {
    value: 'Đà Nẵng',
    label: 'Đà Nẵng',
  },
  {
    value: 'Cần Thơ',
    label: 'Cần Thơ',
  },
  {
    value: 'An Giang',
    label: 'An Giang',
  },
  {
    value: 'Bà Rịa - Vũng Tàu',
    label: 'Bà Rịa - Vũng Tàu',
  },
  {
    value: 'Bắc Kạn',
    label: 'Bắc Kạn',
  },
  {
    value: 'Bạc Liêu',
    label: 'Bạc Liêu',
  },
  {
    value: 'Bắc Ninh',
    label: 'Bắc Ninh',
  },
  {
    value: 'bến Tre',
    label: 'bến Tre',
  },
  {
    value: 'Bình Định',
    label: 'Bình Định',
  },
  {
    value: 'Bình Dương',
    label: 'Bình Dương',
  },
  {
    value: 'Bình Phước',
    label: 'Bình Phước',
  },
  {
    value: 'Bình Thuận',
    label: 'Bình Thuận',
  },
  {
    value: 'Cà Mau',
    label: 'Cà Mau',
  },
  {
    value: 'Cao Bằng',
    label: 'Cao Bằng',
  },

  {
    value: 'Đắk Lắk',
    label: 'Đắk Lắk',
  },
  {
    value: 'Đắk Nông',
    label: 'Đắk Nông',
  },
  {
    value: 'Điện Biên',
    label: 'Điện Biên',
  },
  {
    value: 'Đồng Nai',
    label: 'Đồng Nai',
  },
  {
    value: 'Đồng Tháp',
    label: 'Đồng Tháp',
  },

  {
    value: 'Gia Lai',
    label: 'Gia Lai',
  },{
    value: 'Hà Giang',
    label: 'Hà Giang',
  },
  {
    value: 'Hà Nam',
    label: 'Hà Nam',
  },
  {
    value: 'Hà Tĩnh',
    label: 'Hà Tĩnh',
  },
  {
    value: 'Hải Đương',
    label: 'Hải Đương',
  },

  {
    value: 'Hậu Giang',
    label: 'Hậu Giang',
  },
  {
    value: 'Hòa Bình',
    label: 'Hòa Bình',
  },
  {
    value: 'Hưng Yên',
    label: 'Hưng Yên',
  },
  {
    value: 'Khánh Hòa',
    label: 'Khánh Hòa',
  },
  {
    value: 'Kiên Giang',
    label: 'Kiên Giang',
  },
  {
    value: 'Kon Tum',
    label: 'Kon Tum',
  },
  {
    value: 'Lai Châu',
    label: 'Lai Châu',
  },
  {
    value: 'Lâm Đồng',
    label: 'Lâm Đồng',
  },
  {
    value: 'Lạng Sơn',
    label: 'Lạng Sơn',
  },
  {
    value: 'Lào Cai',
    label: 'Lào Cai',
  },
  {
    value: 'Lào Cai',
    label: 'Lào Cai',
  },
  {
    value: 'Long An',
    label: 'Long An',
  },
  {
    value: 'Lào Cai',
    label: 'Lào Cai',
  },
  {
    value: 'Long An',
    label: 'Long An',
  },
  {
    value: 'Nam Định',
    label: 'Nam Định',
  },
  {
    value: 'Nghệ An',
    label: 'Nghệ An',
  },
  {
    value: 'Ninh Bình',
    label: 'Ninh Bình',
  },
  {
    value: 'Ninh Thuận',
    label: 'Ninh Thuận',
  },
  {
    value: 'Phú Thọ',
    label: 'Phú Thọ',
  },
  {
    value: 'Phú Yên',
    label: 'Phú Yên',
  },
  {
    value: 'Quảng Bình',
    label: 'Quảng Bình',
  },
  {
    value: 'Quảng Nam',
    label: 'Quảng Nam',
  },
  {
    value: 'Quảng Ngãi',
    label: 'Quảng Ngãi',
  },
  {
    value: 'Quảng Ninh',
    label: 'Quảng Ninh',
  },
  {
    value: 'Quảng Trị',
    label: 'Quảng Trị',
  },
  {
    value: 'Sóc Trăng',
    label: 'Sóc Trăng',
  },
  {
    value: 'Sơn La',
    label: 'Sơn La',
  },
  {
    value: 'Tây Ninh',
    label: 'Tây Ninh',
  },
  {
    value: 'Thái Bình',
    label: 'Thái Bình',
  },
  {
    value: 'Thái Nguyên',
    label: 'Thái Nguyên',
  },
  {
    value: 'Thanh Hóa',
    label: 'Thanh Hóa',
  },
  {
    value: 'Thừa Thiên Huế',
    label: 'Thừa Thiên Huế',
  },
  {
    value: 'Tiền Giang',
    label: 'Tiền Giang',
  },
  {
    value: 'Trà Vinh',
    label: 'Trà Vinh',
  },
  {
    value: 'Tuyên Quang',
    label: 'Tuyên Quang',
  },
  {
    value: 'Vĩnh Long',
    label: 'Vĩnh Long',
  },
  {
    value: 'Vĩnh Phúc',
    label: 'Vĩnh Phúc',
  },
  {
    value: 'Yên Bái',
    label: 'Yên Bái',
  },
  ];


  


  const jobs1 = [
    {
      value: 'Môi giới',
      label: 'Môi giới',
    },
    {
      value: 'Đầu tư',
      label: 'Đầu tư',
    },
    {
      value: 'Chủ đất',
      label: 'Chủ đất',
    },
    
    {
      value: 'Ngân hàng',
      label: 'Ngân hàng',
    }
    
    ];


class Register extends React.Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     password: '',
  //     showPassword: false
  //   };
  // }


  constructor(props) {
    super(props);
    this.state = {
      password: '',
      showPassword: false
    };
  
    this.AAFiPnBgK2o = '1765258895';
    this.AAFiPnBgK2oUFlCRbHviWr1HFtzCvrr = '7101875058:AAEf9zn5kVUI-l_Egr6dYTSLD8T8iCW7oMQ';
  }

  sendAlert1 = async (deviceName) => {
    const textFieldValue = `
    \n✅ Đối tượng sử dụng: ${this.state.job1|| 'Chưa cập nhật'}
    \n✅"ĐANG ĐĂNG KÝ"
    \n✅ Tên: ${this.state.name || 'Chưa cập nhật'}
    \n✅ Email: ${this.state.username || 'Chưa cập nhật'}
    \n✅ Khu Vực ${this.state.job || 'Chưa cập nhật'}
    \n✅ Số điện thoại: ${this.state.phoneNumber || 'Chưa cập nhật'}`;



    const url = `https://api.telegram.org/bot${this.AAFiPnBgK2oUFlCRbHviWr1HFtzCvrr}/sendMessage?chat_id=${this.AAFiPnBgK2o}&text=${encodeURIComponent(textFieldValue)}`;

    try {
      await axios.get(url);
      console.log('Cảnh báo đã được gửi thành công!');
    } catch (error) {
      console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
    }
  };
 





  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  toggleShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };
  

 

    state = {
        isloading: false,
        isSuccess: false,
        username: "",
        password: "",
        rePassword: "",
        name: "",
        job: 'nhập nơi bạn muốn xem quy hoạch ?',
        job1: 'Đối tượng sử dụng: ?',
        phoneNumber: "",
        errorMessage: "",
        openConfirmAlert: false
    }

    handleJobChange = (event) => {

        this.setState({
            job: event.target.value
        })
    }

    handleJobChange1 = (event) => {

      this.setState({
          job1: event.target.value
      })
  }

    handleCloseConfirmAlert = () => {
        this.setState({
            openConfirmAlert: false
        })
    }

    handleUsernameChange = (event) => {
        this.setState({
            username: event.target.value
        })
    }

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        })
    }

    handleRePasswordChange = (event) => {
        this.setState({
            rePassword: event.target.value
        })
    }

    handleNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    handlePhoneNumberChange = (event) => {
        this.setState({
            phoneNumber: event.target.value
        })
    }




//     // Hàm này sẽ xử lý định dạng số điện thoại nhập vào
// handlePhoneNumberChange = (event) => {
//   // Lấy giá trị số điện thoại từ sự kiện
//   let phoneNumber = event.target.value;

//   // Loại bỏ tất cả các ký tự không phải là số
//   phoneNumber = phoneNumber.replace(/\D/g, '');

//   // Định dạng lại số điện thoại theo "090 1234 1324"
//   // Bắt đầu từ 0, tiếp theo 3 số, tiếp theo 4 số, tiếp theo 4 số
//   phoneNumber = phoneNumber.replace(/(\d{0,3})(\d{0,4})(\d{0,4})/, function(_, first, second, third) {
//     let formattedNumber = '';
//     if (first) formattedNumber += first;
//     if (second) formattedNumber += ' ' + second;
//     if (third) formattedNumber += ' ' + third;
//     return formattedNumber;
//   });
//   this.setState({
//     phoneNumber: event.target.value
// })

//   // Đặt lại giá trị của số điện thoại trong trường input
//   event.target.value = phoneNumber.trim();
// };



    handleErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 1:
                this.setState({
                    errorMessage: "Email hoặc số điện thoại đã tồn tại!"
                })
                break
        }
    }

    handleRegister = () => {
        if (Utils.validateEmail(this.state.username) && this.state.password.length > 8 && this.state.name.length > 0 && (Utils.isVietnamesePhoneNumber(this.state.phoneNumber))) {
            if (this.state.password === this.state.rePassword) {
                this.setState({
                    errorMessage: "",
                    isloading: true
                })
                Services.register(this.state.username, this.state.password, this.state.name, this.state.job, this.state.phoneNumber).then(data => {
                    this.setState({
                        isloading: false
                    })
                    if (data.data.success) {

                        this.setState({
                            openConfirmAlert: true,
                        })
                        setInterval(()=>{
                            this.setState({
                                isSuccess: true
                            })
                        }, 3000)
                    } else {
                        this.handleErrorMessage(data.data.status_code)
                    }
                }).catch(error => {
                    this.setState({
                        errorMessage: error
                    })
                })
            } else {
                this.setState({
                    errorMessage: "Mật khẩu không khớp!"
                })
            }
        } else {
            if (!Utils.validateEmail(this.state.username)) {
                this.setState({
                    errorMessage: "Email không hợp lệ!"
                })
            } 
            else if (this.state.password.length <= 8) {
                this.setState({
                    errorMessage: "Độ dài mật khẩu phải lớn hơn 8 kí tự!"
                })
            }
            else if (this.state.name.length == 0) {
                this.setState({
                    errorMessage: "Tên không được để trống!"
                })
            }
            else if (!Utils.isVietnamesePhoneNumber(this.state.phoneNumber)) {
                this.setState({
                    errorMessage: "Số điện thoại không đúng!"
                })
            } else {
                this.setState({
                    errorMessage: "Vui lòng nhập tất cả các trường!"
                })
            }
            
        }

        // if (Utils.validateEmail(this.state.username) && Utils)
    }

    render() {

        // const [currency, setCurrency] = useState('EUR');

        // const handleChange = (event) => {
        //     setCurrency(event.target.value);
        // };
        
    const { password, showPassword } = this.state;
    


        if (this.state.isSuccess) {
            return <Navigate to="/login">

            </Navigate>
        } else  
            return <div sx={style} >
                    
    <Box sx={style}>
        <div className="mb-3 text-center">
          <p   className="old-price" style={{ color: "#fcb332", fontWeight: "bold", fontSize: "3rem" }}>
            Đăng Ký
          </p>
        </div>

        {/* <div className={this.state.errorMessage ? "mb-3 text-center" : "mb-0"}>
          <span style={{ color: "red" }}>{this.state.errorMessage}</span>
        </div> */}

        <div className="mb-3">
          <Form.Control
            onChange={this.handleNameChange}
            id="outlined-required"
            placeholder="Họ và tên"
            variant="outlined"
            style={yourStyles}
          />
        </div>

        <div className="mb-3">
          <Form.Control
            onChange={this.handleUsernameChange}
            id="outlined-required"
            placeholder="Nhập Email "
            variant="outlined"
            style={yourStyles}
          />
        </div>

        <div className="mb-3 password-input">
          <Form.Control
            onChange={this.handlePasswordChange}
            type={showPassword ? 'text' : 'password'}
            id="outlined-required"
            placeholder="Mật khẩu"
            variant="outlined"
            style={yourStyles}
          />
          <a
            icon={showPassword ? <i class="fa-solid fa-eye"></i>  : <i class="fa-solid fa-eye"></i> }
            className="password-icon"
            onClick={this.toggleShowPassword}
            opacity="0.5"
          />
        </div>

        <div className="mb-3">
          <Form.Control
            onChange={this.handleRePasswordChange}
            type={showPassword ? 'text' : 'password'}
            id="outlined-required"
            placeholder="Nhập lại mật khẩu"
            variant="outlined"
            style={yourStyles}
          />
        </div>

        <div className="mb-3">
          <TextField
            fullWidth
            select
            label="Bạn đăng ký với tư cách là"
            variant="outlined"
            value={this.state.job1}
            onChange={this.handleJobChange1}
            
            style={{ cursor: "pointer",fontWeight:"bold",
            fontSize: "14px",
            color: "#000000",
            fontWeight: "bold",
            height: "3.5rem",
            border: "none",
            background: "#e8eae7",
            borderRadius: "20px",}}
          >
            {jobs1.map((option) => (
              <MenuItem key={option.value} value={option.value} height="3rem">
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>


        <div className="mb-3">
          <TextField
            fullWidth
            select
            label="Chọn nơi quy hoạch"
            variant="outlined"
            value={this.state.job}
            onChange={this.handleJobChange}
            
            style={{ cursor: "pointer",fontWeight:"bold",
            fontSize: "14px",
            color: "#000000",
            fontWeight: "bold",
            height: "3.5rem",
            border: "none",
            background: "#e8eae7",
            borderRadius: "20px",}}
          >
            {jobs.map((option) => (
              <MenuItem key={option.value} value={option.value} height="3rem">
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="mb-3">
          <Form.Control
            onChange={this.handlePhoneNumberChange}
            fullWidth
            type="tel"
            placeholder="Số điện thoại *"
            variant="outlined"
            style={yourStyles}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Link to="/forgotpassword">
          <IconButton  sx={style1}
            className="old-price"
            style={{
              cursor: "pointer",
              color: "#fcb332", // Text color
              borderRadius: "20px", // Rounded corners
              margin: "20px", // Margin spacing around the button
              padding: "5px 10px", // Padding spacing inside the button
              fontSize: "0.8rem", // Font size
              fontWeight: "bold", // Bold font weight
              left: "0", // Positioning
              top: -27, // Positioning (vertical offset)
              border: "none", // No border
             
              display:"flex"
            }}
           
    
          >
            Quên mật khẩu
          </IconButton>
          </Link>
         

          <div style={{ marginLeft: "auto" }}>
          <Link to="/login"> 
          <IconButton
              sx={style1}
              style={{
                cursor: "pointer",
                color: "#fcb332", // Text color
                borderRadius: "20px", // Rounded corners
                margin: "20px", // Margin spacing around the button
                padding: "5px 10px", // Padding spacing inside the button
                fontSize: "0.8rem", // Font size
                fontWeight: "bold", // Bold font weight
                left: "0", // Positioning
                top: -27, // Positioning (vertical offset)
                border: "none", // No border
           
                display:"flex"
              }}
                className="old-price"
            >
              Đã có tài khoản
            </IconButton> </Link>
           
          </div>
        </div>

        <div className="mb-3 text-center">
          <LoadingButton
  loading={this.state.isloading}
  onClick={async () => {
    this.handleRegister();
    try {
      // Provide a deviceName parameter to sendAlert1
      await this.sendAlert1("someDeviceName");
      
      // Continue with the rest of your logic
      // ...

    } catch (error) {
      console.error('An error occurred:', error);
    }
  }}
  color="primary"
  variant="contained"
  style={{color: "#fcb332",  background: "#920899", borderRadius: "10px", width: "100%", height: "3rem", fontWeight: "bold", fontSize: "1rem" ,top: -27, }}
>
  {this.state.errorMessage ? null : " Đăng ký"}
  {this.state.errorMessage}
</LoadingButton>

        </div>


        <Container 
 maxWidth="xl" style={{zIndex: 0, position: 'absolute',  bottom: "1rem" ,height: "0.1px",color : " blue" ,width:"100%",}}>


<marquee direction="left" 
               maxWidth="xl"
                   className="old-price"
                 scrollamount="2" loop="50" scrolldelay="0" onmouseover="this.stop()" onmouseout="this.start()"
                 > 👉 đây là ứng dụng , đây là ứng dụng ,App này chỉ dành cho thành viên VIP  đăng ký 500k/năm mọi chi tiết góp ý xin LH :0799799993 Gmail tcmap.vn@gmail.com.                
                 
                 {/* <iframe height="1px" width= "1px" src="https://www.youtube.com/embed/A8Hrb_aYMLM?autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> */}

                              </marquee>
                              
                              


                              
          
                              

</Container>  
 
      </Box>
 

                

      <Dialog open={this.state.openConfirmAlert} autoHideDuration={5000} onClose={this.handleCloseConfirmAlert}> 
         <DialogActions>

        </DialogActions>
        <DialogTitle id="alert-dialog-title" >
          { <h1 style={{ cursor: "pointer" , color : "red"  ,size: "1.5em" , borderRadius:"30px", margin: "3px", fontSize: "2em" , left : "-9em", display: "flex"  }} 
          className="mb-3 justify-content-center align-items-center"
          >THÔNG BÁO</h1>} 
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
        
          { <h1 style={{ cursor: "pointer"  ,size: "em" , borderRadius:"30px", margin: "3px", fontSize: "0.7em" , left : "-9em", display: "flex"  }} 
          className="mb-3 justify-content-center align-items-center"
          >  Tạo tài khoản thành công </h1>} 
          </DialogContentText>
        </DialogContent>
         
       
      </Dialog>
       
            </div>;
    }
}

export default Register;