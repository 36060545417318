import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
 
import Navbar from "../Components/Navbar/Navbar";
import Container from "../Components/UI/Conainer";
import Footer from "../Components/Footer/Footer";
import "./NewsPage.css";
import { useParams } from "react-router-dom";
import { Services } from "../../../APIs/Services";
 

import { Box, CardMedia, Button, TextField, Modal, Stack, IconButton, Link, Divider, Snackbar, Alert ,Avatar} from '@mui/material';
 
 
import { Carousel } from 'react-bootstrap';
 


const NewsPage = () => {
  const [dateElement, setDateElement] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (dateElement) {
        const rect = dateElement.getBoundingClientRect();
        const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
        const newPosition = offsetTop + rect.top + rect.height / 2;

        dateElement.style.transform = `translateX(-50%) translateY(${newPosition}px)`;
      }
    };

    // Attach scroll event
    window.addEventListener("scroll", handleScroll);

    // Cleanup: remove scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dateElement]);

  const location = useParams();
  const [selectedNews, setSelectedNews] = useState({});
  const navigate = useNavigate(); // useNavigate hook for navigation

  useEffect(() => {
    var arr = location.id.split("-");
    Services.getNews("").then((data) => {
      setSelectedNews({
        ...data.data.data.filter((item) => {
          return item.id == Number(arr[arr.length - 1]);
        })[0],
      });
    });
  }, [location.id]);

  const contentStyle = {
    overflowY: "auto",
    width: { xs: "100%", md: '40%' },
    height: "calc(100% - 56px)",
  };

  const modalStyle = {
    width: ['100%', '50%'], // Set width based on breakpoints
    transform: 'translateX(-50%)', // Center horizontally on desktop
    position: 'absolute',
    overflowY: 'auto',
    border: 'none',
    background: 'rgba(255, 255, 255, 0.95)',
    p: 4,
    boxShadow: 10,
    borderRadius: 2,
    border: "1px solid Yellow",
    position: "fixed",
    bottom: 0,
    width: "100%", // Default width for mobile
    zIndex: 1000,
    transform: "translateX(-50%)", // Center horizontally on mobile
    left: "50%", // Center horizontally on desktop
 
  };
  const modalStyle1 = {
    width: ['100%', '50%'], // Set width based on breakpoints
    transform: 'translateX(-50%)',
    left: "50%", // Center horizontally on desktop
    position: 'absolute',
    height: '100%',
    overflowY: 'auto',
    border: 'none',
    p: 4,
    boxShadow: 10,
    borderRadius: 2,
    background: 'rgba(255, 255, 255, 0.95)',
  };

  const tabBarStyle = {
    height:"3rem",
    position: 'fixed',
    bottom: 0,
    width: '100%', // Default width for mobile
    zIndex: 1000,
    left: '50%', // Center horizontally on mobile
    transform: 'translateX(-50%)', // Center horizontally on mobile
  };

  // Override styles for desktop
  tabBarStyle['@media screen and (min-width: 768px)'] = {
    width: '50%', // 50% width on desktop
    left: '50%', // Center horizontally on desktop
    transform: 'translateX(-50%)', // Center horizontally on desktop
  };

  // ... rest of your component

  // ... rest of your component

  const iconStyle = {
    fontSize: '2rem', // Adjust the font size to make the icon larger
    height: 'auto', // Ensure the icon scales proportionally
  };

 

  const [preloadedImages, setPreloadedImages] = useState([]);
  const selectedNewsImages = selectedNews.img ? selectedNews.img.split(",") : [];

  const handleSlide = (index) => {
    // Preload next image
    const nextIndex = (index + 1) % selectedNewsImages.length;
    const nextImageUrl = selectedNewsImages[nextIndex];

    if (nextImageUrl && !preloadedImages.includes(nextImageUrl)) {
      const image = new Image();
      image.src = nextImageUrl;

      // Once the image is loaded, update the state
      image.onload = () => {
        setPreloadedImages((prevImages) => [...prevImages, nextImageUrl]);
      };
    }
  };

  if (/\d{10}/.test(selectedNews.description)) {
    const phoneNumber = selectedNews.description.match(/\d{10}/)[0];

    const contactButton = document.getElementById('contact-button');
    if (contactButton) {
      contactButton.href = `tel:${phoneNumber}`;
      contactButton.style.display = 'block'; // Make sure the button is visible
    }
  } else {
    const contactButton = document.getElementById('contact-button');
    if (contactButton) {
      contactButton.style.display = 'none'; // Hide the button when there is no phone number
    }
  }
  

// Thay thế / kiểm tra logic hiển thị dựa trên mẫu URL của trang web
const webLinkRegex = /https?:\/\/[^\s]+/;

if (webLinkRegex.test(selectedNews.description)) {
  const webLink = selectedNews.description.match(webLinkRegex)[0];

  const contactButton = document.getElementById('contact-button1');
  if (contactButton) {
    contactButton.href = webLink;
    contactButton.style.display = 'block'; // Đảm bảo nút hiển thị
  }
} else {
  const contactButton = document.getElementById('contact-button1');
  if (contactButton) {
    contactButton.style.display = 'none'; // Ẩn nút khi không có đường link web
  }
}




const isPhoneNumber = /\d{10}/.test(selectedNews.description);
const isWebLink = /https?:\/\/[^\s]+/.test(selectedNews.description);

let displayText = selectedNews.description;

 

console.log('displayText before:', displayText);

if (isPhoneNumber) {
  const phoneNumber = selectedNews.description.match(/\d{10}/)[0];
  displayText = displayText.replace(phoneNumber, '');
}

console.log('displayText after:', displayText);

// Ẩn đường link web nếu có
if (isWebLink) {
  const webLink = selectedNews.description.match(/https?:\/\/[^\s]+/)[0];
  displayText = displayText.replace(webLink, '');
}
 
  return (
    <div>
      <Modal open={true}>
        <Box >
          <Box className="Home" sx={{ ...modalStyle1, width: [`100%`, `50%`], border: "none", }} > 
            <Container style={contentStyle}>
 

            <html lang="en" style={{
      height: '0.1px',
      with:"0.1px"
 
    }} >
 
      <body>
      </body>
    </html>

 
                <div>
                {selectedNews.img && (
  <Carousel onSlide={handleSlide}>
    {selectedNewsImages.map((imageUrl, index) => (
      <Carousel.Item key={index}>
        <div className="image-container" style={{
          position: "relative",
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "auto",
          height: "50vh",
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
          justifyContent: "center"
        }}>
         <div 
    style={{
      position: "relative",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
          justifyContent: "center",
          width: "5rem",  height: "5rem",
            borderRadius: "30px",
            backgroundImage: `url(../assets/icons/logo512.png)`, 
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative" ,
            opacity:"0.2"
    }}
  >    
  </div>

        </div>
        <br />
      </Carousel.Item>
    ))}
  </Carousel>
)}

       
  
    </div>

    <a
  id="contact-button"
  style={{
    cursor: "pointer",
    color: "blue",
    borderRadius: "20px",
    margin: "2px",
    padding: "5px 10px",
    fontSize: "18px",
    height: "35px",
    fontWeight: "bold",
    top: "-50px",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
    animation: "blink 100s infinite", // Sử dụng animation
  }}
>
  <i className="fa fa-phone fa-1x" aria-hidden="true"></i> &nbsp;Liên hệ
</a>
 


 
<a
download
  id="contact-button1"
  style={{
    cursor: "pointer",
    color: "blue",
    borderRadius: "20px",
    margin: "2px",
    padding: "5px 10px",
    fontSize: "18px",
    height: "35px",
    fontWeight: "bold",
    top: "-50px",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
    animation: "blink 100s infinite", // Sử dụng animation
  }}
>
  <i download className="fa fa-download fa-1x" aria-hidden="true"></i> &nbsp;tải về 
</a>
 


  
                <div className="news-page">
           
<div className="news-page__content">
  <h1 
    style={{
      height: 'auto',
      borderRadius: 8,
      fontWeight: '600',
      fontSize:"18px",
      justifyContent: 'center',
      textAlign: 'center', // Adjusted to 'left'
      whiteSpace: 'pre-wrap',
    }}
  >
    {selectedNews.title}
  </h1>

  <span
    style={{
      height: 'auto',
      borderRadius: 8,
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap',
      display: 'flex',
      fontSize: '13px',
      textAlign: 'left' // Adjusted to 'left'
    }}
  >
      {displayText}
  </span>
<br/>
    <div className="news-page__text">
    <iframe
                                    src={ ("https://www.youtube.com/embed/"  +  selectedNews.category)  }
                                        style={{
                                        borderRadius: "10px",
                                        width: "100%",
                                        height: "50vh",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        }}
                                        frameBorder="0"
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                        title="video1"
                                        borderRadius="20px"
                                        height="250"
                                    />
    </div>
  </div>
  <br/><br/><br/><br/> 
  <div className="news-page__text">
    <small>
      Thông tin quy hoạch tcmap.vn Hotline:{" "}
      <a href="tel:0799799993">0799799993</a>
    </small>
  </div>
</div>
 
            </Container>
          </Box>
          
          <BottomNavigation showLabels sx={{ ...modalStyle, width: [`100%`, `50%`], border: "none", }} className="news-page1">
            <BottomNavigationAction
              label="quay lại"
          
              style={iconStyle}
              onClick={() => navigate(-1)} // Navigate back
            />
            <BottomNavigationAction label="Sports" href="/quy-hoach"  style={iconStyle} />
            {/* Use Avatar for custom styling */}
            <BottomNavigationAction
              href={`/map?id=${selectedNews.shortDescription}`}
              icon={
                <div className="logo" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <span style={{ fontSize: "15px", color: "rgb(141, 2, 255)", whiteSpace: 'nowrap' }}>
                    Xem quy hoạch
                  </span>
                  <Avatar src="../assets/icons/logo512.png" style={{ ...iconStyle, height: '4rem', width: '4rem', top: -5 }} />
                </div>
              }
            />




            <BottomNavigationAction label="Business" href="/quy-hoach"   style={iconStyle} />
            <BottomNavigationAction label="Florist" href="/quy-hoach"  style={iconStyle} />
          </BottomNavigation>
        </Box>
      </Modal>
    </div>
  );
};

export default NewsPage;
