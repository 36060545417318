import {MenuItem, Select, Box, Button, TextField, Modal, Stack, Dialog, DialogTitle, DialogContent, DialogContentText,DialogActions ,IconButton ,Avatar} from '@mui/material';
import { Services } from '../APIs/Services';
import { Utils } from '../Utils/Utils';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useEffect, useState ,useRef} from "react";
import { format } from 'date-fns';
import { InputGroup, FormControl } from 'react-bootstrap';
import Control from "react-leaflet-custom-control";
// import QRCode from 'qrcode.react';
import axios from 'axios';
 
import { AppBar,Autocomplete,Toolbar  } from "@mui/material";
import Paper from "@mui/material/Paper";
import moment from 'moment';
import DialogMarker from './DiaglogMarker'; 
 
 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AdComponent from '../components/AdSenseAds';
import {  Typography, Pagination} from '@mui/material';

import { InputBase,   } from '@mui/material';
import Form from 'react-bootstrap/Form';

 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
 
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {DISTRICTS} from './../constants/maps.constant'
import Network from '../APIs/Network';
 
 
import { Navigate ,Link } from 'react-router-dom';


 



const modalStyle5 = {
  position: "absolute",
  top: 30,
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "scroll",
  display: "block",
  maxHeight: { xs: "100%", md: "100%" },
  border: "2px solid #000",
  borderRadius: "10px",
  background: '#008800',

};


const modalStyle4 = {
  position: "absolute",
  top: "97%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "scroll",
  display: "block",
  maxHeight: { xs: "100%", md: "100%" },
  border: "2px solid #000",
  borderRadius: "10px",
  background: '#fff',

};

const modalStyle3 = {
  width: ['100%', '70%'], // Set width based on breakpoints
  transform: 'translateX(-50%)', // Center horizontally on desktop
  position: 'absolute',
  background: '#fff',
  borderRadius: 2,
  border: "1px solid Yellow",
  position: "fixed",
  width: "100%", // Default width for mobile
  zIndex: 1000,
  transform: "translateX(-50%)", // Center horizontally on mobile
  left: "50%", // Center horizontally on desktop
  height:"100%",
  top:"-0%"
};


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: {xs: "90%",md: '40%'},
  maxWidth: "90%",
  height: '100%',
  overflow: 'scroll',
  display: "block",
 
  bgcolor: 'background.paper',
  border: '2px solid #000',
 
  borderRadius:"10px",
 
};




  const tcmap = () => {
    window.open("/");
    return null;
  };
  
  const vechai = () => {
    window.open("https://vechai.tcmap.vn/");
    return null;
  };



 
  
  const jobs = [
    {
      value: 'Sale',
      label: 'Sale',
    },
    {
      value: 'Bất động sản',
      label: 'Bất động sản',
    },
    {
      value: 'Công nghệ thông tin',
      label: 'Công nghệ thông tin',
    },
    {
      value: 'Khác',
      label: 'Khác',
    },
  ];
  

  
export default function InfoDialog({ userData, isInfoOpen,handleClose, isChangePassword}){

  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;


  const [errorChangeMessage, setErrorChangeMessage] = useState("")
  const [isReadOnly, setIsReadOnly] = useState(true)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [reNewPassword, setReNewPassword] = useState("")
  const [openDialog, setOpenDialog] = useState(false)
 
 
 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [job, setJob] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [viewNumber, setViewNumber] = useState(0);
  const [date, setDate] = useState(null);
 

  useEffect(() => {
    setName(userData.name);
    setEmail(userData.email);
    setJob(userData.job);
    setPhoneNumber(userData.phone_number);
    setViewNumber(userData.number_of_views);
  
    const expDate = userData.exp_date ? new Date(userData.exp_date * 1000) : null;
    console.log('expDate:', expDate);
    setDate(expDate);
  }, [userData]);
  


  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value)
  }

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value)
  }

  const handleReNewPasswordChange = (event) => {
    setReNewPassword(event.target.value)
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleJobChange = (event) => {
    setJob(event.target.value)
  }

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value)
  }

  const handleViewNumberChange = (event) => {
    setViewNumber(event.target.value)
  }
 
  

  const handleChangePassword = () => {
    if (oldPassword.length > 8 && newPassword.length > 8 && reNewPassword.length > 8) {
      if (newPassword === reNewPassword) {
        setErrorChangeMessage("")
        Services.changePassword(localStorage.getItem("userId"), newPassword, oldPassword).then(data => {
          if (data.data.success) {
            handleClose()
            setErrorChangeMessage("")
          } else {
            if (data.data.status_code === 1) {
              setErrorChangeMessage("Mật khẩu cũ không chính xác!")
            }
          }
        })
      } else {
        setErrorChangeMessage("Xác nhận mật khẩu không khớp!")
      }
    } else {
      setErrorChangeMessage("Mật khẩu không hợp lệ!")
    }
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleUpdate = () => {
    if (isReadOnly) {
      setIsReadOnly(false)
    } else {
      if (name.length > 0 && (Utils.isVietnamesePhoneNumber(phoneNumber))) {
        Services.updateUser(name, job, phoneNumber).then(data => {
          if (data.data.success) {
            setErrorChangeMessage("")
            setIsReadOnly(true)
          }
        })
      } else {
        setErrorChangeMessage("Một số trường không hợp lệ!")
      }
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleDeleteUser = () => {
    Services.deleteUser().then(data => {
      console.log(data)
      if (data.data.success) {
        handleCloseDialog()
        handleClose()
        setTimeout(() => {
          window.location.href = '/login'; // Chuyển hướng đến trang /map
        }, 2000); 
      }
    })
  }

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isSuperUser");
    localStorage.removeItem("userId");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    window.location.href = "/login";
  };


  
  const [menuLevel, setMenuLevel] = useState(0);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedWard, setSelectedWard] = useState('');
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);

 

 

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCity(selectedCityId);
    setMenuLevel(1);
    setDistricts(cities.find((city) => city.Id === selectedCityId)?.Districts || []);
    setSelectedDistrict('');
    setWards([]);
    setSelectedWard('');
  };

  const handleDistrictChange = (event) => {
    const selectedDistrictId = event.target.value;
    setSelectedDistrict(selectedDistrictId);
    setMenuLevel(2);
    const selectedCityData = cities.find((city) => city.Id === selectedCity);
    const selectedDistrictData = selectedCityData?.Districts.find(
      (district) => district.Id === selectedDistrictId
    );
    setWards(selectedDistrictData?.Wards || []);
    setSelectedWard('');
  };

  const handleWardChange = (event) => {
    setSelectedWard(event.target.value);
  };

  const handleGoBack = () => {
    setMenuLevel(menuLevel - 1);
  };


 
 
  
  const handleConfirm = () => {
    window.location.href = `/map?id=${selectedWard}`;
  };
  
  

 
  
  function shareMarkerAndroid(url) {
    if (window.NativeAndroid) {
        window.NativeAndroid.share(url);
    }
  }

  const handleCopyToClipboard = (id) => {
    const url = new URL(window.location.href).origin + "?marker=" + dataSelected.id;
    navigator.clipboard.writeText(url)
      .then(() => {
        console.log('Đã sao chép vào clipboard');
        // Hiển thị thông báo cho người dùng
        alert('Đã sao chép ');
 
        window.location.href = `/?marker=${dataSelected.id}`;
        
        
      })
      .catch((error) => {
        console.error('Lỗi khi sao chép vào clipboard:', error);
      });
  };


 

  // Use useEffect to update the QR code whenever any of the data changes
  useEffect(() => {
    const qrDataString = `${name} ${phoneNumber} ${job} ${email} ${"https://vechai.tcmap.vn"}`;
    setQRCodeData(qrDataString);
  }, [name, phoneNumber, email, job,name]);
  

  const [qrCodeData, setQRCodeData] = useState('');






  const [data, setData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10000;

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleSearchChange = (event, value) => {
    setSearchText(value);
    setCurrentPage(1); // Reset to the first page when performing a new search
  };



  useEffect(() => {
    const fetchData = async () => {
      const query = new URLSearchParams(window.location.search);
      const markerId = query.get('marker');

      if (markerId != null) {
        try {
          const markerData = await Services.getMarker(markerId);
          setData([markerData.data.data]);
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          const markersData = await Services.getMarkers();
          setData(markersData.data.data);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);




   // Filter data based on search text
const filteredData = data.filter((item) =>
item.name.toLowerCase().includes(searchText.toLowerCase())
);

// Calculate total number of pages
const totalPages = Math.ceil(filteredData.length / itemsPerPage);

// Calculate the index of the first item to display on the current page
const startIndex = (currentPage - 1) * itemsPerPage;

// Get the items for the current page
const currentItems = filteredData.slice(startIndex, startIndex + itemsPerPage);

 








 




const [images, setImages] = React.useState([""]);
const addImages = (index) => {
  if (index == images.length - 1) {
      setImages([...images, ""])
  } else {
      setImages(prevState => {
          const images = [...prevState]; // create new array based on current tasks
          images.splice(index, 1); // remove task by index
          handleImagesChange(images)
          return images; // return altered array
        })
  }
}
const [errorModal, setErrorModal] = React.useState("")
const [isAddingUser, setIsAddingUser] = React.useState(false)
const handleAddUser = () => {
  setDataSelected({
    "name": "",
    "description": "",
    "image_url": "",
    "youtube_url": "",
    "exp_date": new Date().getTime()/1000,
    "district": "Quận 1",
    "lag": "",
    "lng": "",
    "type": "Testing",
    "is_activate": false,
    "is_delete": false
  })

  setIsAddingUser(true)
}

const [isSearching, setIsSearching] = React.useState(false)
const [emailSearch, setEmailSearch] = React.useState("")
 
 

 
const handleImagesChange = (iImages) => {
  setDataSelected(prevState => ({
    ...prevState,
    "image_url": iImages.join(',')
  }))
}

 
 

const handleDescriptionChange = (event) => {
  setDataSelected(prevState => ({
    ...prevState,
    "description": event.target.value
  }))
}

 
 
const [isEditing, setEditing] = React.useState(false)
const handleUpdate1 = () => {
  if (isAddingUser) {
    setErrorModal("")
    if (dataSelected.name.length > 1 && dataSelected.description.length > 1 && dataSelected.lng.length > 1 && dataSelected.lag.length > 1 ) {
      Services.addMarker(dataSelected.name, dataSelected.exp_date,dataSelected.description, dataSelected.image_url, dataSelected.youtube_url, dataSelected.district, dataSelected.lng, dataSelected.lag, dataSelected.type).then(data => {
        console.log(data)
        if (data.data.success) {
          getData(page, emailSearch)
        } else {
          setErrorModal("Email đã tồn tại!")
        }
      })
    } else {
      setErrorModal("Một số trường không hợp lệ!")
    }
  } else {
    if (!isEditing) {
      setEditing(!isEditing)
    } else if (dataSelected.name.length > 1 ) {
      Services.updateMarker(dataSelected.id,dataSelected.name, dataSelected.exp_date, dataSelected.description, dataSelected.image_url, dataSelected.youtube_url, dataSelected.district, dataSelected.lng, dataSelected.lag, dataSelected.type, dataSelected.exp_date, dataSelected.is_activate, dataSelected.is_delete).then(data => {
        getData(page, emailSearch)
        setErrorModal("")
        setEditing(!isEditing)
      })
    } else {
      setErrorModal("Một số trường không hợp lệ!")
    }
  }
}
 
React.useEffect(() => {
  getData(1, "")
}
  , [])

const [dataSelected, setDataSelected] = React.useState(null)


const getData = (index, title) => {
  // Services.users(index, email).then(data => {
  //   setUsers(data.data.data)
  //   setTotalPages(data.data.total_page)
  // })
  Services.getAdminMarkers(index, title).then(data=>{
      setUsers(data.data.data)
      setTotalPages(data.data.total_page)
  })
}
const [users, setUsers] = React.useState([])

const [total_pages, setTotalPages] = React.useState(1)


const handleTapUser = (data) => {
  setImages(data.image_url.split(","))
  setDataSelected(data)

};

const [page, setPage] = React.useState(1);
const handleChange = (event, value) => {
  if (localStorage.getItem("email")) {
    setPage(value);
    if (isSearching) {
      getData(value, emailSearch);
    } else {
      getData(value, "");
    }
  }
};



const [activeTab, setActiveTab] = useState('table'); // 'table' or 'modal'

const handleTabChange = (tab) => {
  setActiveTab(tab);


};


  

const [isButtonOn, setIsButtonOn] = useState(false);

const handleClick = () => {
  setIsButtonOn(!isButtonOn);
};

 








    return (
      <div>
        <Dialog
        style={{zIndex: 0 }}
          open={isInfoOpen}
          onClose={handleClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
            <DialogContent style={{ overflowY: 'auto', maxHeight: '100%',maxWidth:"100vh" ,width:"100%"}}>
            <IconButton onClick={handleClose} style={{ zIndex:99999,  top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} >❌

</IconButton> 

         
            <div className="container">
              <div className="row h-100 justify-content-center align-items-center ">
                <div className="col-sm-12 col-md-12 ">

                  <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                  
  
                  </div>


                  <div
               
                onClick={() => {
                  localStorage.setItem('mapID', data.id);
                  window.location.href = '/';
                }}
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center', 
                  borderRadius:"10px"// Thêm justifyContent để căn giữa theo chiều ngang
                }}
              >
                <Avatar
                className="logo" 
                  src="../assets/icons/logo512.png"
                  style={{
                    width: '4rem',
                    height: '4rem',
                    marginRight: '0.5rem', // Điều chỉnh margin để tạo khoảng cách giữa ảnh và văn bản
                  }}
                />
                <h2 style={{ fontSize: '15px', margin: '0' }}>Trang chủ</h2>
              </div>



            
                   
                    {/* Display the QR code */}
                    <br/>
                    <div className="mt-3" style={{ position: 'relative' }}>
                      {/* <h2  style={{  fontSize:"19px" }}>
                        thông tin
                      </h2> */}
                      {/* <QRCode
                      logoImage={dotImage}
                        value={qrCodeData}
                        style={{ borderRadius: '5px', height: "200px", width: "200px" }}
                        {...qrCodeOptions}
                        alt="QR Code"
                      /> */}

                      <h2  style={{  fontSize:"13px" }}>
                        
                      Ngày sử dụng  <a style={{color:"black"}}> {date ? format(date, 'dd/MM/yyyy') : 'N/A'}</a>
                      </h2>

                       
                   
                    </div>
                 
    <br/>
                  {isChangePassword ? <div>
                    

                    <div>
                       
                      


                      <div className="mb-3">
                <Stack className="w-100" direction="row" spacing={0}>
                  <div style={{ flex: 1 }}>
                     
    </div>
                           
                          
                        
                  
                           
  </Stack>
</div>

                    </div>

                    
                    
                  </div> :






                    <div>
                       
                      <div className="mb-3">
                      
                      </div>
                    </div>}

                    <div>
                       
 
                    </div>

 

                  <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >



                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h2 onClick={handleClick} style={{ width: 300, height: 30, fontSize: "17px", borderRadius: "30px", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <div style={{ color: "black" }}>
                      <Stack direction="row" spacing={0}> 
                            <TextField
                                value={viewNumber}
                                type="text"
                                id="outlined-required"
                                fullWidth
                                label="Số lượt xem"
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                  style: { textAlign: 'left',color:"blue" }, // Align text to the left
                                }} 
                              />
                              <TextField
                                value={date ? format(date, 'dd/MM/yyyy') : 'N/A'}
                                type="text"
                                id="outlined-required"
                                fullWidth
                                label="Ngày hết hạn"
                                variant="outlined"
                                InputProps={{
                            readOnly: true,
                            style: { textAlign: 'left',color:"blue" }, // Align text to the left
                          }}
                              />&nbsp;&nbsp;
                            </Stack>
                      </div>
                     
                    </h2>
                  </div>

                  
              <br/>
 

</div>


 
 
                  </div>
                </div>
              </div>
            </div> 

             
 


                    <IconButton onClick={handleLogout}  style={{ zIndex:99999,  bottom: 0, left: "0", cursor: "pointer" , color : "red" ,background : "none" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "15px",fontWeight:"bold" }} >  &nbsp;  login
                  </IconButton> 



 
            <div className="container">
              <div className="row h-100 justify-content-center align-items-center ">
                <div className="col-sm-12 col-md-12 ">

                  <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                    <h3>
                      {isChangePassword ? "Thay đổi mật khẩu" : "Thông tin tài khoản"}
                    </h3>
                  </div>

                  <div display={errorChangeMessage !== ""} className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                    <span style={{ color: 'red' }}>
                      {errorChangeMessage}
                    </span>
                  </div>

                  {isChangePassword ? <div>

                    <div className="mb-3">
                      <Stack className="w-100" direction="row" spacing={2}>
                        <TextField onChange={handleOldPasswordChange} type="password" id="outlined-required" fullWidth label="Mật khẩu cũ" variant="outlined" />
                      </Stack>
                    </div>
                    <div className="mb-3">
                      <Stack className="w-100" direction="row" spacing={2}>
                        <TextField onChange={handleNewPasswordChange} type="password" id="outlined-required" fullWidth label="Mật khẩu mới" variant="outlined" />
                      </Stack>
                    </div>
                    <div className="mb-3">
                      <Stack className="w-100" direction="row" spacing={2}>
                        <TextField onChange={handleReNewPasswordChange} type="password" id="outlined-required" fullWidth label="Xác nhận mật khẩu mới" variant="outlined" />
                      </Stack>
                    </div>
                  </div> :
                    <div>
                      <div className="mb-3">
                        <Stack className="w-100" direction="row" spacing={2}>

                          <TextField disabled={isReadOnly} value={name} onChange={handleNameChange} id="outlined-required" fullWidth label="Họ và tên *" variant="outlined" />
                        </Stack>
                      </div>
                      <div className="mb-3">
                        <Stack className="w-100" direction="row" spacing={2}>
                          <TextField disabled value={email} id="outlined-required" fullWidth label="Email *" variant="outlined" />
                          <TextField
                            disabled={isReadOnly}
                            fullWidth
                            id="outlined-select-currency"
                            select
                            label="Nghề nghiệp"
                            value={job}
                            onChange={handleJobChange}
                          >

                            {/* <MenuItem key="1" value="ABC">
                            ABC
                        </MenuItem> */}
                            {jobs.map((option) => (
                              <MenuItem
                              key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Stack>
                      </div>
                      <div className="mb-3">
                        <Stack className="w-100" direction="row" spacing={2}>
                          <TextField disabled={isReadOnly} value={phoneNumber} onChange={handlePhoneNumberChange} fullWidth id="outlined-required" label="Số điện thoại *" variant="outlined" />
                          <TextField disabled value={viewNumber} id="outlined-required" onChange={handleViewNumberChange} fullWidth label="Lượt xem còn lại" variant="outlined" />

                        </Stack>
                      </div>
                    </div>}
                  {/* <div className="mb-3">
                <TextField id="outlined-required" fullWidth label="Email" variant="outlined" />
            </div>
            <div className="mb-3">
                <TextField fullWidth id="outlined-required" label="Mật khẩu" variant="outlined" />
            </div> */}

            


{isChangePassword ? (


  <Stack  >
      <Button onClick={handleChangePassword} className="" style={{ flex: 1 }} color="success" variant="contained">Đổi mật khẩu</Button><br/>
      {/* <Stack style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 50, fontSize: "1rem" }}>
    <Button onClick={handleOpenDialog} className="w-100" style={{ flex: 1, marginRight: '8px' }} color="warning" variant="contained">Xoá tài khoản</Button>
    <Button onClick={handleUpdate} className="w-100" style={{ flex: 1 }} color="success" variant="contained">{isReadOnly ? "Chỉnh sửa" : "Cập nhật"}</Button>
</Stack> */}
<Button onClick={handleOpenDialog} className="w-100" style={{ flex: 1, marginRight: '8px' }} color="warning" variant="contained">detele acccout</Button>




 
</Stack>

) : (
  <div>
 <Stack >
                        {/* <Button onClick={handleOpenDialog} className="w-100" align="left" color="warning" variant="contained">Xoá tài khoản</Button>
                        <Button onClick={handleUpdate} className="w-100" align="right" color="success" variant="contained">{isReadOnly ? "Chỉnh sửa" : "Cập nhật"}</Button> */}
                        {/* <Button onClick={handleClose} className="w-100" align="right" color="action" variant="contained">Đóng</Button> */}
                        <Stack style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 50, fontSize: "1rem" }}>
    <Button onClick={handleOpenDialog} className="w-100" style={{ flex: 1, marginRight: '8px' }} color="warning" variant="contained">detele acccout</Button>
    {/* <Button onClick={handleUpdate} className="w-100" style={{ flex: 1 }} color="success" variant="contained">{isReadOnly ? "Chỉnh sửa" : "Cập nhật"}</Button> */}
</Stack>
                      </Stack>
  </div>
)}




                  {/* <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                    {isChangePassword ?
                      <Button onClick={handleChangePassword} className="" align="right" color="success" variant="contained">Đổi mật khẩu</Button>
                      
                      :
                      <Stack className="w-100" direction="row" spacing={2}>
                        <Button onClick={handleOpenDialog} className="w-100" align="left" color="warning" variant="contained">Xoá tài khoản</Button>
                        <div className="w-100"></div>
                        <Button onClick={handleUpdate} className="w-100" align="right" color="success" variant="contained">{isReadOnly ? "Chỉnh sửa" : "Cập nhật"}</Button>
                        <Button onClick={handleClose} className="w-100" align="right" color="action" variant="contained">Đóng</Button>
                      </Stack>
                    }

                  </div> */}

                  

                </div>
              </div>
            </div>
         
        <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{zIndex: "99999999999"}}
      >
        <DialogTitle id="alert-dialog-title">
          {"Xoá tài khoản"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc chắn xoá tài khoản này không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>Huỷ</Button>
          <Button onClick={handleDeleteUser}>
            Xoá
          </Button>
        </DialogActions>
      </Dialog>
 
  
 


                 


 <br/>

 <h>
  <a>
  <strong>Lịch xử tin của bạn</strong>
  </a>
</h>
 {localStorage.getItem("name") ? (
  <>
    <TableContainer component={Paper}>
      <Table onClick={() => handleTabChange('modal')} aria-label="simple table">
        <TableBody>
          {users.map((row, index) => (
            localStorage.getItem("email") === row.user.email && (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => handleTapUser(row)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell align="center">
                  {row.description.length > 10 ? row.description.substring(0, 20) + "..." : row.description}
                </TableCell>
                <TableCell align="center">
                  <small style={{ fontSize: "10px" }}>
                    {format(new Date(row.exp_date * 1000), 'HH:mm - dd/MM/yyyy')}
                  </small>
                </TableCell>
                {/* Add other columns if needed */}
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
{total_pages > 1 && localStorage.getItem("email") ? (
  <div className="my-3" style={{ float: 'right' }}>
    <Pagination onChange={handleChange} page={page} count={total_pages} color="primary" />
  </div>
) : null}


  </>
) : (
<Link  to="/login"
                
                > 
                  <h>
      <IconButton style={{border: '1px solid DodgerBlue',display: 'flex', alignItems: 'center', position: 'relative', left: '50%', transform: 'translateX(-50%)', color: "black",borderRadius:"20px" }}>ĐĂNG NHẬP</IconButton>
 
    </h> </Link>
)}




 







 {activeTab === 'modal' && dataSelected != null && (
   <div>
     
 
     {dataSelected != null ?
        <Modal
         open={isInfoOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>



  

          {/* <IconButton onClick={() => handleTabChange('table')} style={{ height:"2rem" , width:"2rem", top: 7,  cursor: "pointer", color: "red", background: "white", size: "10px", position: "absolute", borderRadius: "30px" ,left:3 }}  className="codepro-custom-btn codepro-btn-60">
                        <IoMdArrowRoundBack style={{ width:"5rem", width :"5rem" }}/>
                       </IconButton> */}

<IconButton onClick={() => handleTabChange('table')} style={{ zIndex: 99999999, top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} >❌</IconButton> 




            <div className="container">
              <div className="row h-100 justify-content-center align-items-center ">
                <div className="col-sm-12 col-md-12 ">

                  <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                    <h3>
                      {isAddingUser ? "Thêm tin" : "Thông tin"}
                    </h3>
                  </div>

                  {errorModal !== "" ? <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                    <span style={{ color: 'red' }}>
                      {errorModal}
                    </span>
                  </div> : null}

                  <div className="mb-3">
                    <Stack className="w-100" direction="row" spacing={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                        disabled={!(isEditing || isAddingUser)}
                          label="Ngày hết hạn"
                          value={dataSelected.exp_date == null ? new Date() :new Date(dataSelected.exp_date*1000)}
                          onChange={(newValue) => {
                            setDataSelected(prevState => ({
                              ...prevState,
                              "exp_date": newValue.getTime()/1000
                            }))
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </div>
 


                <div className="mb-3" style={{ display: "flex" ,padding :10,margin: "0 5px"}}>
                


                  {images.map((value, index) => (
                    <div key={index} className="mr-2">
                      <img
                        style={{
                          objectFit: "cover",
                          width: "50px",
                          height: "50px",
                          borderRadius: "10px",
                          margin: "0 10px",
                          fontSize:"10px"
                        }}
                        src={value}
                        alt={`chưa có ảnh`}
                      />
                    </div>
                  ))}
                </div>


                  <div className="mb-3">
                    <Stack className="w-100" direction="row" spacing={2}>
                      <TextField
                      disabled={!(isEditing || isAddingUser)}
                                    fullWidth
                                    id="outlined-select-currency"
                                    select
                                    label="chọn khu vực"
                                    value={dataSelected.district}
                                    onChange={handleDistrictChange}
                                >
                                    {DISTRICTS.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                      </Stack>
                  </div>
                        
                  <div className="mb-3">
                    <Stack className="w-100" direction="row" spacing={2}>
                      <TextField onChange={handleDescriptionChange} disabled={!(isEditing || isAddingUser)} id="outlined-required" multiline rows={3} maxRows={5} value={dataSelected.description} fullWidth label="Mô tả" variant="outlined" />
                    </Stack>
                  </div>
              
                  <div className="mb-3">
                   
                  </div>
                  {isAddingUser ?
                    <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                      <Stack className="w-100" direction="row" spacing={2}>
                        <div className="w-100"></div>
                        <Button onClick={handleUpdate1} className="w-100" align="right" color="success" variant="contained">Thêm</Button>
                        {/* <Button onClick={() => handleTabChange('table')}  className="w-100" align="right" color="action" variant="contained">❌</Button> */}
                      </Stack>

                    </div>
                    :
                    <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex" }} >
                      <Stack className="w-100" direction="row" spacing={2}>
                        <Button onClick={handleUpdate1} className="w-100" align="right" color="success" variant="contained">{isEditing ? "Cập nhật" : "Chỉnh sửa"}</Button>
                        <Button onClick={handleCopyToClipboard}  className="w-100" align="right"  variant="contained">Chia sẻ tin</Button>
                      </Stack>

                    </div>}

                </div>
              </div>
            </div>
          </Box>
        </Modal>
        : ""
      }
 
   </div>
 )}
  

 


</DialogContent>
        </Dialog>
         <Box>
           {selectedMarker && (
             <DialogMarker
               marker={selectedMarker}
               onClose={() => setSelectedMarker(null)}
             />
           )}
         </Box>


         <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{zIndex: "99999999999"}}
      >
        <DialogTitle id="alert-dialog-title">
          {"Xoá tài khoản"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc chắn xoá tài khoản này không?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>Huỷ</Button>

          <Button onClick={handleDeleteUser}>
            Xoá
          </Button>
        </DialogActions>
      </Dialog>
 


      </div>
    );
}