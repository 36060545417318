import React, { useEffect, useState ,useRef} from "react";
import { MapContainer, TileLayer, useMapEvent,useMapEvents  ,useMap ,Tooltip ,Marker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import MapBoxVectorTile from "../../components/MapBoxVectorTile";
import classes from "./index.module.scss";
import { Utils } from "../../Utils/Utils";
import { Services } from '../../APIs/Services';
import L, { DivIcon } from 'leaflet';
import {  button ,Avatar ,Container , Box ,Moda ,Button} from '@mui/material';
import DrawTool from "../../components/DrawTool";
import { connect } from "react-redux";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Control from "react-leaflet-custom-control";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NewMapNotification from '../NewMapNotification';
import MarkerClusterGroup from "react-leaflet-cluster";
import ChangeView from '../ChangeMapView';
import DialogMarker from './../DiaglogMarker';
import SearchMapView2 from '../laban';
import SearchMapView1 from '../SearchMapView1';
import SearchMapView8 from '../SearchMapView8';
import SearchMapView9 from '../SearchMapView9';

const modalStyle3 = {
  width: ['100%', '70%'], // Set width based on breakpoints
  transform: 'translateX(-50%)', // Center horizontally on desktop
  position: 'absolute',
  border: '1px solid yellow',
  background: '#fff',
  padding: 4, // Use padding instead of shorthand 'p'
  boxShadow: 10,
  position: 'fixed',
  bottom: -100,
  width: '100%', // Default width for mobile
  zIndex: 1000,
  left: '50%', // Center horizontally on desktop
  height: 0,
  borderTopLeftRadius: 10, // Apply border radius directly
  borderTopRightRadius: 10, // Apply border radius directly
  borderBottomLeftRadius: 0, // Apply border radius directly
  borderBottomRightRadius: 0, // Apply border radius directly
};

const tabBarStyle = {
  height:"3rem",
  position: 'fixed',
  bottom: 0,
  width: '100%', // Default width for mobile
  zIndex: 1000,
  left: '50%', // Center horizontally on mobile
  transform: 'translateX(-50%)', // Center horizontally on mobile
};

// Override styles for desktop
tabBarStyle['@media screen and (min-width: 768px)'] = {
  width: '50%', // 50% width on desktop
  left: '50%', // Center horizontally on desktop
  transform: 'translateX(-50%)', // Center horizontally on desktop
};
 
 

 

function getBoundsMap(arrCoordinate) {
  const corner1 = L.latLng(arrCoordinate[1], arrCoordinate[0]);
  const corner2 = L.latLng(arrCoordinate[3], arrCoordinate[2]);
  const bounds = L.latLngBounds(corner1, corner2);

  return bounds;
}



const createClusterCustomIcon = (cluster) => {
  return L.divIcon({
    html: `<span></span>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(0,0, true),
  })
}
 
 

const Map = (props ) => {

  const { dataMapJson, handleLongPress, handleCurrentViewChangen ,initialHeading = 0  } = props;
  const { bounds, center } = dataMapJson;
  const mapBound = getBoundsMap(bounds);
  const centerMap = [center[1], center[0]];
  const zoomMap = center[2];
 
  const [clickedCard, setClickedCard] = useState(false);
  const [currentView, setCurrentView] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isAddMarker, setIsAddMarker] = React.useState(false);
  const [markers, setMarkers] = useState([])
  const [currentMarker, setCurrentMarker] = useState(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false)


  const [showKHSDDLayer, setShowKHSDDLayer] = useState(false);

  const handleClick = () => {
    setShowKHSDDLayer(!showKHSDDLayer);
  };


  
  

  // const [isOpen, setIsOpen] = useState(false);
  // const handleOpenModal = () => {
  //   setIsOpen(true);
  // };

  const [isOpen2, setIsOpen2] = useState(false);
  const handleOpenModal2 = () => {
    setIsOpen2(true);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const handleOpenModal1 = () => {
    setIsOpen1(true);
  };

  // const [isOpen5, setIsOpen5] = useState(false);
  // const handleOpenModal5 = () => {
  //   setIsOpen5(true);
  // };
  const [isOpen8, setIsOpen8] = useState(false);
  const handleOpenModal8 = () => {
    setIsOpen8(true);
  };


  
  const modals = [
    // Đặt tên các modal tại đây, ví dụ:
    // 'modal1',
    // 'modal2',
    // 'modal3',
  ];

  // this.AAFiPnBgK2o = '1765258895';
  // this.XBEeQeFPIM0qIMQlRaMlNrCkRj = '6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw'; vip


  // this.AAFiPnBgK2o = '1765258895';
  // this.XBEeQeFPIM0qIMQlRaMlNrCkRj = '6875029358:AAFgQoqAiVcDL2NUhmuRI_1UgXT_ohlrj90'; ko vip


  // đây là ứng dụng , hoàn toàn miễn phí  mọi chi tiết góp ý xin LH :0799799993 Gmail tcmap.vn@gmail.com.


  // đây là ứng dụng ,App này chỉ dành cho thành viên VIP  đăng ký 500k/năm mọi chi tiết góp ý xin LH :0799799993 Gmail tcmap.vn@gmail.com.
  // const [isOpen9, setIsOpen9] = useState(false);

    const [isOpen9, setIsOpen9] = useState(true);
  const handleOpenModal9 = () => {
    setIsOpen9(true);
  };
 
 
  
  // Hàm chọn ngẫu nhiên một modal từ danh sách
  const getRandomModal = () => {
    const randomIndex = Math.floor(Math.random() * modals.length);
    return modals[randomIndex];
  };
  
  // Hàm mở modal và thiết lập lại đợi 60 giây cho lần mở modal tiếp theo
  const openModalAndScheduleNext = () => {
    const randomModal = getRandomModal(); // Chọn ngẫu nhiên một modal
    console.log(`Hiển thị modal: ${randomModal}`);
    setIsOpen9(true); // Mở modal
  
    // Thiết lập lại đợi 60 giây để mở modal tiếp theo
    setTimeout(() => {
      setIsOpen9(true); // Đóng modal hiện tại
      openModalAndScheduleNext(); // Gọi lại hàm này sau khi đã đợi 60 giây
    }, 1200000); // 60 giây là 60000 miligiây
  };
  useEffect(() => {
    openModalAndScheduleNext(); // Bắt đầu quá trình hiển thị modal ngẫu nhiên
  }, []); // Thực hiện chỉ một lần khi component được render
  
  
  // Gọi hàm mở modal lần đầu khi component được render
  useEffect(() => {
    openModalAndScheduleNext(); // Gọi hàm mở modal và thiết lập đợi 20 giây cho lần mở modal tiếp theo
  }, []); // Chạy chỉ một lần sau khi component được render

 
   
const handleCurrentViewChange = (currentView) => {
  Services.getCardMarkers(currentView).then((data) => {
  });
};

  useEffect(() => {
    Services.getMarkers().then((data) => {
      handleCurrentViewChange(data.data.data);
    });
  }, []);

  useEffect(()=>{ 
    Services.getMarkers().then(data => {
      setMarkers(data.data.data)
    })
  },[])

  // Disable your component when it's out of view
 
  // useEffect(() => {
  //   console.log("AAAA")
  // }, [isShowPlaceInfo])

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  const toggleSidebarClosePinAlert = () => {
    // console.log('Closing dialog');
    toggleSidebarClosePinAlert(false);
  };
  

  const toggleSidebar1 = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }
 
  const [opacity, setOpacity] = useState(0.);
  const [opacity1, setOpacity1] = useState(0);
  const [opacity4, setOpacity4] = useState(0);
  const [opacity3, setOpacity3] = useState(0);
  const [opacity2, setOpacity2] = useState(0);
  const [overallOpacity, setOverallOpacity] = useState(0.1);
 

  const [selectedLayer1, setSelectedLayer1] = useState(false);
  const [selectedLayer2, setSelectedLayer2] = useState(false);
  const [selectedLayer3, setSelectedLayer3] = useState(false);
  const [selectedLayer4, setSelectedLayer4] = useState(false);

 



const handleLayerChange1 = (layer) => {

switch (layer) {
  case "ThuaDat":
    setSelectedLayer1(!selectedLayer1);
    break;
  case "KHSDD2022":
    setSelectedLayer2(!selectedLayer2);
    break;
  case "KHSDD2030":
    setSelectedLayer3(!selectedLayer3);
    break;
  case "QHPKQHXD":
    setSelectedLayer4(!selectedLayer4);
    break;
  // Add more cases for additional layers if needed
  default:
    break;
}
};

 
  // Override styles for desktop
  tabBarStyle['@media screen and (min-width: 768px)'] = {
    width: '50%', // 50% width on desktop
    left: '50%', // Center horizontally on desktop
    transform: 'translateX(-50%)', // Center horizontally on desktop
  };
  
  


  
  const [openPinAlert, setOpenPinAlert] = useState(false);

  const handleOpenPinAlert = () => {
    setOpenPinAlert(true);
  };
  const handleClosePinAlert = () => {
    // console.log('Closing dialog');
    setOpenPinAlert(false);
  };
   

  const [openNewMap, setOpenNewMap] = useState(false);
  const [dataNotification, setDataNotification] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Services.getSettingNewsMap("");
        const data = response.data;
  
        if (data.data !== null) {
          const arr = data.data.filter((value) => value.is_active === true);
  
          if (arr.length > 0) {
            // Không mở modal ngay lúc này
            // setOpenNewMap(true);
            setDataNotification(arr);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render
  
  const handleOpenMapModal = () => {
    setOpenNewMap(true);
  };
  
  const handleCloseMapModal = () => {
    setOpenNewMap(false);
  };
  
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(true);
  };


 

  const toggleAddMarker = () => {
    setIsAddMarker((prev) => !prev); // Toggle the value
  };

  
    useEffect(() => {
      Services.getMarkers().then((data) => {
        setCurrentView(data.data.data);
        handleCurrentViewChange(data.data.data);
      });
    }, []);
  
    useEffect(()=>{ 
      Services.getMarkers().then(data => {
        setMarkers(data.data.data)
      })
    },[])


    
const [isMarkerVisible, setIsMarkerVisible] = useState(true);

const toggleMarkerVisibility = () => {
  setIsMarkerVisible(!isMarkerVisible);
};


const [open, setOpen] = useState(false);
const [vipDialogOpen, setVipDialogOpen] = useState(false);
const [isVip, setIsVip] = useState(false); // Giả định trạng thái VIP của người dùng

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose1 = () => {
  setOpen(false);
  setVipDialogOpen(false);
};

const handleSelectPlace = (placeData) => {
  // Thực hiện hành động với placeData nếu là VIP
  if (isVip) {
    console.log('Selected place:', placeData);
    handleClickOpen();
  } else {
    setVipDialogOpen(true);
  }
};

const upgradeToVip = () => {
  // Logic để nâng cấp người dùng lên VIP
  setIsVip(true);
  setVipDialogOpen(false);
  window.open("/THANH-toan1");
};
const handleClose = () => {
  setOpenModel(false)
}
  
const [openModel, setOpenModel] = useState(false);
const handleOpenVipDialog = () => {
  setVipDialogOpen(true);
};

const handleCloseVipDialog = () => {
  setVipDialogOpen(false);
};


  return (

 
    <MapContainer
    style={{
      zIndex: "0",
      height: '100%',
      position: 'absolute',
   
    }}
 

      scrollWheelZoom={true}
      className={classes.mapContainer}
      attributionControl={false}
      zoom={zoomMap}
      center={centerMap}
      bounds={mapBound}
      zoomControl={false}
      maxNativeZoom={19.4}
      minZoom={5} 
      maxZoom={20}
      wheelPxPerZoomLevel={99999999999999999}
      tileSize={64}
      loading="lazy"
      
      
    >
    
    <TileLayer
      url="https://mt1.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}"
      attribution= '&copy; <a href="https://tcmap.vn/">chau:0799.799.993</a>'
      tileSize= {256}
      maxNativeZoom={26}
      loading="lazy"
      minZoom={0}
      maxZoom={26}
      
    />
 
 
 

 <MarkerClusterGroup
   onClick={(e) => console.log('onClick', e)}
   iconCreateFunction={createClusterCustomIcon}
   maxClusterRadius={mapBound >= 50 ? 800 : 99999999}
   spiderfyOnMaxZoom={false}
   showCoverageOnHover={true}
   maxNativeZoom={16}
   maxZoom={17}
   minZoom={15}
   disableClusteringAtZoom={19}
   zoomToBoundsOnClick={false}
   animate={false}
   scrollWheelZoom={true}
   keepInView={true}
        >
     {isMarkerVisible &&   markers.map((data, index) => (
      <Marker
        key={index}
        keepInView={true}
        position={[data.lag, data.lng]}
        style={{
          zIndex: 999999,
        }}
        eventHandlers={{
          click: (e) => {
            setCurrentMarker(data);
          },
          currentView: [data.lag, data.lng],
          clickedCard: true,
          isMarkerDialogOpen: true,
          currentMarker: data
        }}
        permanent={true}
        icon={L.divIcon({
          className: "leaflet-tooltip4",
          iconUrl: "./assets/icons/pint.png",
          html: `
            <div style="display: flex; flex-direction: column; align-items: center;" >
              <div style="
                width: auto;
                border: 2px solid #3d94f6;
                border-radius: 10px;
                color: #3d94f6;
                background-color: white;
                height: 35px;
                font-size: 13px;
                margin: 5px;
                margin-top: 3px;
                overflow: hidden;
                white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: center;
              ">
                <p style="margin: 0;">&nbsp; ${data.name.slice(0, 7)}${data.name.length > 10 ? '...' : ''} &nbsp;</p>
              </div>
            </div>
          `,
          iconSize: [100, 0],
        })}
      />
    ))}

{!isMarkerVisible ? (
  <MapBoxVectorTile
    className={classes.mapContainer}
    isAddMarker={isAddMarker}
    setIsAddMarker={setIsAddMarker}
    opacity={props.opacity}
    keepInView={true}
  />
) : null}



        </MarkerClusterGroup> 

 
 
<div
  className="leaflet-control"
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", 
    position: 'absolute',
    right: 0,
    top:387,
    marginTop: "1px",
    background:"#fff",
    borderRadius:"5px"
    ,zIndex:999999 
  }}
> 
 
<div
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", 
    position: 'absolute',
    right: 0,
    top:387,
    marginTop: "1px",
    background:"#fff",
    borderRadius:"5px"
    ,zIndex:999999 ,
   
  }}

> 



<BottomNavigation 
  showLabels 
   
  sx={{ ...modalStyle3,     width: [`100%`, `40%`], border: "none",  border: "none",
  bottom: -0,
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)', // Chia ra 5 cột có kích thước bằng nhau
  alignItems: 'center',
  background:"#fff", height:50  }} 
  
>


<BottomNavigationAction
    sx={{ 
      bottom: 25,left:10,
      zIndex:9999999
    }}
    label="Ký hiệu"
    onClick={() => {
      handleClosePinAlert(false);
      handleOpenModal1();
    }}
    //    onClick={() => {
    //   // handleButtonClick();
    //   handleOpenVipDialog();
    // }}
    icon={<div >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 512 512"> <path d="M512 256c0 .9 0 1.8 0 2.7c-.4 36.5-33.6 61.3-70.1 61.3H344c-26.5 0-48 21.5-48 48c0 3.4 .4 6.7 1 9.9c2.1 10.2 6.5 20 10.8 29.9c6.1 13.8 12.1 27.5 12.1 42c0 31.8-21.6 60.7-53.4 62c-3.5 .1-7 .2-10.6 .2C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>
    </div>}
    
  />

  <BottomNavigationAction
      sx={{ 
        bottom: 25,zIndex:9999999
      }}
      label="Tin BĐS"
    
    onClick={() => {
      handleClosePinAlert(false);
      handleOpenModal8();
    }}
    icon={<div ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">
    <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5z"/>
    <path d="M2 3h10v2H2zm0 3h4v3H2zm0 4h4v1H2zm0 2h4v1H2zm5-6h2v1H7zm3 0h2v1h-2zM7 8h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2zm-3 2h2v1H7zm3 0h2v1h-2z"/>
  </svg></div>}
  /> 
  
  <BottomNavigationAction
   

   onClick={() => {
     if (localStorage.getItem("token") === null) {
         window.location.href = "/login";  
     } else {
      handleOpenPinAlert();
     }
 }}
      //  onClick={() => {
      //     // handleButtonClick();
      //     handleOpenVipDialog();
      //   }}
 
 

   icon={
     <Avatar
     className="logo" 
       src="../assets/icons/logo512.png"
       style={{
         position: 'fixed',
         bottom: 10,  
         height: '5rem',
         width: '5rem',
         fontSize: '0.8em',
         border: '10px solid GreenYellow',
         background: '#fff',
         boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.5)',
       }}
     ></Avatar>
   }
 />  

  <BottomNavigationAction
      sx={{ 
        bottom: 25,
      }}
    label="La bàn"
    onClick={() => {
      handleClosePinAlert(false);
      handleOpenModal2(); 
    }}
    // onClick={() => {
    //   // handleButtonClick();
    //   handleOpenVipDialog();
    // }}
    icon={<div ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-browser-safari" viewBox="0 0 16 16">
    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.25-14.75v1.5a.25.25 0 0 1-.5 0v-1.5a.25.25 0 0 1 .5 0m0 12v1.5a.25.25 0 1 1-.5 0v-1.5a.25.25 0 1 1 .5 0M4.5 1.938a.25.25 0 0 1 .342.091l.75 1.3a.25.25 0 0 1-.434.25l-.75-1.3a.25.25 0 0 1 .092-.341m6 10.392a.25.25 0 0 1 .341.092l.75 1.299a.25.25 0 1 1-.432.25l-.75-1.3a.25.25 0 0 1 .091-.34ZM2.28 4.408l1.298.75a.25.25 0 0 1-.25.434l-1.299-.75a.25.25 0 0 1 .25-.434Zm10.392 6 1.299.75a.25.25 0 1 1-.25.434l-1.3-.75a.25.25 0 0 1 .25-.434ZM1 8a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 0 .5h-1.5A.25.25 0 0 1 1 8m12 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 1 1 0 .5h-1.5A.25.25 0 0 1 13 8M2.03 11.159l1.298-.75a.25.25 0 0 1 .25.432l-1.299.75a.25.25 0 0 1-.25-.432Zm10.392-6 1.299-.75a.25.25 0 1 1 .25.433l-1.3.75a.25.25 0 0 1-.25-.434ZM4.5 14.061a.25.25 0 0 1-.092-.341l.75-1.3a.25.25 0 0 1 .434.25l-.75 1.3a.25.25 0 0 1-.342.091m6-10.392a.25.25 0 0 1-.091-.342l.75-1.299a.25.25 0 1 1 .432.25l-.75 1.3a.25.25 0 0 1-.341.09ZM6.494 1.415l.13.483a.25.25 0 1 1-.483.13l-.13-.483a.25.25 0 0 1 .483-.13M9.86 13.972l.13.483a.25.25 0 1 1-.483.13l-.13-.483a.25.25 0 0 1 .483-.13M3.05 3.05a.25.25 0 0 1 .354 0l.353.354a.25.25 0 0 1-.353.353l-.354-.353a.25.25 0 0 1 0-.354m9.193 9.193a.25.25 0 0 1 .353 0l.354.353a.25.25 0 1 1-.354.354l-.353-.354a.25.25 0 0 1 0-.353M1.545 6.01l.483.13a.25.25 0 1 1-.13.483l-.483-.13a.25.25 0 1 1 .13-.482Zm12.557 3.365.483.13a.25.25 0 1 1-.13.483l-.483-.13a.25.25 0 1 1 .13-.483m-12.863.436a.25.25 0 0 1 .176-.306l.483-.13a.25.25 0 1 1 .13.483l-.483.13a.25.25 0 0 1-.306-.177m12.557-3.365a.25.25 0 0 1 .176-.306l.483-.13a.25.25 0 1 1 .13.483l-.483.13a.25.25 0 0 1-.306-.177M3.045 12.944a.3.3 0 0 1-.029-.376l3.898-5.592a.3.3 0 0 1 .062-.062l5.602-3.884a.278.278 0 0 1 .392.392L9.086 9.024a.3.3 0 0 1-.062.062l-5.592 3.898a.3.3 0 0 1-.382-.034zm3.143 1.817a.25.25 0 0 1-.176-.306l.129-.483a.25.25 0 0 1 .483.13l-.13.483a.25.25 0 0 1-.306.176M9.553 2.204a.25.25 0 0 1-.177-.306l.13-.483a.25.25 0 1 1 .483.13l-.13.483a.25.25 0 0 1-.306.176"/>
  </svg></div>}
    
  />  

  <BottomNavigationAction
      sx={{ 
        bottom: 25,left:-10
      }}
    label="QH mới"
    onClick={handleOpenMapModal}
    
    icon={<div ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 576 512"><path d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg></div>}
  />

  

  

 

  
 
</BottomNavigation>


</div>


           



           
</div>
             
 




 

 
 
<div   style={{
                     flexGrow: 1, display:"flex", position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      bottom: 5,
                      marginTop: "1px",
                      
                    }} 
                                >

 




  <Dialog  
    onClose={handleClosePinAlert}
  placement="bottom"
  open={openPinAlert} 
   PaperProps={{ elevation: 0 }}
 BackdropProps={{ invisible: true }} 
  style={{
    color: "black",
    position: "fixed",
    height: 350,
    width: "100%",
    bottom: 150,
    left: "50%",
    transform: "translate(-50%, -50%)", // Đặt Dialog chính giữa màn hình
    top: "80%", // Dịch chuyển Dialog xuống
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)", // Đổ bóng
    border: "none",
    background: "none", // Màu nền trắng với độ trong suốt 0.8
    transition: "top 0.3s, bottom 0.3s" // Thêm transition cho top và bottom'bo
    ,border:"none"
  }}> 

  {/* <button onClick={handleClosePinAlert}     style={{
      color: "black",
      top:0,
      borderRadius: "20px",
      margin: "3px",
      fontSize: "0.8rem",
      height: "35px",
      transform: "translateX(0%)",
      width: 240,
      textAlign: "center",
    }}> 
</button>  */}
<br/><br/>
 
{/* <div className="map-rotation-controls">
        <button onClick={() => handleRotateMap(-90)}>Rotate Left</button>
        <button onClick={() => handleRotateMap(90)}>Rotate Right</button>
      </div> */}


<h>
  Chọn lớp quy hoạch
</h>

  <DialogActions   BackdropProps={{ style: { backgroundColor: "transparent" } } }style={{ display: 'flex', flexDirection: 'column' }}>

  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <button   onClick={handleClosePinAlert}  style={{  top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} >❌</button>
        </div>


        <button   onClick={handleClosePinAlert}  style={{  top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} >❌</button>
          
     
        


<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <button
    style={{
      color: "black",
      background: selectedLayer4 ? "#00ff00" : "#c4aa009f",
      borderRadius: "20px",
      margin: "3px",
      fontSize: "0.8rem",
      height: "35px",
      transform: "translateX(0%)",
      width: 240,
      textAlign: "center",
      border: "1px solid DodgerBlue",
    }}
    active={selectedLayer4}
    onClick={() => handleLayerChange1("QHPKQHXD")}
  >
   QH phân khu - QH xây dựng
  </button>
 
</div>
  <br/>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <button
    style={{
      color: "black",
      background: selectedLayer3 ? "#00ff00" : "#c4aa009f",
      borderRadius: "20px",
      margin: "3px",
      fontSize: "0.8rem",
      height: "35px",
      transform: "translateX(0%)",
      width: 240,
      textAlign: "center",
      border: "1px solid DodgerBlue",
    }}
    active={selectedLayer3}
    onClick={() => handleLayerChange1("KHSDD2030")}
  >
   Quy hoạch sử dụng đất năm 2030
  </button>
</div>


<br/>

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <button
    style={{
      color: "black",
      background: selectedLayer2 ? "#00ff00" : "#c4aa009f",
      borderRadius: "20px",
      margin: "3px",
      fontSize: "0.8rem",
      height: "35px",
      transform: "translateX(0%)",
      width: 240,
      textAlign: "center",
      border: "1px solid DodgerBlue",
    }}
    active={selectedLayer2}
    onClick={() => handleLayerChange1("KHSDD2022")}
  >
    Kế hoạch sử dụng đất 2022 - 2024
  </button>
 

</div> 






 




  </DialogActions>

  
</Dialog>


  

</div>








 





{openNewMap && (
  <NewMapNotification
    open={openNewMap}
    handleClose={handleCloseMapModal}
    data={dataNotification}
    // ... other props you want to pass
  />
)}

 
  {selectedLayer4 && (
  <TileLayer
    url={Utils.URL_IMAGE4}
    maxNativeZoom={15}
    minZoom={10}
    loading="lazy"
    maxZoom={22}
    opacity={(parseFloat(opacity4) + parseFloat(opacity) + parseFloat(props.opacity) + parseFloat(props.opacity)/ 100)}
  />
)}

{selectedLayer3 && (
  <TileLayer
    url={Utils.URL_IMAGE3}
    maxNativeZoom={15}
    minZoom={10}
    loading="lazy"
    maxZoom={22}
    opacity={(parseFloat(opacity3) + parseFloat(opacity) + parseFloat(props.opacity) + parseFloat(props.opacity)/ 100)}
  />
)}
 

 


{selectedLayer2 && (
  <TileLayer
    url={Utils.URL_IMAGE}
    maxNativeZoom={15}
    minZoom={10}
    loading="lazy"
    maxZoom={22}
    opacity={(parseFloat(opacity2) + parseFloat(opacity) + parseFloat(props.opacity) + parseFloat(props.opacity)/ 100)}
  />
)}


{selectedLayer1 && (
  <TileLayer
    url={Utils.URL_IMAGE2}
    maxNativeZoom={18}
    minZoom={18}
    loading="lazy"
    maxZoom={22}
    opacity={(parseFloat(opacity1) + parseFloat(opacity) + parseFloat(props.opacity) + parseFloat(props.opacity)/ 100)}
  />
)}

    
<MapBoxVectorTile
    className={classes.mapContainer}
    keepInView={true}
    opacity={props.opacity}
    isAddMarker={isAddMarker}
    setIsAddMarker={setIsAddMarker}
  />
    
 {currentMarker != null ? <DialogMarker state={{currentMarker: currentMarker, isMarkerDialogOpen: currentMarker != null}} handleClose={()=> {
        setCurrentMarker(null)
      }}></DialogMarker>: null}

{clickedCard ? (
        <ChangeView center={currentView} setClicked={setClickedCard} zoom={20} />
      ) : null}

 

<SearchMapView2
      open={isOpen2}
      handleClose={() => setIsOpen2(false)}
      // Truyền các props khác cần thiết
    />
    
<SearchMapView1
      open={isOpen1}
      handleClose={() => setIsOpen1(false)}
      // Truyền các props khác cần thiết
    />
    <SearchMapView8
      open={isOpen8}
      handleClose={() => setIsOpen8(false)}
      // Truyền các props khác cần thiết
    />

{/* <SearchMapView9
      open={isOpen9}
      handleClose={() => setIsOpen9(false)}
      // Truyền các props khác cần thiết
    />  */}



      <Dialog
        open={vipDialogOpen}
        onClose={handleClose1}
        aria-labelledby="vip-dialog-title"
        aria-describedby="vip-dialog-description"
      >
            <span onClick={handleClose1} style={{  top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} color="primary">
            ❌
          </span>
        <DialogTitle id="vip-dialog-title">
        
        </DialogTitle>
        <DialogContent>
          <p>Tính năng này cần nâng cấp lên tài khoản VIP để sử dụng</p>
          

      
          <div  onClick = {upgradeToVip}style={{
               
                backgroundImage: `url(../assets/icons/1000_F_529985028_eDc7RMZWRqT8knkwTz0RbeUgS9tlGYMM.webp)`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "2rem",
                
              }}>
          
              </div>
              <h1 onClick = {upgradeToVip} className="old-price" style={{ fontSize:"15px" }}>Nâng cấp VIP ngay</h1>

          

        </DialogContent>
   
        <DialogActions>
 
  

       


        </DialogActions>
      </Dialog>
    
    </MapContainer>
    
  );
};

const mapStateToProps = (state) => ({
  dataMapJson: state.mapBoxVectorTile.dataMapJson,
  data: state.data
});


const mapDispatchToProps = {};

 
export default connect(mapStateToProps, mapDispatchToProps)(Map);
