import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyC2v6hw5ORIA5AO-tVbqkrkHyVhU9bEAcQ",
    authDomain: "tcdatdai2.firebaseapp.com",
    projectId: "tcdatdai2",
    storageBucket: "tcdatdai2.appspot.com",
    messagingSenderId: "683897883804",
    appId: "1:683897883804:web:6fbd031a1693cbdaaaf159",
    measurementId: "G-DG2E6XERBC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { auth, app };