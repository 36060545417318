export const MAPS_TILE_LAYER_URL = 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';
export const MAPS_TILE_LAYER_FREE = 'http://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
export const MAP_TILE_ATTRIBUTION = '&copy; <a href="">OpenStreetMap</a>  &copy; <a href="">MapBox</a>';
export const MAP_TILE_LAYER_ATTRIBUTION = '';
export const SELECTED_STYLE = {
  fill: true,
  weight: 1,
  fillColor: "#0BFB06",
  color: "rgb(245,227,7)",
  fillOpacity: 0.2,
  opacity: 1,
};
export const NOT_SELECTED_STYLE = {
  fill: false,
  weight: 1,
  fillColor: "#0BFB06",
  color: "rgb(245,227,7)",
  fillOpacity: 0.2,
  opacity: 1,
};

export const DISTRICTS = [
 
  {
      value: 'Miền Nam',
      label: 'Miền Nam',
  },
  {
      value: 'Miền Tây',
      label: 'Miền Tây',
  },
  {
      value: 'Tây Nguyên',
      label: 'Tây Nguyên',
  },
  {
    value: 'Miền Trung',
    label: 'Miền Trung',
  },
  {
    value: 'Miền Bắc',
    label: 'Miền Bắc',
  },
  {
    value: 'Dự án',
    label: 'Dự án',
  },
]
export const CAN_GIUOC_URL_MAPBOX_VECTOR_TILE = 'https://tcmap.vn:7070/data/my-loc-2021/{z}/{x}/{y}.pbf';
export const URL_SERVER = 'https://tcmap.vn:7070';
export const DATA_JSON_NAME_FILE = 'my-loc-2021';