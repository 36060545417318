module.exports = {
    // baseurl: "http://34.124.165.217:9000/api/",
    baseurl: "https://tcmap.vn:7000/api/",
    // baseurl: "http://localhost:8000/api/",
    //endpoint
    login: "login",
    register: "register",
    users: "users",
    emailOtp: "email_otp",
    resetPassword: "forgot_password",
    changePassword: "change_password",
    user: "user",
    admin_user: "admin_user",
    admin_reset_password: "admin_reset_password",
    active_account: "active_account",
    checkAuthentication: "checkAuthentication",
    car_markers: "car_markers",
    markers: "markers",
    admin_markers: "admin-markers",
    marker: "marker",
    maps: "maps",
    map: "map",
    all_maps: "all_maps",
    search_maps: "search_maps",
    search_maps_location: "search_maps_location",
    settingMap: "setting_map",
    news: "news",
    notification: "notification",
    comment: "comment",
    image: "image",
    change_view: "change_view"
}