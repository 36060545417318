import React, { useState } from "react";
import NewsItem from "../NewsItem/NewsItem";
import "./NewsList.css";
import unorm from "unorm";
import Form from "react-bootstrap/Form";
import { Box,Button, CardMedia, MenuItem, TextField, Modal, Stack, IconButton } from '@mui/material';

const NewsList = (props) => {
  const [searchQuery, setSearchQuery] = useState("");

  const normalizeString = (str) => {
    return unorm.nfd(str).replace(/[\u0300-\u036f]/g, "");
  };

  const handleSearch = () => {
    const normalizedSearchQuery = normalizeString(searchQuery.toLowerCase());

    // Implement the logic to filter news based on the normalizedSearchQuery
    const filteredNews = props.news.filter((item) => {
      const normalizedTitle = normalizeString(item.title.toLowerCase());
      return normalizedTitle.includes(normalizedSearchQuery);
    });

    // Return the filtered news
    return filteredNews.map((item) => (
      <NewsItem
        title={item.title}
        img={item.img}
        key={item.id}
        id={item.id}
        shortDescription={item.shortDescription}
      />
    ));
  };

  return (
    <Stack>
      <Stack >
      <Form.Control
            className="leaflet-tooltip9"
            fullWidth
            placeholder="search...."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              cursor: 'pointer',
              size: '10px',
              borderRadius: '15px',
              fontSize: '18px',
              color: 'black',
              display: 'block',
              background: '#ffff',
              width: '100%',
              height:40,
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center', // Center the text within the input
              top: 10,
            }}
          />

      </Stack>
 <Stack>
 <div className="news-list">
        {searchQuery ? (
          handleSearch()
        ) : (
          props.news.map((item) => (
            <NewsItem
              title={item.title}
              img={item.img}
              key={item.id}
              id={item.id}
              shortDescription={item.shortDescription}
            />
          ))
        )}
      </div>
 </Stack>
 
    
    </Stack>
  );
};

export default NewsList;
