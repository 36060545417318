import React, { useEffect, useState } from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
 

import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import NewsList from "../Components/NewsList/NewsList";
import Container from "../Components/UI/Conainer";
import default_news from "../utils/news.json";
import "../../../styles.css";
import { Services } from "../../../APIs/Services";
import { Box, Modal,Avatar ,IconButton} from '@mui/material';

export default function NewsHome(handleClose) {
  const [openModal, setOpenModal] = useState(false);
  const [news, setNews] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all'); // Default category

  useEffect(() => {
    Services.getNews("").then((data) => {
      setNews(data.data.data);
    });
    setOpenModal(true);
  }, []);

 

  const modalStyle = {
    width: ['100%', '50%'], // Set width based on breakpoints
    transform: 'translateX(-50%)', // Center horizontally on desktop
    position: 'absolute',
    overflowY: 'auto',
    border: 'none',
    background: 'rgba(255, 255, 255, 0.95)',
    p: 4,
    boxShadow: 10,
    borderRadius: 2,
    border: "1px solid Yellow",
    position: "fixed",
    bottom: 0,
    width: "100%", // Default width for mobile
    zIndex: 1000,
    transform: "translateX(-50%)", // Center horizontally on mobile
    left: "50%", // Center horizontally on desktop
 
  };
  
  const contentStyle = {
    overflowY: "auto",
    height: "calc(100% - 56px)",
  };
  const tabBarStyle = {
    height:"3rem",
    position: 'fixed',
    bottom: 0,
    width: '100%', // Default width for mobile
    zIndex: 1000,
    left: '50%', // Center horizontally on mobile
    transform: 'translateX(-50%)', // Center horizontally on mobile
  };
  
  // Override styles for desktop
  tabBarStyle['@media screen and (min-width: 768px)'] = {
    width: '50%', // 50% width on desktop
    left: '50%', // Center horizontally on desktop
    transform: 'translateX(-50%)', // Center horizontally on desktop
  };
  
  
  // ... rest of your component
  
  
  // ... rest of your component
  
  const iconStyle = {
    fontSize: '2rem', // Adjust the font size to make the icon larger
    height: 'auto', // Ensure the icon scales proportionally
  };


  const modalStyle1 = {
    width: ['100%', '50%'], // Set width based on breakpoints
    transform: 'translateX(-50%)',
    left: "50%", // Center horizontally on desktop
    position: 'absolute',
    height: '100%',
    overflowY: 'auto',
    border: 'none',
    p: 4,
    boxShadow: 10,
    borderRadius: 2,
  };


  return (
    <div>
      <Modal open={openModal} onClose={() => setOpenModal(true)}>
        <Box >
          <Box className="Home"sx={{ ...modalStyle1, width: [`100%`, `50%`], border: "none", }} >
            {/* <Navbar changeCategoryHandler={changeCategoryHandler} /> */}
            <Container style={contentStyle}>
              <NewsList news={news} />
            </Container>
          </Box>
          <IconButton onClick={handleClose} style={{ top: "1", right: "2vh", cursor: "pointer", color: "red", background: "#ffff", size: "10px", position: "absolute", borderRadius: "30px", margin: "3px", padding: "5px 10px", fontSize: "small" }} className="leaflet-tooltip9">
            ❌
          </IconButton>
 
          <BottomNavigation showLabels sx={{ ...modalStyle, width: [`100%`, `50%`], border: "none", }} >
            <BottomNavigationAction label="All" href="/tintuc"  style={iconStyle} />
            <BottomNavigationAction label="Sports" href="/map?id=2504"  style={iconStyle} />
            {/* Use Avatar for custom styling */}
            <BottomNavigationAction
                href="/"
                icon={
                  <div className="logo" style={{ textAlign: "center", fontSize: "15px", color: "rgb(141, 2, 255)", whiteSpace: 'nowrap' }}>
                    quy hoạch
                    <Avatar src="../assets/icons/logo512.png" style={{ ...iconStyle, height: '4rem', width: '4rem', top: -5 }} />
                  </div>
                }
              />

            <BottomNavigationAction label="Business" href="/tintuc"  style={iconStyle} />
            <BottomNavigationAction label="Florist" href="/tintuc"  style={iconStyle} />
          </BottomNavigation>
        </Box>
      </Modal>
    </div>
  );
}
