import { useEffect, useState } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import L from "leaflet";
import "leaflet-geometryutil";

const Geoman = () => {
  const context = useLeafletContext();
  const [area, setArea] = useState(0);
  const [length, setLength] = useState(0);
  const [isMeasuring, setIsMeasuring] = useState(false);

  useEffect(() => {
    const leafletContainer = context.layerContainer || context.map;

    // Tạo container và link
    var container = L.DomUtil.create("div", "custom-control1");
    var link = L.DomUtil.create("a", "custom-control1", container);
    link.innerHTML = "Your Custom Control HTML";
    leafletContainer.pm.addControls({
      position: "topright", // Vị trí của biểu tượng
      fillColor: 'green',    // Màu sắc của biểu tượng
      strokeColor: 'black',  // Màu sắc của viền biểu tượng
      opacity: 1,            // Độ mờ của biểu tượng
      lineCap: 'round',      // Kiểu kết thúc của đường biểu tượng
      lineJoin: 'round',     // Kiểu nối của đường biểu tượng
      weight: 2,             // Độ dày của đường biểu tượng
      dashArray: '5, 10',    // Kiểu nét đứt của đường biểu tượng
  
      title: "đo vẽ diện tích",

      drawCircle: false,
      drawMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawText: false,
      editMode: false,
      dragMode: false,
      removalMode: false,
      rotateMode: false,
      oneBlock: true,
      drawCircleMarker: false,
      editControls: true,
      cutPolygon: false,
      iconControls: {
        polygon: {
        
          fillColor: 'green',    // Màu sắc của biểu tượng
          strokeColor: 'black',  // Màu sắc của viền biểu tượng
          opacity: 1,            // Độ mờ của biểu tượng
          lineCap: 'round',      // Kiểu kết thúc của đường biểu tượng
          lineJoin: 'round',     // Kiểu nối của đường biểu tượng
          weight: 2,             // Độ dày của đường biểu tượng
          dashArray: '5, 10',    // Kiểu nét đứt của đường biểu tượng
          icon: L.divIcon({      // Tạo biểu tượng tùy chỉnh
            className: 'custom-polygon-icon',
            html: 'Your Custom Icon HTML',
            iconSize: [32, 32],   // Kích thước biểu tượng
          })
        },
        // Thêm các tùy chọn cho các chức năng khác nếu cần
      }
    });
    

    leafletContainer.on("pm:create", (e) => {
      if (e.layer && e.layer.pm) {
        const shape = e.layer;
        shape.pm.enable();
        setIsMeasuring(true);

        const shapeType = shape.pm.getShape();
        if (shapeType === "Polygon") {
          const latLngs = shape.getLatLngs()[0];
          let area = L.GeometryUtil.geodesicArea(latLngs);
          let length = L.GeometryUtil.length(latLngs);
          let tooltipContent = `${area.toFixed(1)} m²`;

          const updateShapeTooltip = () => {
            let tooltipContent = `${area.toFixed(1)} m²`;
            if (length) {
              tooltipContent += ` | ${length.toFixed(1)} m`;
            }
            shape.setTooltipContent(tooltipContent);
          };

          shape.bindTooltip(tooltipContent, {
            permanent: true,
            direction: "center",
            fillColor: "red",
          }).openTooltip();

          shape.on("pm:edit", (e) => {
            updateShapeTooltip();
            shape.openTooltip();
          });

          const lines = [];

          for (let i = 0; i < latLngs.length; i++) {
            const startLatLng = latLngs[i];
            const endLatLng = latLngs[(i + 1) % latLngs.length];
            const middleLatLng = L.latLng(
              (startLatLng.lat + endLatLng.lat) / 2,
              (startLatLng.lng + endLatLng.lng) / 2
            );

            const line = L.polyline([startLatLng, endLatLng], { color: 'red' });
            const lineLength = L.GeometryUtil.length([startLatLng, endLatLng]);
            const tooltipContent = `${lineLength.toFixed(1)} m`;
            line.bindTooltip(tooltipContent, {
              permanent: true,
              direction: "center",
              fillColor: "red",
              title : "chia sẻ vị trí",
            }).openTooltip(middleLatLng);

            line.addTo(leafletContainer);
            lines.push(line);
          }

          shape.on("pm:edit", (e) => {
            const editedLayer = e.target;
            const editedLatLngs = editedLayer.getLatLngs()[0];
            area = L.GeometryUtil.geodesicArea(editedLatLngs);
            length = L.GeometryUtil.length(editedLatLngs);
            updateShapeTooltip();

            lines.forEach((line) => {
              leafletContainer.removeLayer(line);
            });

            lines.length = 0;

            for (let i = 0; i < editedLatLngs.length; i++) {
              const startLatLng = editedLatLngs[i];
              const endLatLng = editedLatLngs[(i + 1) % editedLatLngs.length];
              const middleLatLng = L.latLng(
                (startLatLng.lat + endLatLng.lat) / 2,
                (startLatLng.lng + endLatLng.lng) / 2
              );

              const line = L.polyline([startLatLng, endLatLng], { color: 'red' });
              const lineLength = L.GeometryUtil.length([startLatLng, endLatLng]);
              const tooltipContent = `${lineLength.toFixed(1)} m`;
              line.bindTooltip(tooltipContent, {
                permanent: true,
                direction: "center",
                fillColor: "red",
              }).openTooltip(middleLatLng);
              

              line.addTo(leafletContainer);
              lines.push(line);
            }

            setArea(area);
            setLength(length);
          });
        }
      } 
    });

    return () => {
      leafletContainer.pm.removeControls();
      leafletContainer.off("pm:create");
    };
  }, [context]);

  const handleClearShapes = () => {
    const leafletContainer = context.layerContainer || context.map;

    leafletContainer.eachLayer((layer) => {
      if (layer.pm) {
        layer.pm.disable();
        leafletContainer.removeLayer(layer);
      }
    });

    setIsMeasuring(false);
  };

  return (
    <>
      {isMeasuring && (
        <div className="leaflet-control" style={{background:"#fff",borderRadius:"10px", position: 'absolute', top: '20px', right: '30px' ,color:"red", border: "1px solid GreenYellow",}}>
          <span className="codepro-custom-btn codepro-btn-59" onClick={handleClearShapes}>❌</span>
        </div>
      )}
    </>
  );
};

export default Geoman;
