import { Box, Button, TextField, Modal, Stack, MenuItem ,IconButton ,Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {DISTRICTS} from './../constants/maps.constant'
import { Services } from '../APIs/Services';
import Network from '../APIs/Network';
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
// import Select from 'react-select';
 
import { useGeolocated } from 'react-geolocated';

const style = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  maxWidth: {xs: "100%",md: '30%'},
  minHeight: {xs: "100%",md: '100%'},
  overflow: 'scroll',
  display: "block",
  maxHeight: "80%",
  border: 'none',
  borderRadius:"20px",
  boxShadow: 24,
  font: "15 px",
  background : "#fff" ,
  p: 4,
	left: {xs: "50%",md: '50%'},
	top: {xs: "50%",md: '50%'},
	minWidth: {xs: "100%",md: '40%'},
  };
export default function MarkerForm({open,handleClose, newMarkerCoords, handleClickConfirmAlert}) {

    
 
    // const [markerName, setMarkerName] = React.useState("");
    // const [markerYoutubeURL, setMarkerYoutubeURL] = React.useState("");
    // const [markerDistrict, setMarkerDistrict] = React.useState("");
    // const [markerDes, setMarkerDes] = React.useState("");

 
    // const [district, setDistrict] = React.useState("Quận 1");
 
 

    const [images, setImages] = useState([]);

    const handleImageChange = (event) => {
      const selectedFiles = event.target.files;
      const newImages = [...images];
      let processedImagesCount = 0; // Số lượng ảnh đã được xử lý
    
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
    
        if (file) {
          // Assuming Services.addImage is an asynchronous function that uploads the image
          Services.addImage(file).then((data) => {
            newImages.push(Network.baseurl + Network.image + `?image_id=${data.data.data.id}`);
            processedImagesCount++; // Tăng số lượng ảnh đã xử lý
    
            // Nếu đây là ảnh cuối cùng được xử lý, cập nhật state
            if (processedImagesCount === selectedFiles.length) {
              setImages(newImages);
            }
          });
        }
      }
    };
    
    const removeImage = (index) => {
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
    };
    
    const selectImages = () => {
      document.getElementById('fileInput').click();
    };
    


    const saveMarker = () => {
        Services.addMarker(markerName, new Date().getTime()/1000 + 4*30*24*60*60,markerDes,images.join(','), markerYoutubeURL, markerDistrict,newMarkerCoords[1], newMarkerCoords[0],"Test").then(data=>{
          if (data.data.success){
            handleClose()
            handleClickConfirmAlert()
          }
        })
      }




      
      
      const [markerDistrict, setMarkerDistrict] = React.useState('');
      const [markerDes, setMarkerDes] = React.useState('');
 
      const [youtubeURLError, setYoutubeURLError] = React.useState('');
      const [districtError, setDistrictError] = React.useState('');
      const [desError, setDesError] = React.useState('');
      const [imagesError, setimagesError] = React.useState('');

      
  
      const submitMarker = () => {
        // Kiểm tra và hiển thị thông báo cho mỗi trường nếu nó bị trống
        if (markerName.trim() === '') {
          setNameError('Vui lòng nhập giá bán');
        } else {
          setNameError('');
        }
    
        if (markerYoutubeURL.trim() === '') {
          setYoutubeURLError('có thể để trống');
        } else {
          setYoutubeURLError('');
        }
    
        if (markerDistrict.trim() === '') {
          setDistrictError('Vui lòng chọn khu vực');
        } else {
          setDistrictError('');
        }
    
        if (markerDes.trim() === '') {
          setDesError('Vui lòng nhập mô tả bài đăng');
        } else {
          setDesError('');
        }

        // Nếu tất cả các trường đều không bị trống, tiến hành lưu marker
        if (markerName.trim() !== '' && markerYoutubeURL.trim() !== '' && markerDistrict.trim() !== '' && markerDes.trim() !== ''  ) {
          saveMarker();
        }
      };
    
   
   
    
      const handleDistrictChange = (event) => {
        setMarkerDistrict(event.target.value);
        setDistrictError('');
      };
    
      const handleMarkerDesChange = (event) => {
        // Thực hiện xử lý các ký tự đặc biệt ở đây trước khi lưu vào state
        let des = event.target.value;
        des = des.replace(/\\/g, '\\\\'); // Thêm dấu gạch chéo trước khi gạch chéo
        des = des.replace(/"/g, '\\"'); // Thêm dấu gạch chéo trước dấu nháy kép
        setMarkerDes(des);
        setDesError('');
      };
      





    
 
 
    const [price, setPrice] = useState('');
    const [area, setArea] = useState('');
    const [markerName, setMarkerName] = useState('');
    const [nameError, setNameError] = useState('');

 
    const convertToText = (number) => {
      const billion = Math.floor(number / 1000000000);
      const million = Math.floor((number % 1000000000) / 1000000);
      const thousand = Math.floor((number % 1000000) / 1000);
      const remainder = number % 1000;
    
      let text = '';
      
      if (billion > 0) {
        text += `${billion} tỷ`;
      }
    
      if (million > 0) {
        text += `${text ? ' ' : ''}${million}tr`;
      }
    
      // if (thousand > 0) {
      //   text += `${text ? ' ' : ''}${thousand} K`;
      // }
    
      // if (remainder > 0) {
      //   text += `${text ? ' ' : ''}${remainder}`;
      // }
    
      return text;
    };
    
  

// Xử lý sự kiện thay đổi giá tiền
const handleChangePrice = (event) => {
  const value = parseFloat(event.target.value);
  setPrice(value);
  const textValue = convertToText(value);
  setPrice(value);
  calculatePricePerSquareMeter(value, area);
  // Hiển thị giá trị dưới dạng chuỗi tương ứng
  console.log(textValue); // Hoặc bạn có thể thêm code để hiển thị trên giao diện người dùng
};




    const handleChangeArea = (event) => {
        const value = event.target.value;
        setArea(value);
        calculatePricePerSquareMeter(price, value);
    };

    const calculatePricePerSquareMeter = (price, area) => {
      const parsedPrice = parseFloat(price);
      const parsedArea = parseFloat(area);
      if (!isNaN(parsedPrice) && !isNaN(parsedArea) && parsedArea !== 0) {
        let pricePerSquareMeter = parsedPrice / parsedArea;
        pricePerSquareMeter = Math.round(pricePerSquareMeter * 100) / 100;
    
        let displayArea;
        if (parsedArea >= 1000000000) {
          displayArea = `${(parsedArea / 1000000000).toFixed(1)} tỷ m²`;
        } else if (parsedArea >= 1000000) {
          displayArea = `${(parsedArea / 1000000).toFixed(1)}tr m²`;
        } else if (parsedArea >= 1000) {
          displayArea = `${(parsedArea / 1000).toFixed(0)}K m²`;
        } else {
          displayArea = `${parsedArea} m²`;
        }
    
        let displayValue;
        if (pricePerSquareMeter >= 1000000000) {
          const billionValue = Math.floor(pricePerSquareMeter / 1000000000); 
          const millionValue = Math.floor((pricePerSquareMeter % 1000000000) / 1000000);
          const thousandValue = Math.floor((pricePerSquareMeter % 1000000) / 1000); 
          displayValue = `${billionValue.toLocaleString('vi-VN')} tỷ`;
          if (millionValue > 0) {
            displayValue += ` ${millionValue.toLocaleString('vi-VN')}tr`;
          }
          if (thousandValue > 0) {
            displayValue += ` ${thousandValue.toLocaleString('vi-VN')}K`;
          }
        } else if (pricePerSquareMeter >= 1000000) {
          const millionValue = Math.floor(pricePerSquareMeter / 1000000); 
          const thousandValue = Math.floor((pricePerSquareMeter % 1000000) / 1000); 
          displayValue = `${millionValue.toLocaleString('vi-VN')}tr`;
          if (thousandValue > 0) {
            displayValue += ` ${thousandValue.toLocaleString('vi-VN')}K`;
          }
        } else {
          const minimumPrice = 1000;
          if (pricePerSquareMeter < minimumPrice) {
            displayValue = minimumPrice.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
          } else {
            displayValue = pricePerSquareMeter.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
          }
        }
    
        setMarkerName(`${displayValue} /m².ㅤ${area}m².ㅤ${convertToText(price)}`);
      } else {
        setMarkerName('Vui long nhập giá, vs diện tích');
      }
    };




    const [markerYoutubeURL, setMarkerYoutubeURL] = React.useState('');
  const { coords } = useGeolocated();
  const [locationInfo, setLocationInfo] = useState(null);

  useEffect(() => {
    if (coords) {
      fetchLocationInfo(coords);
    }
  }, [coords]);

  useEffect(() => {
    if (locationInfo) {
      // Thiết lập giá trị mặc định chỉ khi component được render lần đầu tiên
      setMarkerYoutubeURL(locationInfo.address);
    }
  }, [locationInfo]);

  const fetchLocationInfo = async (coords) => {
    const { latitude, longitude } = coords;
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();

      // Trích xuất các thành phần của địa chỉ từ dữ liệu trả về
      const village = data.address.village || data.address.hamlet || data.address.town || data.address.city || '';
      const county = data.address.county || '';
      const state = data.address.state || '';

      // Tạo chuỗi địa chỉ mới theo định dạng "Xã - Huyện - Tỉnh"
      // const formattedAddress = `➤ ${village} - ${county} - ${state}`;
      const formattedAddress = `➤ ${village} - ${county} - ${state} `;

      // Cập nhật state để hiển thị thông tin địa chỉ mong muốn
      setLocationInfo({ latitude, longitude, address: formattedAddress });
    } catch (error) {
      console.log('Error fetching address information:', error);
    }
  };




  
  const handleMarkerYoutubeChange = (event) => {
    const newValue = event.target.value;
    setMarkerYoutubeURL(newValue);
  };
  
  
  
 
    
    return (
        <Modal
        style={{zIndex: 0}}
        open={open}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        onClose={handleClose}
        >




            <Box sx={style}>
           
                            <IconButton   onClick={() => { handleClose(false); }}  style={{  top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} >❌</IconButton> 
                            
                            <br/>
                <div className="container">
                   <div className="row">
                       <Offcanvas.Title
                            style={{
                            width: "100%",
                            display: "flex",
                            Color: "rgb(255, 255, 255)",
                            justifyContent: "center",
                            
                            
                            }}
                        >
              <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex",color: "#fcb332", fontWeight: "bold", fontSize: "4rem", }} >
                                <p  style={{ display: "flex",color: "#fcb332", fontWeight: "bold", fontSize: "2.5rem", }}>
                                    Đăng tin                                </p>
                            </div>

     
          
          </Offcanvas.Title>
                   </div>
                    <Stack spacing="20px">

                    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {images.map((value, index) => (
          <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '10px' }}>
            {value ? (
              <div>
                <img
                  style={{ objectFit: "cover", width: "50px", height: "50px",borderRadius: "10px", }}
                  src={value}
                  alt={`Image ${index}`}
                />
                <button
                  onClick={() => removeImage(index)}
                  style={{
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                    outline: "none"
                  }}
                >
                  {index === images.length - 1 ? (
                    "❌"
                  ) : (
                    "❌"
                  )}
                </button>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <input
        id="fileInput"
        onChange={handleImageChange}
        type="file"
        accept="image/*"
        style={{ display: 'none' }} // Hide the file input
        multiple // Allow selecting multiple files
      />
      <button
        onClick={selectImages}
        style={{
          borderRadius: "30px",
          padding: "10px 20px",
          fontSize: "16px",
          marginTop: "10px"
        }}
      >
        Thêm ảnh <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera" viewBox="0 0 16 16">
  <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z"/>
  <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
</svg>
      </button>
   
    </div>
 
    <div style={{ textAlign: 'center', marginTop: '5px',color:"red",fontWeight:"bold" }} >   {imagesError !== ''}  {imagesError} </div>
    <div style={{ textAlign: 'center', marginTop: '5px',color:"black",fontWeight:"bold" }}>     &nbsp;  &nbsp; &nbsp; &nbsp;    &nbsp; &nbsp;&nbsp; &nbsp; {markerName}</div>
        <Stack direction="row" spacing={0}>
                            
                              <TextField
                              style={{background:"#ffff", textAlign: 'center',color:"blue" }}
                                   className="leaflet-tooltip9"
                                   value={area}
                                   onChange={handleChangeArea}
                                   id="outlined-required"
                                   fullWidth
                                   label="Nhập diện tích m²"
                                   variant="outlined"
                                   FormHelperTextProps={{ style: { textAlign: 'center' } }}
                                   InputProps={{
                                       inputMode: 'numeric', type: 'number', 
                                   }}
                              />
                                <TextField
                                    style={{ background: "#ffff" , textAlign: 'center',color:"blue"}}
                                    className="leaflet-tooltip9"
                                    value={price}
                                    onChange={handleChangePrice}
                                    id="outlined-required"
                                    fullWidth
                                    label="Giá Đăng (VND)"
                                    variant="outlined"
                                    FormHelperTextProps={{ style: { textAlign: 'center' } }}
                                    InputProps={{
                                        inputMode: 'numeric',
                                        type: 'number', // Chỉ cho phép nhập số
                                    }}
                                />

                            </Stack>


                        
                            <TextField
                              style={{ borderRadius: "30px", background: "#ffff", textAlign: 'center' }}
                              className="leaflet-tooltip9"
                              value={markerYoutubeURL}
                              onChange={handleMarkerYoutubeChange}
                              id="outlined-required"
                              fullWidth
                              label="vị trí"
                              variant="outlined"
                              FormHelperTextProps={{ style: { textAlign: 'center' } }}
                            />


                        
                        <TextField style={{ borderRadius: "30px",background:"#ffff" }}
                        
                                    fullWidth
                                    id="outlined-select-currency"
                                    select
                                    label="chọn khu vực"
                                    value={markerDistrict}
                                    onChange={handleDistrictChange}
                                    error={districtError !== ''}
                                    helperText={districtError}
                                    FormHelperTextProps={{ style: { textAlign: 'center' } }}
                                >
                                    {DISTRICTS.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <div>
                                <TextField
                                  style={{ borderRadius: '30px', background: '#ffff', minHeight: '100px', overflowY: 'auto' }}
                                  value={markerDes}
                                  onChange={handleMarkerDesChange}
                                  multiline
                                  rows={5}
                                  fullWidth
                                  label="Mô tả sản phẩm"
                                  variant="outlined"
                                  className="leaflet-tooltip9"
                                  error={desError !== ''}
                                  helperText={desError}
                                  FormHelperTextProps={{ style: { textAlign: 'center' } }}
                                  
                                  
                                />   


                       <div dangerouslySetInnerHTML={{ __html: markerDes }} style={{ display: 'none' }} />

                              </div>
                         <br/>
                         <div>
                {/* Kết quả */}
 

            </div>    
                        <Button className="old-price"  onClick={submitMarker}style={{ cursor: "pointer"  ,background : "#fcb332" ,size: "10px" ,position : "relative",borderRadius:"30px", margin: "3px", padding: "5px 10px", fontSize: "small" ,height:"3rem"}} > Tin Đăng</Button>
                    </Stack>
                </div>
            </Box>
        </Modal>
    );
}