import React, { useEffect } from 'react';

const AdComponent = () => {
  useEffect(() => {
    // Push the ad
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div>


 

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="6392472434"
         data-ad-format="auto" />



<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="7941357825"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="4768399579"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="6392472434"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="9919862592"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="7277970544"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="4443923642"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="321312"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="6023498548"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="1142906644"
         data-ad-format="auto" />

<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="2925406966"
         data-ad-format="auto" />

         
<ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5264519737433546"
         data-ad-slot="4294649753         "
         data-ad-format="auto" />
 
        </div>



 

         
  );
};

export default AdComponent;

