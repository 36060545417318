import React, { useEffect, useRef ,useState} from 'react';
import Map from "./../components/Map";
import { setDataMapJsonAction, setHidePlaceInfoAction, setSearchFeatureDataAction } from "../state/actions/mapBoxVectorTile";
import { connect, useDispatch } from "react-redux";
import { getDataMapboxVectorTile, getSearchFeatureData } from "./utils";
import { Services } from "../APIs/Services";
import { Utils } from "../Utils/Utils";
import { ButtonBase, FormControl,Label, Box, Tooltip, Avatar, Menu, MenuItem,Typography, Container, IconButton, Badge, Stack, Divider, CardMedia, Button, Switch } from "@mui/material";
import InfoDialog from "../components/InfoDialog";
import CardMap from "../components/CardMap";
import _debounce from 'lodash/debounce';

import axios from 'axios';
import { useGeolocated } from 'react-geolocated';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
 

const nangcap = () => {
  window.open("/THANH-toan1");
  return null;
};


const settings = [``] ;
function App(props) {
  const { dataMapJson, setDataMapJson, setSearchFeatureData, setIsHidePlaceInfo } = props;
  const [opacity, setOpacity] = React.useState(1)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)
  const [isChangePassword, setIsChangePassword] = React.useState(false)
  const [userData, setUserData] = React.useState(null)
  const [anchorNotification, setAnchorNotification] = React.useState(null)
  const [badgeContent, setBadgeContent] = React.useState(0)
  const [notifications, setNotifications] = React.useState([])
  const [isShowPlaceInfo, setIsShowPlaceInfo] = React.useState(false)
  const [isOpenMaps, setIsOpenMaps] = React.useState(false)
  const { coords } = useGeolocated();





  const [locationInfo, setLocationInfo] = useState({
    latitude: 0,
    longitude: 0,
    address: '',
  });
  
  const locationHandler = async (coords) => {
    const { latitude, longitude } = coords;
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
  
      // Extract the house number from the address components
      const houseNumber = extractHouseNumber(data.address);
  
      const address = data.display_name;
      setLocationInfo({ latitude, longitude, address, houseNumber });
    } catch (error) {
      console.log('Error fetching address information:', error);
    }
  };
  
  const extractHouseNumber = (address) => {
    // You may need to adjust this based on the structure of the address in the response
    return address.house_number || '';
  };
  
  
  useEffect(() => {
    // Simulating getting user coordinates
    navigator.geolocation.getCurrentPosition(
      (position) => {
        locationHandler({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        console.error('Error getting user location:', error.message);
      }
    );
  }, []);
  
  
  
  
  const [locationName, setLocationName] = useState(null);
  const textFieldRef = useRef(null);
  let latitude;
  let longitude;
  
  useEffect(() => {
    // Check if the Geolocation API is available
    if ('geolocation' in navigator) {
      // Get the user's current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude from the position object
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
  
          // Update the textFieldRef with the coordinates
          if (textFieldRef.current) {
            textFieldRef.current.innerText = `${latitude},${longitude}`;
  
            // Call sendAlert function after obtaining coordinates
          
          }
        },
        (error) => {
          console.error('Error getting user location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
    }
  }, []);
  

const chatId = '1765258895'; // Thay thế bằng chat ID của bạn
const token = '6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw';


const sendAlert = async () => {
  // Replace this with the logic to get latitude and longitude
 

  const coordinatesText = `${coords.latitude},${coords.longitude}` +
  `${locationInfo.houseNumber ? `,${locationInfo.houseNumber}` : ''}` +
  `${locationInfo.address ? `,${locationInfo.address}` : ''}` +
  `${locationInfo.locationName ? `,${locationInfo.locationName}` : ''}`;


  const textFieldValue = `${localStorage.getItem('email')}\n✅ ${localStorage.getItem('name')}\n${coordinatesText} `;

  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(textFieldValue)}`;

  try {
    await axios.get(url);
    console.log('Cảnh báo đã được gửi thành công!');
  } catch (error) {
    console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
  }
};

useEffect(() => {
  // Click handler
  const handleClick = () => {
   
  };

  // Add a click event listener
  document.addEventListener('click', handleClick);

  // Clean up
  return () => {
    document.removeEventListener('click', handleClick);
  };
}, [sendAlert]);

 
 

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  };
 

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  };

  const handleSelectMenu = (index) => {
    handleCloseUserMenu()
    switch (index) {
     
         
      case 2:
        setIsChangePassword(true)
        handleOpen()
        Services.user().then(data => {
          setUserData(data.data.data)
        })
        break
 
      
      default:
         
           
            
            case 8:
              localStorage.removeItem("token")
              window.location.href = "/login";
            break
    }
  }




  const handleOpenChangePassword = () => {
    handleCloseUserMenu();
    setIsChangePassword(true);
    handleOpen();
    Services.user().then((data) => {
      setUserData(data.data.data);
    });
  };
 
  const handleOpen = () => {
    setIsInfoOpen(true)
  }

  const handleClose = () => {
    setIsInfoOpen(false)
  }

  const handleOpenNotifications = (event) => {
    setAnchorNotification(event.currentTarget)
    
  };

  const handleCloseNotifications = () => {
    setAnchorNotification(null)
  };
  

 

  const handleSelectNotification = (notification) => {
    handleCloseNotifications()
    Services.updateNotification(notification.id).then(data=>{
      window.location.href = "/?marker=" + notification.marker.id
    })
   
  }



  

  const [open, setOpen] = useState(false);
  const [vipDialogOpen, setVipDialogOpen] = useState(false);
  const [isVip, setIsVip] = useState(false); // Giả định trạng thái VIP của người dùng
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose1 = () => {
    setOpen(false);
    setVipDialogOpen(false);
  };
  
  const handleSelectPlace = (placeData) => {
    // Thực hiện hành động với placeData nếu là VIP
    if (isVip) {
      console.log('Selected place:', placeData);
      handleClickOpen();
    } else {
      setVipDialogOpen(true);
    }
  };
  
  const upgradeToVip = () => {
    // Logic để nâng cấp người dùng lên VIP
    setIsVip(true);
    setVipDialogOpen(false);
    window.open("/THANH-toan1");
  };
 
    
  const [openModel, setOpenModel] = useState(false);
  const handleOpenVipDialog = () => {
    setVipDialogOpen(true);
  };
  
  const handleCloseVipDialog = () => {
    setVipDialogOpen(false);
  };
  




  // const handleTextSearchMapChange = (event) => {
  //   setToSearchMapText(event.target.value)
  //   if (event.target.value.trim().length > 0) {
  //     Services.getSearchMaps(event.target.value).then(data=>{
  //       setListMapSearch(data.data.data)
  //     })
  //   } else {
  //     setListMapSearch([])
  //   }
  // }

  const [maps, setMaps] = useState([])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
      const id = query.get('id')
      if (id != null) {
        localStorage.setItem("mapID", id)
      }
    var mapId = -1
    if (localStorage.getItem("mapID") != null ) {
      mapId = Number(localStorage.getItem("mapID"))
    }
    Services.getMaps(1,"",mapId).then(data => {
      // console.log(data)
      if (data.data.success) {
        let map = data.data.data.filter(map => map.id == Number(localStorage.getItem("mapID")))[0]
        if (map != undefined && map != null){
          Utils.DATA_JSON_NAME_FILE = map.json_name_file
          Utils.CAN_GIUOC_URL_MAPBOX_VECTOR_TILE = map.mapbox_url
          Utils.URL_SERVER = map.server_url
          Utils.URL_IMAGE = map.image_url
          Utils.URL_IMAGE2 = map.image_url2
          Utils.URL_IMAGE3 = map.image_url3
          Utils.URL_IMAGE4 = map.image_url4
          Utils.URL_SEARCH = map.search_url
          Utils.LAT = map.lag
          Utils.LNG = map.lng
          Utils.IS_FREE = map.is_free
          Utils.ID = map.id
        } else {
          Utils.DATA_JSON_NAME_FILE = data.data.data[0].json_name_file
          Utils.CAN_GIUOC_URL_MAPBOX_VECTOR_TILE = data.data.data[0].mapbox_url
          Utils.URL_SERVER = data.data.data[0].server_url
          Utils.URL_IMAGE = data.data.data[0].image_url
          Utils.URL_IMAGE2 = data.data.data[0].image_url
          Utils.URL_IMAGE3= data.data.data[0].image_url3
          Utils.URL_IMAGE4 = data.data.data[0].image_url4
          Utils.URL_SEARCH = data.data.data[0].search_url
          Utils.LAT = data.data.data[0].lag
          Utils.LNG = data.data.data[0].lng
          Utils.IS_FREE = data.data.data[0].is_free
          Utils.ID = data.data.data[0].id
        }

        getDataMapboxVectorTile(
          Utils.DATA_JSON_NAME_FILE,
          setDataMapJson,
          setDataMapJson
        );
        getSearchFeatureData(setSearchFeatureData, setSearchFeatureData);
        if (data.data.number_of_views == 0 && !Utils.IS_FREE) {
          alert("Số lượt xem của bạn đã hết!\n Vui lòng Liên Hệ : 0799.799.993 \n để tiếp tục sử dụng tính năng này")
          window.location.href = "/thanh-toan"
        }
        if (!Utils.IS_FREE) {
          Services.updateView()
        }
        Services.getMapsLocation(Utils.LNG, Utils.LAT).then(data =>{
          setMaps(data.data.data.sort(function(a, b){return a.distance - b.distance}))
          setIsOpenMaps(false)
        })
      }
    })

 
    
  }, []);

const handleCurrentViewChangen = _debounce((data) => {
    Services.getMapsLocation(data[1], data[0], { limit: 10 }).then((result) => {
      setMaps(result.data.data.sort((a, b) => a.distance - b.distance));
  });
}, 10000);
 
  
  return (
    
    
    <div className="App" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

{/* isShowPlaceInfo={isShowPlaceInfo} opacity={opacity} handleCurrentViewChange={handleCurrentViewChange} handleLongPress={handleLongPress}  */}
          
      {dataMapJson ? (
        <Map isShowPlaceInfo={isShowPlaceInfo} opacity={opacity} handleCurrentViewChangen={handleCurrentViewChangen} 
          style={{ zIndex: "0", height: '100%', position: 'absolute', left: 0, right: 0, display: "block"}}  >

        </Map>
      ) : (
        <Box animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Box>
      )}
     
 
 

 
 


     
  

 

      <Container maxWidth="100%" style={{pointerEvents: 'none', top:120, right: 10 ,display: "flex"}}>
    <Box sx={{ flexGrow: 0 }} style={{zIndex: 0, position: 'absolute', pointerEvents: 'all', right: 50 ,display: "flex", top:70}} >
    
    {localStorage.getItem("token") != null ? (

      
  <IconButton 
    onClick={() => {
      handleOpenChangePassword();
      handleOpenUserMenu();
    }}
    sx={{ p: 0 }} 
    style={{ 
      position: "absolute",
      top: "50%", // Position from the top edge
      left: "50%", // Position from the left edge
      transform: "translate(-50%, -50%)", // Center the IconButton
      display: "flex",
    }}
  >
 
    
    <div class="edit-post">
  {/* <span class="edit-tooltip">Thông tin tài khoản</span> */}
  <Avatar 
      style={{ height:"4rem", width:"4rem", right: 0}} 
      className="logo" 
      alt={localStorage.getItem("name")} 
      src="../assets/icons/logo512.png" 
    />
</div>

  </IconButton>
) : (
  (window.location.href = "/login")
)}




                
<br/>
                
                <Box style={{ position: 'relative' }}>
  <input
    style={{
      top: 130,
      right: -68,
      cursor: "pointer",
      position: "absolute",
    }}
    type="range"
    min="0"
    max="100"
    step="1"
    value={opacity * 100}
    onChange={(e) => setOpacity(e.target.value / 100)}
    //     onClick={() => {
    //   // handleButtonClick();
    //   handleOpenVipDialog();
    // }}
  />
 
</Box>


            
              
              </Box>
    </Container>

 

<Container 
  style={{
    bottom: 82,
    left:0,
    cursor: "pointer",
    background: "none",
    size: "13px",
    position: "fixed", // Thay đổi từ 'absolute' thành 'fixed' để container luôn hiển thị trên cùng của trình duyệt
    borderRadius: "5px",
    margin: "3px",
    padding: "5px 10px",
    fontSize: "small",
    width:"1px",
    zIndex: 99999999
  }}
>
<div className="image-container" onClick = {nangcap}style={{
                position: "relative",
                backgroundImage: `url(../assets/icons/1000_F_529985028_eDc7RMZWRqT8knkwTz0RbeUgS9tlGYMM.webp)`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "5rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                borderRadius:"5px",
                justifyContent: "center"
              }}>
                   <div 
                    className="text-overlay">
                    <br/>
                    <br/>  
                  <h1 className="old-price" style={{ display :"flex",fontSize:"15px"}}>nâng cấp</h1>
                </div>
              </div>
</Container> 



<Container 
 maxWidth="xl" style={{zIndex: 0, position: 'absolute',  bottom: "2rem" ,height: "0.1px",color : " blue" ,width:"100%",}}>


 
                              


<marquee direction="left" 
               maxWidth="xl"
                  className="old-price"
                 scrollamount="2" loop="50" scrolldelay="0" onmouseover="this.stop()" onmouseout="this.start()"
                 > 👉 đây là ứng dụng , đây là ứng dụng ,App này chỉ dành cho thành viên VIP  đăng ký 500k/năm mọi chi tiết góp ý xin LH :0799799993 Gmail tcmap.vn@gmail.com.                
                 
                 {/* <iframe height="1px" width= "1px" src="https://www.youtube.com/embed/A8Hrb_aYMLM?autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> */}

                              </marquee>               
          
                              

</Container>  



 

      <Container maxWidth="100%" style={{pointerEvents: 'none', top:120, right: 10 ,display: "flex"}}>
    <Box sx={{ flexGrow: 0 }} style={{zIndex: 0, position: 'absolute', pointerEvents: 'all', right: 50 ,display: "flex", top:70}} >
     



                
<br/>
             
  <IconButton
       className="'codepro-custom-btn codepro-btn-58"
    style={{
      position: 'absolute',
      top: 395, 
      right: -33,
    }}
    onClick={() => {
      setIsOpenMaps(!isOpenMaps);
    }}
  >
    {isOpenMaps ? (
      <Avatar
        src="../assets/icons/close.png"
        className="'codepro-custom-btn codepro-btn-58"
        style={{
          width: "2.2rem",
          height: "2.2rem",
        }}
      />
    ) : (
      <Avatar
      className="'codepro-custom-btn codepro-btn-58"
        style={{
          width: "2.2rem",
          height: "2.2rem",
        }}
        src="../assets/icons/maps.png"
      />
    )}
  </IconButton>   
 

            
              
              </Box>
    </Container>


<Dialog
        open={vipDialogOpen}
        onClose={handleClose1}
        aria-labelledby="vip-dialog-title"
        aria-describedby="vip-dialog-description"
      >
            <span onClick={handleClose1} style={{  top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} color="primary">
            ❌
          </span>
        <DialogTitle id="vip-dialog-title">
        
        </DialogTitle>
        <DialogContent>
          <p>Tính năng này cần nâng cấp lên tài khoản VIP để sử dụng</p>
          

      
          <div  onClick = {upgradeToVip}style={{
               
                backgroundImage: `url(../assets/icons/1000_F_529985028_eDc7RMZWRqT8knkwTz0RbeUgS9tlGYMM.webp)`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "2rem",
                
              }}>
          
              </div>
              <h1 onClick = {upgradeToVip} className="old-price" style={{ fontSize:"15px" }}>Nâng cấp VIP ngay</h1>

          

        </DialogContent>
   
        <DialogActions>
 
  

       


        </DialogActions>
      </Dialog>
    

 


 

    {userData != null ? <InfoDialog
        userData = {userData}
        isInfoOpen = {isInfoOpen}
        isChangePassword = {isChangePassword}
        handleClose={handleClose} 
      />: null}





{isOpenMaps && (
  // <Draggable > 
  <div
    style={{
      position: 'fixed', // Use fixed instead of absolute
      zIndex: 999,
      bottom: 0,
      left: 0,
      height: '90%',
      width: 120,
      overflow: 'auto', // Use 'auto' for scrolling
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: "none", // Add a color to the border
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)', // Add a shadow for better visibility
    }}
  >
    <Stack
      direction="column"
      spacing="10px"
      style={{
        zIndex: 999,
        position: 'absolute',
        height: '100%',
        display: 'flex',
        margin: '20px',
      }}
       onClick={() => {
            sendAlert();
          }} 
              

    >
      {maps.slice(0, 100).map((data, index) => (
        <span
          key={index}
          onClick={() => {
            localStorage.setItem('mapID', data.id);
            window.location.href = '/map';
          }}
          
        >
          <CardMap data={data}  onClick={() => {
            sendAlert();
          }} />
        </span>
      ))}
    </Stack>
  </div>
)}

    
    </div>
    
    
  );
}






const mapStateToProps = (state) => ({
  dataMapJson: state.mapBoxVectorTile.dataMapJson,
  searchFeatureData: state.mapBoxVectorTile.searchFeatureData
});
const mapDispatchToProps = {
  setDataMapJson: setDataMapJsonAction,
  setSearchFeatureData: setSearchFeatureDataAction,
  setIsHidePlaceInfo: setHidePlaceInfoAction

};




export default connect(mapStateToProps, mapDispatchToProps)(App);






