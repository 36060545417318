import { Box, Button, CardMedia, TextField, Modal, Stack, IconButton, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from "react";
import data from "./data";
import Form from "react-bootstrap/Form";
import { InputGroup, Navbar, Nav, NavDropdown, FormControl, Button as ReactButton } from 'react-bootstrap';
import unidecode from 'unidecode';
import AdComponent from '../components/AdSenseAds';

function removeDiacritics(str) {
  return unidecode(str);
}

const style = {
  position: 'absolute',
  top: '5%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: { xs: "100%", md: '40%' },
  maxWidth: { xs: "100%", md: '40%' },
  border: 'none',
  border: "none",
  background: "#fff",
  p: 4,
};



export default function SearchMapView1({ mapSearch, handleClose, open, handleMapSearchChange, listMapSearch }) {
  const [inputVal, setInputVal] = useState("");
  const [people, setPeople] = useState(data);
  const [activeTab, setActiveTab] = useState("list"); // State variable to track active tab

  const [typedText, setTypedText] = useState('');
  const placeholderText = "Gõ tên loại đất, hoặc ký hiệu đất";

  useEffect(() => {
    const interval = setInterval(() => {
      let currentText = typedText;
      if (currentText.length < placeholderText.length) {
        currentText = placeholderText.slice(0, currentText.length + 1);
        setTypedText(currentText);
      } else {
        clearInterval(interval);

        // After a certain time, reset the placeholder to empty and start over.
        setTimeout(() => {
          setTypedText('');
        }, 1000); // Change the wait time before starting again
      }
    }, 50); // Change display speed here if necessary

    return () => {
      clearInterval(interval);
    };
  }, [typedText]);

  return (
    <Modal
      style={{ zIndex: 9999999999 }}
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}

    >
      <Box sx={style} style={{ height: "3rem", borderRadius: "30px", background:"none"}}>
     

        <IconButton onClick={handleClose} style={{ top: 10, right: "5", cursor: "pointer", color: "red", background: "#ffff", size: "13px", position: "absolute", borderRadius: "5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} >❌</IconButton> <br />

 

        
      <div
        style={{
          display: "flex",
          zIndex: 9999999999,
          top: 30,
          cursor: "pointer",
          color: "red",
          size: "13px",
          position: "absolute",
          borderRadius: "5px",
          margin: "3px",
          fontSize: "small",
          left: '50%',
          transform: 'translate(-50%, -50%)',
     
        }}
      >
        <span
          style={{
            cursor: "pointer",
            color: activeTab === "list" ? "white" : "red",
            background: activeTab === "list" ? "DodgerBlue" : "#fff",
            size: "13px",
            borderRadius: "5px 0 0 5px", // Bo tròn cạnh trái
            margin: "0",
            padding: "5px 10px",
            fontSize: "small",
             width:150
          }}
          onClick={() => setActiveTab("list")}
          variant={activeTab === "list" ? "contained" : "outlined"}
        >
         ký quy hoạch
        </span>
        <span
          style={{
            cursor: "pointer",
            color: activeTab === "map" ? "white" : "red",
            background: activeTab === "map" ? "DodgerBlue" : "#fff",
            borderColor: "white",
            size: "13px",
            margin: "0",
            padding: "5px 10px",
            fontSize: "small",
            borderLeft: "none" ,
                 width:150
          }}
          onClick={() => setActiveTab("map")}
          variant={activeTab === "map" ? "contained" : "outlined"}
        >
         quy hoạch
        </span>
        


     
      </div>

      {/* <AdComponent /> */}




    
        {activeTab === "list" && (
          <Stack direction="column" spacing="20px" style={{ overflowX: "auto", overflowY: "scroll", maxHeight: "90vh" }}
          >
            
        <Form.Control
          type="search"
          value={inputVal}
          onChange={(e) => {
            setInputVal(e.target.value);
          }}
          placeholder={typedText}
          style={{ top: -15, height: "3rem", borderRadius: "30px", }}
        />
            {people
              .filter((person) => {
                const formattedInput = removeDiacritics(inputVal.toLowerCase());
                const formattedCode = removeDiacritics(person.code.toLowerCase());
                const formattedName = removeDiacritics(person.name.toLowerCase());

                if (formattedInput === "") {
                  return person;
                } else if (
                  formattedCode.includes(formattedInput) ||
                  formattedName.includes(formattedInput)
                ) {
                  return person;
                }
              })
              .map((person, i) => {
                return (
                  <InputGroup
                    id={i}
                    sx={style}
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        background: person.color,
                        borderRadius: "5px",
                        width: "100%",
                        fontSize: "15px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                        
                        }}
                      >
                        {person.name} - &nbsp;

                        <span
                        style={{
                          border: "3px solid DodgerBlue",  borderRadius:"5px" 
                        }}
                      >
                     &nbsp;{person.code}&nbsp;
                      </span>
                      </span>
                    </span>
                
                  </InputGroup>
                );
              })}
          </Stack>
        )}

        {activeTab === "map" && (
          <div style={{ display: "flex", flexDirection: "column", overflowX: "auto", overflowY: "scroll", maxHeight: "90vh" }}>
            <img src="https://guland.vn/bds/img/tutorial/qhpk/5-6-7.webp" alt="QH phân khu 1" />
            <img src="https://guland.vn/bds/img/tutorial/qhpk/8-9-10-11.webp" alt="QH phân khu 2" />
            <img src="https://guland.vn/bds/img/tutorial/qhpk/12-13-14.webp" alt="QH phân khu 3" />
            <img src="https://guland.vn/bds/img/tutorial/qhpk//19-20-21-22.webp" alt="QH phân khu 4" />
          </div>

    
        )}



      </Box>
    </Modal>
  );
}
