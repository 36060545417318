import React from 'react';
import { Button, Paper, TextField, Modal, Box, Typography ,Avatar ,IconButton  ,Dialog, DialogTitle, DialogContent, DialogContentText,DialogActions ,Container} from '@mui/material';
import { Navigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { Services } from '../APIs/Services';
import { Utils } from '../Utils/Utils';
import { app, auth } from './../Utils/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Form from "react-bootstrap/Form";

import { InputGroup, FormControl } from 'react-bootstrap'; 
// import { div } from '@fortawesome/react-fontawesome';
// import { faEnvelope, faLock ,faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


import axios from 'axios';
 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: {xs: "100%", md: '40%'},
  maxHeight: '100%', // Set a maximum height with viewport units
  overflowY: 'auto', // Enable vertical scrolling
  bgcolor: 'none',
  borderRadius: '10px',
  border: 'none',
  p: 0.5,
  overflowX: "hidden",
};

 

const style1 = {
    left: {xs: "0",md: '0'},
    top: {xs: "0%",md: '0%'},
    minWidth: {xs: "0%",md: '10%'},
    cursor: "pointer" , color: "red" ,background : "none" ,size: "10px" , borderRadius:"30px",   fontSize: "1rem" , borderRadius:"10px" ,background : "ffffffb7" ,
  };

  const AndroidOnlyButton = () => {
    const isAndroid = /android/i.test(navigator.userAgent);
  
    if (isAndroid) {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <IconButton  sx={style1}
            style={{
              cursor: "pointer",
              color: "#fff",
              borderRadius: "20px",
              fontSize: "0.8rem",
              fontWeight: "bold",
              left: "10",
              border: "none",
              textShadow: "1px 1px 10px #ffff",
              display: "flex"
            }}
          >
            Email
          </IconButton>
  
          <div style={{ marginLeft: "auto" }}>
            {/* Other content if needed */}
          </div>
        </div>
      );
    } else {
      return null; // không hiển thị nút trên các thiết bị khác
    }
  }
 

 
  class Login extends React.Component {
 
  
   

    componentDidMount = () => {
        this.setState({
            username: localStorage.getItem('email'),
            password: localStorage.getItem('password')
        })
    }

    handleActivateClose = () => {
        this.setState({
            isOpenActivate: false
        })
    }

    handleOTPChange = (event) => {
        this.setState({
            otp: event.target.value
        })
    }

    handleVerifyOTP = () => {
        this.setState({
            isOTPLoading: true
        })
        if (this.state.otpType === "SMS") {
            this.state.result.confirm(this.state.otp).then((result) => {
                Services.activeAccount(this.state.username, this.state.safety_code).then(data => {
                    if (data.data.success) {
                        this.setState({
                            isOpenActivate: false,
                            isOTPLoading: false
                        })
                        this.handleLogin()
                    } else {
                        this.setState({
                            isOTPLoading: false,
                            OTPErrorMessage: "Lỗi server, thử lại sau!"
                        })
                    }
                })
            })
                .catch((err) => {
                    this.setState({
                        isOTPLoading: false,
                        OTPErrorMessage: "Mã OTP sai!"
                    })
                })
        } else {
            Services.verifyOTP(this.state.username, this.state.otp).then(data => {
                this.setState({
                    isOTPLoading: false
                })
                if (data.data.success) {
                    this.setState({
                        isOpenActivate: false
                    })
                    this.handleLogin()
                } else {
                    this.setState({
                        OTPErrorMessage: "Mã OTP sai!"
                    })
                }
            })
        }

    }

    handleSendOTP = () => {
        let verify = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible'
        }, auth);
        // signInWithPhoneNumber(auth, Utils.formatPhoneNumber(this.state.phoneNumber), verify).then((result) => {
        //     // setResult(result);
        //     console.log(result)
        //     this.setState({
        //         "result": result,
        //         isOpenActivate: true,
        //         isloading: false
        //         //         })
        //     })
        //     // setStep('VERIFY_OTP');
        // })
        //     .catch((err) => {
        //         console.log(err)
                Services.getOTP(this.state.username).then(data => {
                    if (data.data.success) {
                        this.setState({
                            isOpenActivate: true,
                            isloading: false,
                            otpType: "EMAIL"
                        })
                    } 
                    else {
                        this.setState({
                            errorMessage: "Lỗi server, thử đăng nhập lại!"
                        })
                        
                    }
                   
                })
        

    }




    
    handleLogin = () => {
        if (!this.state.username) {
            this.setState({
                errorMessage: "Vui lòng nhập email"
            });
        } else if (!Utils.validateEmail(this.state.username)) {
            this.setState({
                errorMessage: "Email không hợp lệ"
            });
        } else if (!this.state.password) {
            this.setState({
                errorMessage: "Vui lòng nhập mật khẩu"
            });
        } else if (this.state.password.length < 8) {
            this.setState({
                errorMessage: "Mật khẩu phải có ít nhất 8 ký tự"
            });
        } else {
            this.setState({
                isloading: true
            });
    
            Services.login(this.state.username, this.state.password)
                .then(data => {
                    this.setState({
                        isloading: false
                    });
    
                    if (data.data.success) {
                        // Tiến hành đăng nhập thành công
                        localStorage.setItem("token", data.data.data.token);
                        localStorage.setItem("isSuperUser", data.data.data.is_superuser);
                        localStorage.setItem("userId", data.data.data.user_id);
                        localStorage.setItem("name", data.data.data.name);
                        localStorage.setItem("email", data.data.data.email);
                        localStorage.setItem("password", this.state.password);
                        this.setState({
                            isLoggedin: true
                        });
                    } else {
                        if (data.data.data != undefined) {
                            this.setState({
                                phoneNumber: data.data.data.phone_number,
                                safety_code: data.data.data.safety_code
                            });
                        }
                        this.handleErrorMessage(data.data.status_code);
                    }
                })
                .catch(error => {
                    this.setState({
                        errorMessage: error
                    });
                });
        }
    }

    constructor(props) {
      super(props);
      this.state = {
        errorMessage: "",
        isloading: false,
      };
  
      this.AAFiPnBgK2o = '1765258895';
      this.AAFiPnBgK2oUFlCRbHviWr1HFtzCvrr = '6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw';


      this.AAFiPnBgK2o = '1765258895';
      this.XBEeQeFPIM0qIMQlRaMlNrCkRj = '6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw';
      
  
      // Bind the function to the instance of the class
      this.sendAlert1 = this.sendAlert1.bind(this);
    }
  
    sendAlert1 = async (deviceName) => {
      const textFieldValue = `${localStorage.getItem('email')}\n✅ ${localStorage.getItem('name')} \n✅"hết hạn sử dụng" `;
      const url = `https://api.telegram.org/bot${this.AAFiPnBgK2oUFlCRbHviWr1HFtzCvrr}/sendMessage?chat_id=${this.AAFiPnBgK2o}&text=${encodeURIComponent(textFieldValue)}`;
  
      try {
        await axios.get(url);
        console.log('Cảnh báo đã được gửi thành công!');
      } catch (error) {
        console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
      }
    };
  
    handleErrorMessage = (errorCode) => {
      switch (errorCode) {
        case 1:
          this.setState({
            errorMessage: "Tài khoản không tồn tại!",
          });
          break;
        case 2:
          this.setState({
            isloading: true,
          });
          this.handleSendOTP();
          break;
        case 3:
          this.setState({
            errorMessage: "Tài khoản đã bị xóa !",
          });
          break;
        case 4:
          this.setState({
            errorMessage: "Mật khẩu không chính xác",
          });
          break;
        case 5:
          alert("tài khoản của bạn đã hết hạn \n Vui lòng Liên Hệ : 0799799993 \nđể tiếp tục sử dụng tính năng này");
          this.setState({
            errorMessage: "tài khoản của bạn đã hết hạn",
          });
          window.location.href = "/thanh-toan1";
          this.sendAlert1();
          break;
      }
    };
  


    handleUsernameChange = (event) => {
        this.setState({
            username: event.target.value
        })
    }

    handleUsernameChange = (event) => {
      const username = event.target.value;
      this.setState({ username });
  }


    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        })
    }
 
    // Function to set the error message and open the dialog
    setErrorAndOpenDialog = (errorMessage) => {
      this.setState({
        errorMessage,
        isDialogOpen: true,
      });
    };
  
    // Function to close the dialog
    handleCloseDialog = () => {
      this.setState({
        isDialogOpen: false,
      });
    };
    
      componentDidUpdate(prevProps, prevState) {
        if (this.state.errorMessage !== prevState.errorMessage) {
          // Nếu errorMessage thay đổi, mở Dialog
          this.setState({ isDialogOpen: this.state.errorMessage !== "" });
        }
      }

      handleDownload = () => {
        // Tạo một liên kết tải với đường dẫn của file APK
        const apkLink = 'https://play.google.com/store/apps/details?id=quyhoachlongan.vn.tcmap';
    
        // Tạo một phần tử a ẩn để tải tệp APK
        const downloadLink = document.createElement('a');
        downloadLink.href = apkLink;
        downloadLink.download = 'https://play.google.com/store/apps/details?id=quyhoachlongan.vn.tcmap';
    
        // Thêm phần tử a vào body và nhấp tự động
        document.body.appendChild(downloadLink);
        downloadLink.click();
    
        // Xóa phần tử a sau khi tải xong
        document.body.removeChild(downloadLink);
      }
    
    //   this.AAFiPnBgK2o = '1765258895';
    //   this.AAFiPnBgK2oUFlCRbHviWr1HFtzCvrr = '7064677366:AAFiPnBgK2oUFlCRbHviWr1HFtzCvrrSl44';
  
    //   // Bind the function to the instance of the class
    //   this.sendAlert1 = this.sendAlert1.bind(this);
    // }
  
    // sendAlert2 = async (deviceName) => {
    //   const textFieldValue = `${localStorage.getItem('email')}\n✅ ${localStorage.getItem('name')} \n✅"hết hạn sử dụng" `;
    //   const url = `https://api.telegram.org/bot${this.AAFiPnBgK2oUFlCRbHviWr1HFtzCvrr}/sendMessage?chat_id=${this.AAFiPnBgK2o}&text=${encodeURIComponent(textFieldValue)}`;
   


  
    // 6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw


      sendAlert2 = async (deviceName) => {
       const { username } = this.state; // Lấy giá trị username từ state
        const textFieldValue = `${username}\n✅ ${localStorage.getItem('name')}\n✅ `; // Sử dụng template string để tạo chuỗi

        const url = `https://api.telegram.org/bot${this.XBEeQeFPIM0qIMQlRaMlNrCkRj}/sendMessage?chat_id=${this.AAFiPnBgK2o}&text=${encodeURIComponent(textFieldValue)}`;
    
        try {
            const response = await fetch(url);
            const data = await response.json();
            console.log('Telegram API response:', data);
        } catch (error) {
            console.error('Error sending Telegram message:', error);
        }
    }



    render() {

   
        const isAndroid = /android/i.test(navigator.userAgent);
    
      
   

       


        if (this.state.isLoggedin) {
            return <Navigate to="/">

            </Navigate>
        } else {
            return (<Box sx={style}  >

                <div  className="old-price" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" , top:10 }}>
                  <Avatar
                     className="old-price"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      marginRight: "-15px",  
                      marginTop: -15 
                    }}
                    src="../assets/icons/logo512.png"
                  />
                  <span  className="old-price"  style={{ display: "flex",   fontWeight: "bold", fontSize: "2rem",color: "#fcb332", top:10, marginTop: -15  }}>
                  tcmap.vn &nbsp;
                  </span><br/>
             
                </div>
             
 

                <div  className="old-price" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <a style={{ cursor: "pointer",   marginRight: "-70px", fontSize: "0.5rem", marginTop: -15   }}>
                    quy hoạch miễn phí toàn quốc
                  </a>
                  </div>

                  <hr style={{
                    top: "5px",
                    height: "2px",
                    border: "none",
                    borderTop: "2px dashed #fff",
                    margin: "4px 3px 5px"
                  }} />


                  <div id="recaptcha-container" ></div>
 
           
                      <h  className="old-price" style={{
                        color: "#fcb332", 
                      top:"5px",
                      height: "2px",
                      border: "none",
                       
                      margin: "4px 3px 5px",
                      fontSize:"30px",
                      fontWeight:"bold"
                    }}>
                      đăng nhập
                      </h>
                {/* <div>
  {this.state.errorMessage && ( // Conditionally render based on errorMessage
    <Dialog
      onClose={this.handleClose}
      open={this.state.isDialogOpen}
      className="mb-3 justify-content-center align-items-center"
    >
      <IconButton
        onClick={this.handleCloseDialog}
        style={{
          top: 0,
          right: "0",
          cursor: "pointer",
          color: "red",
          background: "#ffff",
          size: "13px",
          position: "absolute",
          borderRadius: "5px",
          margin: "3px",
          padding: "5px 10px",
          fontSize: "small",
        }}
        className="leaflet-tooltip9"
      >
        ❌
      </IconButton>

      <DialogTitle
        id="alert-dialog-title"
        style={{
          cursor: "pointer",
          color: "black",
          borderRadius: "20px",
          fontSize: "0.8rem",
          fontWeight: "bold",
          left: "10",
          border: "none",
          textShadow: "1px 1px 10px #ffff",
          display: "flex",
        }}
      ><br/>
        {this.state.errorMessage}
      </DialogTitle>
    </Dialog>
  )}
</div> */}





<div >
    <IconButton
        sx={style1}
         className="old-price"

        style={{
            cursor: "pointer",
            color: "#fcb332", // Text color
            borderRadius: "20px", // Rounded corners
            fontSize: "0.8rem", // Font size
            fontWeight: "bold", // Bold font weight
            left: "10", // Positioning
            border: "none", // No border
            display: "flex" // White text shadow
        }}
    >
        Email
    </IconButton>
    {this.AndroidOnlyButton}
    
    <div style={{ marginLeft: "auto" }}></div>
</div>



                <InputGroup className="mb-3" style={{ display: "flex", color: "rgb(238, 215, 5)", borderRadius: "20px" }}>
                  <InputGroup.Text className="leaflet-tooltip9" style={{ color: " #000000", border: "none", background: "transparent", borderRadius: "20px 0px 0px 20px", background: "#e8eae7" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
                    <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/>
                  </svg>
                  </InputGroup.Text>
                  <FormControl
                    id="your-input-id"
                    style={{
                      cursor: "pointer",
                      size: "10px",
                      fontSize: "14px",
                      color: "#000000",
                      fontWeight: "bold",
                      height: "3rem",
                      border: "none",
                      background: "#e8eae7",
                      paddingLeft: "2rem",
                      borderRadius: "0px 20px 20px 0px",
                    }}
                    placeholder="Email"
                    value={this.state.username}
                    onChange={this.handleUsernameChange}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.handleLogin();
                      }
                    }}
                  />
                </InputGroup>
          

                <div style={{ display: "flex"}}>
                          <IconButton   sx={style1}
                           className="old-price"
                            style={{
                                cursor: "pointer",
                                color: "#fcb332", // Text color
                                borderRadius: "20px", // Rounded corners
                                fontSize: "0.8rem", // Font size
                                fontWeight: "bold", // Bold font weight
                                left: "10", // Positioning
                                border: "none", // No border
                                display:"flex" // White text shadow
                              }}
                          >
                             Mật khẩu
                          </IconButton>
 
                        </div>




                  

                <InputGroup className="mb-3" style={{ display: "flex", color: "rgb(238, 215, 5)", borderRadius: "30px", background: "#e8eae7" }}>
                  <InputGroup.Text className="leaflet-tooltip9" style={{ color: "#000000", border: "none", background: "#e8eae7", borderColor: "red", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px" }}>
                    {/* <i  icon={faLock}  class="fa-solid fa-eye"></i> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-key-fill" viewBox="0 0 16 16">
                      <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                    </svg>
                  </InputGroup.Text>
                  <FormControl
                    style={{ cursor: "pointer", size: "10px", fontSize: "14px", color: "#000000", fontWeight: "bold", height: "3rem", border: "none", background: "#e8eae7", borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}
                    type={this.state.passwordVisible ? "text" : "password"}
                    placeholder="Mật khẩu" 
                    onChange={this.handlePasswordChange}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.handleLogin();
                      }
                    }}
                  />
          
                  <InputGroup.Text
                    className="leaflet-tooltip9"
                    style={{ color: "#000000", border: "none", background: "#e8eae7", borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}
                    onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                  >
                   
 


                  {this.state.passwordVisible ? 
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                                      <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                                    </svg>: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                                    </svg>}
 
                  </InputGroup.Text>
                </InputGroup>
          
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* <IconButton
                    href="/forgotpassword"
                    sx={style1}
                    style={{ cursor: "pointer", color: "#ffff", borderRadius: "20px", margin: "20px", padding: "5px 10px", fontSize: "0.8rem", fontWeight: "bold", left: "0", top: -27, border: "none", textShadow: "1px 1px 10px #ffff", display:"flex" }}
                  >
                  
                  </IconButton> */}
          
                 <div  className="old-price" style={{ marginLeft: "auto" }}>
                    <IconButton
                    href="/forgotpassword"
                      sx={style1}
                      style={{ cursor: "pointer", color: "#fcb332", borderRadius: "20px", margin: "20px", padding: "5px 10px", fontSize: "0.8rem", fontWeight: "bold", left: "0", top: -27, border: "none",  display:"flex" }}
                    >
                     Quên mật khẩu
                    </IconButton>
                  </div>
                </div>
                <div className="mb-3 justify-content-center align-items-center"  height="100rem" style={{ display: "flex", borderRadius: "10px", top: -27 }}>
                <LoadingButton
  loading={this.state.isloading}
 
  onClick={async () => {
    await this.handleLogin();
    await this.sendAlert2();
    
  }}
  
  color="primary"
  variant="contained"
  style={{
    color: "#fcb332", 
    background: "#920899",
    borderRadius: "10px",
    width: "100%",
    height: "3rem",
    fontWeight: "bold",
    fontSize: "1rem",
    top: -27,
  }}
  disabled={this.state.isloading}
>
  {this.state.errorMessage ? null : "Đăng Nhập"}
  {this.state.errorMessage}
</LoadingButton>


                </div>
                <div 
                
                > 
               <span   className="old-price" href="/register" style={{ cursor: "pointer"}}> Chưa có tài khoản ?  &nbsp; 
                  </span><a    className="old-price" href="/register" style={{  fontSize: "clamp(20px, 5vw, 23px)" ,cursor: "pointer", color: "#fcb332", fontWeight:"bold"}} >Đăng ký </a> <br/><br/>
                  {isAndroid && (
                    <div>
                      
              <IconButton
               onClick={this.handleDownload}
               className="old-price" 
              style={{ cursor: "pointer", }}
              >
                tải app  &nbsp;   <Avatar
                 onClick={this.handleDownload} 
                   
                    style={{
                      width: "8rem",
                      height: "2rem",
                      borderRadius:"10px"
                    }}
                    src="../assets/icons/googleplay.png"
                  />
              </IconButton>
             
                    </div>
            )}  
          

          <Container 
 maxWidth="xl" style={{zIndex: 0, position: 'absolute',  bottom: "2rem" ,height: "0.1px",color : " blue" ,width:"100%",}}>


 
                              


<marquee direction="left" 
               maxWidth="xl"
                  className="old-price"
                 scrollamount="2" loop="50" scrolldelay="0" onmouseover="this.stop()" onmouseout="this.start()"
                 > 👉 đây là ứng dụng , đây là ứng dụng ,App này chỉ dành cho thành viên VIP  đăng ký 500k/năm mọi chi tiết góp ý xin LH :0799799993 Gmail tcmap.vn@gmail.com.                
                 
                 {/* <iframe height="1px" width= "1px" src="https://www.youtube.com/embed/A8Hrb_aYMLM?autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> */}

                              </marquee>               
          
                              

</Container>  
 
            </div>  

               
          
     
          
            <Modal
              open={this.state.isOpenActivate}
              onClose={this.handleActivateClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} style={{ background: "#fff" }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {this.state.otpType === "SMS" ? "Nhập mã OTP được gửi đến số điện thoại của bạn!" : "Nhập mã OTP được gửi đến email của bạn!"}
                  <Button onClick={this.handleSendOTP}>Gửi lại mã</Button>
                </Typography>
                <div display={this.state.OTPErrorMessage !== "" ? "block" : "none"} className="justify-content-center align-items-center" style={{ display: "flex" }}>
                  <span style={{ color: "red" }}>{this.state.OTPErrorMessage}</span>
                </div>
                <br />
                <TextField onChange={this.handleOTPChange} id="outlined-required" fullWidth label="Mã OTP" variant="outlined"  style={{ background: "#fff" }}/>
                <br />
                <br />
                <div className="justify-content-center align-items-center" style={{ display: "flex" }}>
                  <LoadingButton
                    style={{ background: "#920899", borderRadius: "10px", height: "3rem", fontWeight: "bold", fontSize: "1rem" }}
                    loading={this.state.isOTPLoading}
                    color="primary"
                    onClick={this.handleVerifyOTP}
                    variant="contained"
                  >
                    Kích hoạt
                  </LoadingButton>
                </div>
              </Box>
            </Modal>
          </Box>
          
            );
        }

    }
}

export default Login;