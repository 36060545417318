import {Container, Box, CardMedia, Button, TextField, Modal, Stack, IconButton, Link, Divider, Snackbar, Alert ,Avatar ,AppBar,Toolbar} from '@mui/material';

import { InputGroup ,Navbar, Nav, NavDropdown, FormControl, Button as ReactButton } from 'react-bootstrap';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Carousel } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Services } from '../APIs/Services';
// import 'moment/locale/vi'; // Import the Vietnamese locale
// moment.locale('vi');
// import moment from 'moment';
 
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
const style = {
    position: 'absolute',
    top: {xs: "50%",md: '50%'},
    left: {xs: "50%",md: '50%'},
    transform: 'translate(-50%, -50%)',
    minWidth: {xs: "100%",md: '50%'},
    maxHeight:  {xs: "100%",md: '100%'},
    Height: {xs: "100%",md: '100%'},
    overflow: 'scroll',
    display: "block",
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius:"10px",
   
  };
 
   
  const modalStyle4 = {
    background: 'none',
    border: "1px solid Yellow",
    width: "100%", // Default width for mobile
  };
 
 
 
function shareMarkerAndroid(url) {
  if (window.NativeAndroid) {
      window.NativeAndroid.share(url);
  }
}



export default function DialogMarker({state,handleClose}) {

    const [showFullComment, setShowFullComment] = useState(false);
    
    const toggleReadMore = () => {
        setShowFullComment(!showFullComment);
    };
 
 
    const [button1Playing, setButton1Playing] = useState(false);
    const [button2Playing, setButton2Playing] = useState(false);
    
    const handleButtonClick = (button) => {
        if (button === "button1") {
          setButton1Playing(true);
          setButton2Playing(false);
        } else if (button === "button2") {
          setButton1Playing(false);
          setButton2Playing(true);
        }
      };


    const [comments, setComments] = useState([])
    const [comment, setComment] = useState("")
    const [openAlert, setOpenAlert] = useState(false)

    const handleCloseAlert = () => {
        setOpenAlert(false)
    }
    const handleOpenAlert = () => {
        setOpenAlert(true)
    }

    useEffect(()=>{
        Services.getComments(state.currentMarker.id).then((data)=>{
            setComments(data.data.data)
        })
    },[state.currentMarker.id])

    const handleCommentChange = (event) => {
        setComment(event.target.value)
    }

    const handleAddComment = () => {
        if (comment.trim().length > 0) {
            Services.addComment(state.currentMarker.id, comment).then((data)=> {
                setComment("")
                Services.getComments(state.currentMarker.id).then((data)=>{
                    setComments(data.data.data)
                })
            })
        }
    }
 

const handleCopyToClipboard = (id) => {
  const url = new URL(window.location.href).origin + "?marker=" + state.currentMarker.id;
  navigator.clipboard.writeText(url)
    .then(() => {
      console.log('Đã sao chép vào clipboard');
      // Hiển thị thông báo cho người dùng
      alert('Đã sao chép ');
      
      // Thực hiện các hành động tiếp theo tại đây, ví dụ: mở tab mới
      window.open(url, "_blank"); // Mở URL trong tab mới
      handleOpenAlert();
    })
    .catch((error) => {
      console.error('Lỗi khi sao chép vào clipboard:', error);
    });
};



    if (/\d{10}/.test(state.currentMarker.description)) {
        const phoneNumber = state.currentMarker.description.match(/\d{10}/)[0];
      
        const contactButton = document.getElementById('contact-button');
        if (contactButton) {
          contactButton.href = `tel:${phoneNumber}`;
          contactButton.classList.add('visible');
        }
      } else {
        const contactButton = document.querySelector('#contact-button');
        if (contactButton) {
          contactButton.style.display = 'none';
        }
      }

      const handleButtonClick1 = () => {
        const phoneNumberRegex = /\d{10}/;
        const currentMarkerDescription = state.currentMarker.description;
    
        if (phoneNumberRegex.test(currentMarkerDescription)) {
          const phoneNumber = currentMarkerDescription.match(phoneNumberRegex)[0];
          window.location.href = `tel:${phoneNumber}`;
        } else {
          console.log('Số điện thoại không hợp lệ');
        }
      };

      // const copyPrompt2 = () => {
      //   window.open("https://www.google.com/maps/dir/?api=1&destination=" + state.currentMarker.lag + "," + state.currentMarker.lng + "&travelmode=driving");
      //   navigator.clipboard.writeText('AAAAAAAAA');
      // };




      const copyPrompt2 = (id) => {
        const url = new URL(window.location.href).origin + "?marker=" + state.currentMarker.id;
        navigator.clipboard.writeText(url)
          .then(() => {
            console.log('Đã sao chép vào clipboard');
            // Hiển thị thông báo cho người dùng
            // alert('Đã sao chép ');
            
            // Thực hiện các hành động tiếp theo tại đây, ví dụ: mở tab mới
            window.location.href = url;
            handleOpenAlert();
          })
          .catch((error) => {
            console.error('Lỗi khi sao chép vào clipboard:', error);
          });
      };
      




      const handleButtonClick2 = () => {
        const phoneNumberRegex = /\d{10}/;
        const currentMarkerDescription = state.currentMarker.description;
    
        if (phoneNumberRegex.test(currentMarkerDescription)) {
          const phoneNumber = currentMarkerDescription.match(phoneNumberRegex)[0];
          window.open(`https://zalo.me/${phoneNumber}`);
        } else {
          console.log('Số điện thoại không hợp lệ');
        }
      };
 


      
    const [expandedComments, setExpandedComments] = useState([]);

    const handleReadMoreClick = (index) => {
      setExpandedComments((prevExpandedComments) => {
        const newExpandedComments = [...prevExpandedComments];
        newExpandedComments[index] = true;
        return newExpandedComments;
      });
    };
  


    const [zoomedUrl, setZoomedUrl] = useState(null);

    const handleZoom = (imageUrl) => {
        setZoomedUrl(imageUrl);
    };

    const handleCloseZoom = () => {
        setZoomedUrl(null);
    };


    const [selectedImage, setSelectedImage] = useState(0); // Chọn ảnh đầu tiên ban đầu

    const handleThumbnailClick = (index) => {
        setSelectedImage(index);
    };
    return (
      <Modal
      open={state.isMarkerDialogOpen}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      style={{ zIndex: 9999999999, overflow: "hidden", maxHeight: "1000px", overflowX: "hidden", overflowY: "hidden" }}
  >
            <Box >

         
       

          <Box sx={style }> 
          <Box  onClick={handleClose}  style={{ height: "3rem", background:"none", zIndex: 9999,width:"100%"}}> 
          <IconButton        style={{  top: 5, right:5, cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"30px",  padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", zIndex:  9999999 }} >❌</IconButton> 
          </Box>
           
          <div></div>
          <Stack style={{ zIndex: 9999999999, overflow: "auto", maxHeight: "1000px", overflowX: "hidden", overflowY: "hidden" }}   direction="column"  >
               

               <Carousel activeIndex={selectedImage}>
               {state.currentMarker.image_url.split(",").map((value, index) => (
                   <Carousel.Item aspectRatio={16 / 9} key={index}>
                       <div
                           className="image-container"
                           style={{
                               position: "relative",
                               backgroundImage: `url(${value})`,
                               backgroundSize: "contain",
                               backgroundPosition: "center",
                               backgroundRepeat: "no-repeat",
                               width: "auto",
                               height: "50vh",
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center",
                               cursor: "pointer"
                           }}
                           onClick={() => handleZoom(value)}
                       >
                           <div className="text-overlay" style={{ color: "#ffffff", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", opacity: "0.4" }}>
                               <h1>tcmap.vn</h1>
                           </div>
                       </div>
                   </Carousel.Item>
               ))}
           </Carousel> 

                       <div>


                       <AppBar position="sticky" style={{ width: 350, bottom: '5%', margin: 'auto', zIndex: 99999999999999, background: "none", border: "none", boxShadow: 'none', color: "black" }}>
    <Toolbar style={{ display: 'flex', justifyContent: 'center', background: "none", border: "none" }}>
   <BottomNavigation showLabels
                       sx={{ 
                           ...modalStyle4, 
                           width: [`100%`, `100%`],
                           border: "none",
                           bottom: -10,
                           display: 'grid',
                           gridTemplateColumns: 'repeat(5, 1fr)', // Chia ra 5 cột có kích thước bằng nhau
                           alignItems: 'center', // Căn giữa các nút theo chiều dọc
                         }} >



<BottomNavigationAction

 className={`codepro-custom-btn codepro-btn-58 play-button ${button2Playing ? "active" : ""}`}
     // onClick={() => handleButtonClick("button2")}
     
// className={`play-button ${button2Playing ? "active" : ""}`}
style={{ borderRadius:"20px",left:10 }}
label='Chat zalo'
     icon={
<div>
   <svg 

onClick={() => handleButtonClick2("button1")}
 xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="30" height="30"><path fill="#2962ff" d="M15,36V6.827l-1.211-0.811C8.64,8.083,5,13.112,5,19v10c0,7.732,6.268,14,14,14h10	c4.722,0,8.883-2.348,11.417-5.931V36H15z"/><path fill="#eee" d="M29,5H19c-1.845,0-3.601,0.366-5.214,1.014C10.453,9.25,8,14.528,8,19	c0,6.771,0.936,10.735,3.712,14.607c0.216,0.301,0.357,0.653,0.376,1.022c0.043,0.835-0.129,2.365-1.634,3.742	c-0.162,0.148-0.059,0.419,0.16,0.428c0.942,0.041,2.843-0.014,4.797-0.877c0.557-0.246,1.191-0.203,1.729,0.083	C20.453,39.764,24.333,40,28,40c4.676,0,9.339-1.04,12.417-2.916C42.038,34.799,43,32.014,43,29V19C43,11.268,36.732,5,29,5z"/><path fill="#2962ff" d="M36.75,27C34.683,27,33,25.317,33,23.25s1.683-3.75,3.75-3.75s3.75,1.683,3.75,3.75	S38.817,27,36.75,27z M36.75,21c-1.24,0-2.25,1.01-2.25,2.25s1.01,2.25,2.25,2.25S39,24.49,39,23.25S37.99,21,36.75,21z"/><path fill="#2962ff" d="M31.5,27h-1c-0.276,0-0.5-0.224-0.5-0.5V18h1.5V27z"/><path fill="#2962ff" d="M27,19.75v0.519c-0.629-0.476-1.403-0.769-2.25-0.769c-2.067,0-3.75,1.683-3.75,3.75	S22.683,27,24.75,27c0.847,0,1.621-0.293,2.25-0.769V26.5c0,0.276,0.224,0.5,0.5,0.5h1v-7.25H27z M24.75,25.5	c-1.24,0-2.25-1.01-2.25-2.25S23.51,21,24.75,21S27,22.01,27,23.25S25.99,25.5,24.75,25.5z"/><path fill="#2962ff" d="M21.25,18h-8v1.5h5.321L13,26h0.026c-0.163,0.211-0.276,0.463-0.276,0.75V27h7.5	c0.276,0,0.5-0.224,0.5-0.5v-1h-5.321L21,19h-0.026c0.163-0.211,0.276-0.463,0.276-0.75V18z"/></svg>


</div>
   

     
 }
/>



<BottomNavigationAction
 className={`codepro-custom-btn codepro-btn-58 play-button ${button1Playing ? "active" : ""}`}
     onClick={() => handleButtonClick("button1")}
     style={{ borderRadius:"20px" }}
label='Youtube'
     icon={
 <div>

 </div>
      
     
 }
/>




<BottomNavigationAction
 className="codepro-custom-btn codepro-btn-58"
 id="contact-button" 
 style={{ borderRadius:"20px" }}
     icon={

       <a
       onClick={handleButtonClick1}
       id="contact-button"
       style={{
         cursor: "pointer",
         color: "rgb(238, 215, 5)",
         borderRadius: "30px",
         margin: "2px",
         padding: "5px 10px",
         fontSize: "25px",
         height: "4rem",
         width:"4rem",
         fontWeight: "bold",
         top: "-50px",
         textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
         whiteSpace: 'nowrap',

         display: 'inline-block', // Ensure the border is displayed
       }}
     >
       <i className="fa fa-phone fa-1.5x" aria-hidden="true"></i>
     </a>

     
 }
/>

     

      <BottomNavigationAction
 className="codepro-custom-btn codepro-btn-58"
 // onClick={copyPrompt2} 
 onClick={() => handleButtonClick("button2")}
 style={{ borderRadius:"20px" }}
label='tik tok'
     icon={
       <div>
{/* <svg   className={`codepro-custom-btn codepro-btn-58 play-button ${button1Playing ? "active" : ""}`}
     onClick={() => handleButtonClick("button1")}
     xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><path fill="#FF3D00" d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"/><path fill="#FFF" d="M20 31L20 17 32 24z"/></svg> */}

       </div>


    
 }
/>





<BottomNavigationAction
 className="codepro-custom-btn codepro-btn-58"
 onClick={handleCopyToClipboard} 
 style={{ borderRadius:"20px",left:-10 }}
label='Chia sẻ'
     icon={
       <div>
 {/* <svg onClick={() => {
         if (localStorage.getItem("token") === null) {
             window.location.href = "/login";  
         } else {
          
         }
     }}
     xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-browser-safari" viewBox="0 0 16 16">
 <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.25-14.75v1.5a.25.25 0 0 1-.5 0v-1.5a.25.25 0 0 1 .5 0m0 12v1.5a.25.25 0 1 1-.5 0v-1.5a.25.25 0 1 1 .5 0M4.5 1.938a.25.25 0 0 1 .342.091l.75 1.3a.25.25 0 0 1-.434.25l-.75-1.3a.25.25 0 0 1 .092-.341m6 10.392a.25.25 0 0 1 .341.092l.75 1.299a.25.25 0 1 1-.432.25l-.75-1.3a.25.25 0 0 1 .091-.34ZM2.28 4.408l1.298.75a.25.25 0 0 1-.25.434l-1.299-.75a.25.25 0 0 1 .25-.434Zm10.392 6 1.299.75a.25.25 0 1 1-.25.434l-1.3-.75a.25.25 0 0 1 .25-.434ZM1 8a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 0 .5h-1.5A.25.25 0 0 1 1 8m12 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 1 1 0 .5h-1.5A.25.25 0 0 1 13 8M2.03 11.159l1.298-.75a.25.25 0 0 1 .25.432l-1.299.75a.25.25 0 0 1-.25-.432Zm10.392-6 1.299-.75a.25.25 0 1 1 .25.433l-1.3.75a.25.25 0 0 1-.25-.434ZM4.5 14.061a.25.25 0 0 1-.092-.341l.75-1.3a.25.25 0 0 1 .434.25l-.75 1.3a.25.25 0 0 1-.342.091m6-10.392a.25.25 0 0 1-.091-.342l.75-1.299a.25.25 0 1 1 .432.25l-.75 1.3a.25.25 0 0 1-.341.09ZM6.494 1.415l.13.483a.25.25 0 1 1-.483.13l-.13-.483a.25.25 0 0 1 .483-.13M9.86 13.972l.13.483a.25.25 0 1 1-.483.13l-.13-.483a.25.25 0 0 1 .483-.13M3.05 3.05a.25.25 0 0 1 .354 0l.353.354a.25.25 0 0 1-.353.353l-.354-.353a.25.25 0 0 1 0-.354m9.193 9.193a.25.25 0 0 1 .353 0l.354.353a.25.25 0 1 1-.354.354l-.353-.354a.25.25 0 0 1 0-.353M1.545 6.01l.483.13a.25.25 0 1 1-.13.483l-.483-.13a.25.25 0 1 1 .13-.482Zm12.557 3.365.483.13a.25.25 0 1 1-.13.483l-.483-.13a.25.25 0 1 1 .13-.483m-12.863.436a.25.25 0 0 1 .176-.306l.483-.13a.25.25 0 1 1 .13.483l-.483.13a.25.25 0 0 1-.306-.177m12.557-3.365a.25.25 0 0 1 .176-.306l.483-.13a.25.25 0 1 1 .13.483l-.483.13a.25.25 0 0 1-.306-.177M3.045 12.944a.3.3 0 0 1-.029-.376l3.898-5.592a.3.3 0 0 1 .062-.062l5.602-3.884a.278.278 0 0 1 .392.392L9.086 9.024a.3.3 0 0 1-.062.062l-5.592 3.898a.3.3 0 0 1-.382-.034zm3.143 1.817a.25.25 0 0 1-.176-.306l.129-.483a.25.25 0 0 1 .483.13l-.13.483a.25.25 0 0 1-.306.176M9.553 2.204a.25.25 0 0 1-.177-.306l.13-.483a.25.25 0 1 1 .483.13l-.13.483a.25.25 0 0 1-.306.176"/>
</svg> */}
       </div>
     
    
 }
/>



   </BottomNavigation>

    
</Toolbar>
</AppBar>

                       <div>

                         
<br/>

<Toolbar style={{ display: 'flex', justifyContent: 'center', background: "none", border: "none", margin: "auto", width: 350 ,padding :"20px"}}>
                         <div className="thumbnail-container" style={{ overflowX: "auto", whiteSpace: "nowrap" }}> &nbsp;  &nbsp; 
                         {state.currentMarker.image_url.split(",").map((value, index) => (
                                 <img
                                     key={index}
                                     style={{
                                         borderRadius: "5px",
                                         width: "5rem",
                                         height: "3rem",
                                         cursor: "pointer",
                                         border: index === selectedImage ? "5px solid #fcb332" : "none",
                                         marginRight: "0.3rem",
                                         display: "inline-block",
                                         objectFit: "cover" // Maintain aspect ratio
                                     }}
                                     src={value}
                                     alt={`Thumbnail ${index + 1}`}
                                     onClick={() => handleThumbnailClick(index)}
                                 />
                             ))}
                         </div> &nbsp;  &nbsp; 
                     </Toolbar>





       </div>

            

           


          




         {zoomedUrl && (
   <div className="zoomed-container"  onClick={handleCloseZoom} >


<div
   className="image-container"
   style={{
       position: "relative",
       zIndex:999999999999999999,
       backgroundImage: `url(${zoomedUrl})`,
       backgroundSize: "contain",
       backgroundPosition: "center",
       backgroundRepeat: "no-repeat",
       cursor: "pointer"
   }}
   //  onClick={handleZoom}
   
>
<div className="text-overlay" style={{ color: "#ffffff", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", opacity: "0.4" }}>
                               <h1>tcmap.vn</h1>
                           </div>
</div>

       
   </div>
)}






                       </div>



                       


                       
  
  
                       <Stack   style={{ cursor: "pointer"  ,size: "10px" ,position : "relative",borderRadius:"10px", padding: "5px 10px", fontSize: "13px", fontWeight: "bold", color : "black"}}>

     

 
                                   
                                   {button1Playing && (
                                   <iframe
                                   src={ ("https://www.youtube.com/embed/"  +  state.currentMarker.youtube_url)  }
                                       style={{
                                       borderRadius: "10px",
                                       width: "100%",
                                       height: "50vh",
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "center",
                                       }}
                                       frameBorder="0"
                                       allow="autoplay; encrypted-media"
                                       allowFullScreen
                                       title="video1"
                                       borderRadius="20px"
                                       height="250"
                                   />
                                   )}

                                   {button2Playing && (


                                   <iframe
                                   class="tiktok-embed"
                                   data-video-id="7278540119441689863"
                                       src={ ("https://www.tiktok.com/embed/"  +  state.currentMarker.youtube_url +"?autoplay=1" )  }
                                       style={{
                                       borderRadius: "10px",
                                       width: "100%",
                                       height: "70vh",
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "center",
                                       }}
                                       frameBorder="0"
                                       allow="autoplay; encrypted-media"
                                       allowFullScreen
                                       title="video2"
                                       borderRadius="20px"
                                       height="250"
                                   />


                                   )}
                                   
                                   
                         
  
  <Stack   style={{ cursor: "pointer"  ,size: "10px" ,position : "relative",borderRadius:"10px", padding: "5px 10px", fontSize: "13px", fontWeight: "bold", color : "black"}}>
                       
                           

                               <span    
                                 style={{
                                  //  border: "1px solid DodgerBlue",
                                   height: 'auto',
                                   borderRadius: 8,
                                   fontWeight: 'bold',
                                   whiteSpace: 'pre-wrap',
                                   display: 'flex',
                                   flexDirection: 'column', // Hiển thị các phần tử theo chiều dọc
                                   fontSize: '13px',
                                   textAlign: 'left',
                                   padding: '10px' // Điều chỉnh padding nếu cần
                                 }}
                                 spacing={2}
                               >
                                 <div>
                                 <DialogTitle  style={{textAlign: 'center' ,fontSize:"1rem",fontWeight:"bold", padding: '10px'}}> <b>{state.currentMarker.name} 
                                 <hr
                                   style={{
                                       opacity: "0.1",
                                       top: "5px",
                                       height: "0.5px",
                                       backgroundColor: "#000",
                                       border: "none",
                                       margin: "4px 3px 5px",
                                       fontWeight: "bold"
                                   }}
                               /></b>
                              </DialogTitle>
                                   <b  style={{ padding: '10px' }}>Thông tin mô tả</b>
                                 </div>
                                 <div style={{ padding: '10px' }}>
                                 {state.currentMarker.description}
                                 </div>

                                 <hr
                   style={{
                       opacity: "0.1",
                       top: "5px",
                       height: "0.5px",
                       backgroundColor: "#000",
                       border: "none",
                       margin: "4px 3px 5px",
                       fontWeight: "bold"
                   }}
               />
{/* {moment(state.currentMarker.exp_date * 1000).subtract(119, 'day').format('HH:mm - DD/MM/YYYY')
}  */}



<hr
                   style={{
                       opacity: "0.1",
                       top: "5px",
                       height: "0.5px",
                       backgroundColor: "#000",
                       border: "none",
                       margin: "4px 3px 5px",
                       fontWeight: "bold",
                       padding: '20px'
                      
                   }}
               />
                  <div style={{ padding: '10px' }}>
                  {state.currentMarker.youtube_url}
                                 </div>


                               </span>

                         
                           </Stack>
                       </Stack>
                       
     


                
  
  <Stack   style={{ cursor: "pointer"  ,size: "10px" ,position : "relative",borderRadius:"10px", padding: "5px 10px", fontSize: "13px", fontWeight: "bold", color : "black"}}>
                               
                       <div style={{ position: 'relative', width: '100%' }}>
 <div style={{
   position: 'absolute',
   left: 0,
   right: 0,
   margin: 'auto',
   width: '70%', /* Điều chỉnh kích thước của phần tử con nếu cần */
 }}>
 <FormControl
   value={comment}
   onChange={handleCommentChange}
   id="outlined-required"
   fullWidth
   placeholder="Viết bình luận..."
   variant="outlined"
   onKeyPress={(e) => {
     if (e.key === 'Enter') {
       e.preventDefault();
       handleAddComment();
     }
   }}
   className="leaflet-tooltip9"
   type="search"
   style={{
     cursor: 'pointer',
     size: '10px',
     borderRadius: '10px',
     fontSize: '14px',
     color: 'black',
     display: 'flex',
     background: '#ffff',
     paddingRight: '40px', // Adjusted padding to accommodate the icon
   }}
   isClearable
 />

 {comment && (
   <span
     style={{
       position: 'absolute',
       right: 5, // Adjusted right position for the icon
       top: 18, // Center vertically
       transform: 'translateY(-50%)', // Center vertically
       cursor: 'pointer',
       zIndex: 1, 
       borderRadius:"30px"// Ensuring the icon is above the input field
     }}
     type="submit"
     size="small"
     color="primary"
     variant="contained"
     onClick={handleAddComment}
   >

<svg xmlns="http://www.w3.org/2000/svg"  class="bi bi-file-arrow-up red-icon" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
 <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
</svg>

  
   </span>
 )}
</div></div> <br/>


{comment && (
 <div style={{ position: 'relative', width: '100%' }}>
   
   {localStorage.getItem("token") ? (
     <span
       style={{
         position: 'absolute',
         right: '10px', // Adjusted right position for the icon
         top: '50%', // Center vertically
         transform: 'translateY(-50%)', // Center vertically
         cursor: 'pointer',
         zIndex: 1,
         borderRadius: '30px', // Ensuring the icon is above the input field
       }}
       type="submit"
       size="small"
       color="primary"
       variant="contained"
       onClick={handleAddComment}
     >
     </span>
   ) : (
     <div>
       <Link to="/login">
         <span style={{ fontSize: '14px', color: 'blue', cursor: 'pointer' }}>
           Đăng nhập để bình luận
         </span>
       </Link>
     </div>
   )}
 </div>
)}
                           {localStorage.getItem("token") != null ? <Stack direction="row" spacing={1}>
                           <form onSubmit={handleAddComment}>




                               
{comment && (
 <div style={{ position: 'relative', width: '100%' }}>
   
   {localStorage.getItem("token") ? (
     <span
       style={{
         position: 'absolute',
         right: '10px', // Adjusted right position for the icon
         top: '50%', // Center vertically
         transform: 'translateY(-50%)', // Center vertically
         cursor: 'pointer',
         zIndex: 1,
         borderRadius: '30px', // Ensuring the icon is above the input field
       }}
       type="submit"
       size="small"
       color="primary"
       variant="contained"
       onClick={handleAddComment}
     >
     </span>
   ) : (
     <div>
       <Link to="/login">
         <span style={{ fontSize: '14px', color: 'blue', cursor: 'pointer' }}>
           Đăng nhập để bình luận
         </span>
       </Link>
     </div>
   )}
 </div>
)}
                           

                           </form>
                           </Stack> : null}

<br/>
                          <Stack sx={{height: "350px", overflow: "scroll"}} direction="column" spacing={2}>
                               

                                       {comments.map((data, index) => (
                                   <div key={index}>
                                   <Stack direction="row" spacing={1}>
                                       <h style={{
                                    
                                    fontSize: '10px',
                                   fontWeight: 'bold',
                                   textAlign: 'left',
                               }}>@{data.user.name}</h><br/>


                                       {/* <small 
                                       style={{
                                         fontSize:"8px",
                                         fontWeight:"bold"
                                     }}>{moment(data.commented_date * 1000).fromNow()}</small> */}
                                     
                                   </Stack>
                                   <div>
                                   {data.comment && (
           <p style={{ textAlign: "left" }}>
               {showFullComment ? data.comment : `${data.comment.slice(0, 10)}${data.comment.length > 10 ? '...' : ''}`}
               {!showFullComment && data.comment.length > 10 && (
                   <span onClick={toggleReadMore} style={{ color: "blue", cursor: "pointer", fontSize: "8px" }}>
                       Đọc tiếp
                   </span>
               )}
               <hr
                   style={{
                       opacity: "0.1",
                       top: "5px",
                       height: "0.5px",
                       backgroundColor: "#000",
                       border: "none",
                       margin: "4px 3px 5px",
                       fontWeight: "bold"
                   }}
               />
           </p>
       )}

                                   </div>

                                  
                                 
                               </div>
                               ))}



                               
                           </Stack>
                           
                       </Stack>




               </Stack>
          </Box>
              
              

 

      
            </Box>
            
        </Modal>
    );
}

