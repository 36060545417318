import React, { useEffect, useState, useRef } from "react";
import  { useMap } from "react-leaflet";
import L, { Util } from "leaflet";
import "leaflet-textpath"
import "leaflet.vectorgrid";
import Form from "react-bootstrap/Form";
import PlaceInfoComponent from "../../components/PlaceInfoComponent";
import { connect, useSelector } from "react-redux";
import classes from "./index.module.scss";
import ScanLocationModal from "../ScanLocation";
import SearchMapView from "../SearchMapView";
import { Utils } from "../../Utils/Utils";
import { Services } from "../../APIs/Services";
import { Stack, Snackbar, Alert, Avatar,Menu ,IconButton,Button} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { InputGroup, FormControl } from 'react-bootstrap';
import LocateControl from "../../components/LocateControl";
import "./index.module.scss"
import Geoman from "../Geoman";
import DrawTool from "../../components/DrawTool";
import 'leaflet-draw'; // Make sure to import Leaflet Draw
import { Marker ,useMapEvent,useMapEvents} from "react-leaflet";
import MarkerForm from "../MarkerForm";
const commonStyle = {
  // Các thuộc tính chung khác của thanh
  cursor: "pointer",
  fontSize: "14px",
  display: "flex",
  top: 20,
  background: "none",
  left: "49%",
  border: "none",
  position: "absolute",
  transform: "translate(-50%, -50%)", // Đặt thanh ở trung tâm cả chiều dọc và ngang
};

// CSS trên mobile
const mobileStyle = {
  width: "90%",
  borderRadius: "10px",
  ...commonStyle,
  justifyContent: "center", // Căn giữa theo chiều ngang
};

// CSS trên desktop
const desktopStyle = {
  width: "30%",
  borderRadius: "10px",
  ...commonStyle,
  justifyContent: "center", // Căn giữa theo chiều ngang
};


export const SELECTED_STYLE = {
  fill: true,
  weight: 5,
  color : "#F6180A",
  fillColor: "none",
  fillOpacity: 0,
  opacity: 1,
  style: {
    weight: 0.3,
    opacity: 1,
    color: "#ccc",
    fillColor: "#390870",
    fillOpacity: 0.6,
    fill: true,
    stroke: true
  },
  hoverStyle: {
    fillColor: "#390870",
    fillOpacity: 1
  },
  activeStyle: {
    fillColor: "#390870",
    fillOpacity: 1
  },
  zIndex: 401

 
};


export const NOT_SELECTED_STYLE = { 
  fill: true,
  weight:0.3,
  fillOpacity: 0,
  color : "blue",
  fillColor: "none",
  stroke: '#07FA12' ,

  
}
;

 

const telegram = () => {
  window.open("https://t.me/tcmapvn");
  return null;
};
const call = () => {
  window.location.href="tel:0799799993";
  return null;
};
const zalo = () => {
  window.open("https://zalo.me/g/lclyht135");
  return null;
};
 

delete L.Icon.Default.prototype._getIconUrl;

// Load marker
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
  
 


function MapBoxVectorTile(props) {
  const { dataMapJson, searchFeatureData, setIsHidePlaceInfo } = props;
  const isHidePlaceInfo = useSelector(state => state.mapBoxVectorTile.isHidePlaceInfo)
  const { vector_layers, center } = dataMapJson;
  const map = useMap();
  const [openSearch, setOpenSearch] = useState(true);
  const [toNumber, setToNumber] = useState(null);
  const [thuaNumber, setThuaNumber] = useState(null);
  const [openControl2, setOpenControl2] = useState(true);
 
  const style = {
    weight: 1,
    opacity: props.opacity, // Connect opacity value with selectedOpacity
    fillOpacity: props.opacity * 0.1, // Correct syntax for fillOpacity
    fill: `rgba(255, 0, 0, ${props.opacity * 0.1})`, // Correct syntax for fill
  };
  
  


 
  
const vectorTileStyling = ({ nameLayer, searchValue, setCurrentSelectPlace ,event }) => ({
  [nameLayer]: (properties, _zoom, _geometryDimension) => {
    
  

    if (
      searchValue &&
      properties?.SHBANDO == searchValue?.SHBANDO &&
      properties?.SHTHUA == searchValue?.SHTHUA
    ) {
      setCurrentSelectPlace({ properties });
      // style.color = "#920899"; // Màu đường line khi được chọn (color)
      style.fillColor = "#920899";  
      
      return style;
    }

    

    // Ánh xạ các giá trị KH2003 vào màu sắc tương ứng
    const kh2003Colors = {
      
MVK:"#b4ffff",
"  ODT+CLN":"rgba(255,208,255,1)",
" ONT+BHK":"rgba(255,208,255,1)",
"ONT+CLN":"rgba(255,208,255,1)",
"ONT+TMD":"rgba(255,208,255,1)",
"CLN+ODT":"rgba(255,208,255,1)",
"ONT+HNK":"rgba(255,208,255,1)",
"ONT+LNQ":"rgba(255,208,255,1)",
"ONT+NHK":"rgba(255,208,255,1)",
"ONT+TMD":"rgba(255,208,255,1)",
"ONT+NHK":"rgba(255,208,255,1)",
"ONT+TMD":"rgba(255,208,255,1)",
"ONT+NHK":"rgba(255,208,255,1)",
"ONT+TMD":"rgba(255,208,255,1)",
"ONT+NHK":"rgba(255,208,255,1)",
"ONT+LNK":"rgba(255,208,255,1)",
"ODT+CLN":"#ffb4ff",
"ONT+BHK":"#ffb4ff",
"ONT+CLN":"#ffb4ff",
"ONT+TMD":"#ffb4ff",
"CLN+ODT":"#ffb4ff",
"ONT+HNK":"#ffb4ff",
"ONT+LNQ":"#ffb4ff",
"ONT+LNK":"#ffb4ff",
"ODT+BHK":"#ffb4ff",
"ODT+":"#ffb4ff",
"ODT+":"#ffb4ff",
"ODT+":"#ffb4ff",
"ODT+":"#ffb4ff",
"ODT+LUC":"#ffb4ff",
"ODT+NTS":"#ffb4ff",
"RPN+NHK":"rgba(190,255,30,1)",
"ODT+PNK":"#ffb4ff",
"ODT+LNK":"#ffb4ff",
"ODT+":"#ffb4ff",
"ONT+LUC":"#ffb4ff",
"ODT+NNP":"#ffb4ff",
"ONT+LNK":"#ffb4ff",
"CLN+BHK":"#ffd2a0",
"CLN+CLN":"#ffb4ff",
"CLN+LUC":"#ffb4ff",
"CLN+ODT":"#ffb4ff",
"ODT+CLN":"#ffb4ff",
"ODT+CLN":"#ffb4ff",
"ONT+":"#ffb4ff",
"ONT+LNC":"#ffb4ff",
"ONT+LNC":"#ffb4ff",
"ONT+LNC":"#ffb4ff",
"ONT+LNC":"#ffb4ff",
"ONT+LNC":"#ffb4ff",
"RSM+RSN":"rgba(180,255,180,1)",
"RSM+COC":"#ffb4ff",
"RSM+NHK":"#ffb4ff",
"ODT+LNQ":"#ffb4ff",
"ONT+NHK":"#ffb4ff",
"ONT+BHK+BHK":"#ffb4ff",
"RSX+BHK":"#fff0b4",
"ONT+CLN+CLN":"#ffb4ff",
"ONT+RSX":"#ffb4ff",
"DGT+CLN":"#fff0b4",
"BHK+BHK":"#fff0b4",
"BHK+LUC":"#fff0b4",
"DGT+BHK":"#fff0b4",
"LUC+LUC":"#fffc82",
"TON+BHK":"#ffaaa0",
"RSX+CLN":"#fffc96",
"DTL+BHK":"#fff0b4",
"RSX+RSX":"#BBDDA9",
"ONT+CLN+BHK":"ffb4ff",


"BHK":"#fff0b4",
"LNK+CLN":"#fff0b4",
"ONT+CLN+LNK":"#ffb4ff",
"ONT+CLN+LNC":"#ffb4ff",
"LNK+LNQ":"#fff0b4",
"LNK+LNK":"#fff0b4",
"ONT+CLN+LNQ":"#ffb4ff",
"LNQ+CLN":"#fff0b4",
"LNK+LUC":"#fff0b4",
"LNQ+BHK":"#fff0b4",
"LNC+CLN":"#fff0b4",
"NTD+CLN":"#fff0b4",
"LNK+LNC":"#fff0b4",
"LNQ+LNQ":"#fff0b4",
"LNQ+LNC":"#fff0b4",
"LNK+BHK":"#fff0b4",
"LNC+LNC":"#fff0b4",
"DNL+LNK":"#fff0b4",
"NTD+LNC":"#fff0b4",
"NTS+LUC":"#fffc8c",
"NTS+LUC":"#fff0b4",
"xxxxxxxxxxxxx":"rgba(255,208,255,1)",
"NNP":"#ffff64",
"SXN":"#fffc6e",
"CHN":"#fffc78",
"LUA":"#fffc82",
"LUC":"#fffc8c",
"LUK":"#fffc96",
"LUN":"#fffcb4",
"COC":"#F9F55B",
"COT":"#F9F55B",
"CON":"#F9F55B",
"HNK":"#fff0b4",
"BHK":"#fff0b4",
"NHK":"#fff0b4",
"CLN":"#ffd2a0",
"LNC":"#ffd2a0",
"LNQ":"#ffd2a0",
"LNK":"#ffd2a0",
"LNP":"#aaff32",
"RSX":"#BBDDA9",
"RSN":"#BBDDA9",
"RST":"#BBDDA9",
"RSK":"#BBDDA9",
"RSM":"#BBDDA9",
"RPH":"#BFD72F",
"RPN":"#BFD72F",
"RPT":"#BFD72F",
"RPK":"#BFD72F",
"RPM":"#BFD72F",
"RDD":"#8EC850",
"RDN":"#8EC850",
"RDT":"#8EC850",
"RDK":"#8EC850",
"RDM":"#8EC850",
"TSN":"#aaffff",
"TSL":"#aff",
"NTS":"#aaffff",
"LMU":"#fffffe",
"NKH":"#f5ffb4",
"PNN":"#ffff64",
"OTC":"#ffb4ff",
"ONT":"#ffd0ff",
"ODT":"#ffa0ff",
"CDG":"#ffa0aa",
"CTS":"#FEAAA0",
"TSC":"#FEAAA0",
"TSK":"#FEAAA0",
"CQP":"#ff6450",
"CAN":"#ff5046",
"CSK":"#ffa0aa",
"SKK":"#faaaa0",
"SKN":"#faaaa0",
"SKT":"#faaaa0",
"TMD":"#faaaa0",
"SKC":"#faaaa0",
"SKS":"#cdaacd",
"SKX":"#cdaacd",
"CCC":"#ffaaa0",
"DGT":"#ffaa32",
"TL":"#aff",
"DNL":"#ffaaa0",
"DBV":"#ffaaa0",
"DVH":"#ffaaa0",
"DYT":"#ffaaa0",
"DGD":"#ffaaa0",
"DTT":"#ffaaa0",
"DKH":"#ffaaa0",
"DXH":"#ffaaa0",
"DCH":"#ffaaa0",
"DDT":"#ffaaa0",
"DRA":"#cdaacd",
"DDL":"#ffaaa0",
"DSH":"#ffaaa0",
"DKV":"#ffaaa0",
"DCK":"#ffaaa0",
"DSN":"#ffa0aa",
"DTS":"#faaaa0",
"DNG":"#ffaaa0",
"DSK":"#ffaaa0",
"DHT":"#FFAAA0",
"TTN":"#FEAAA0",
"TON":"#ffaaa0",
"TIN":"#ffaaa0",
"NTD":"#d2d2d2",
"SMN":"#a0ffff",
"SON":"#a0ffff",
"MNC":"#b4ffff",
"PNK":"#ffaaa0",
"CSD":"#fffffe",
"BCS":"#fffffe",
"DCS":"#fffffe",
"NCS":"#E6E6C8",
"MVB":"#b4ffff",
"MVT":"#b4ffff",
"MVR":"#b4ffff",
"MVK":"#b4ffff",
 
    };

    const kh2003Value = properties?.KH2003;

    if (kh2003Value && kh2003Colors[kh2003Value]) {
      style.color = kh2003Colors[kh2003Value];
      
    } else {
      style.color = "#fff"; // Màu đường line mặc định
      style.background = "#ff000000"; 
      style.fillColor = "#ff000000";
    }

    return style;
  },
});






  const [openSearchMap, setOpenSearchMap] = useState(false);
  const [toSearchMapText, setToSearchMapText] = useState();

  const [currentMapboxPbfLayer, setCurrentSelectMapboxPbfLayer] =
    useState(false);
  const [currentSelectPlace, setCurrentSelectPlace] = useState(null);
  const searchRef = useRef(null);
  const [listSearch, setListSearch] = useState([]);
  const [listMapSearch, setListMapSearch] = useState([]);
  const [currentSelectSearchItem, setCurrentSelectSearchitem] = useState(null);
 
 
  const createMapboxPbfLayer = ({ clickk, isAddMarker, searchValue }) => {
    if (currentMapboxPbfLayer) {
      map.removeLayer(currentMapboxPbfLayer);
      setCurrentSelectMapboxPbfLayer(mapboxPbfLayer);
    }
  
    const mapboxUrl = Utils.CAN_GIUOC_URL_MAPBOX_VECTOR_TILE;
    const isIPhone = /iPhone/i.test(navigator.userAgent);
  
    var isInFullScreen = false;
  
    function checkFullScreen() {
      if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
        isInFullScreen = true;
      } else {
        isInFullScreen = false;
      }
    }
  
    document.addEventListener('fullscreenchange', checkFullScreen);
    document.addEventListener('webkitfullscreenchange', checkFullScreen);
    document.addEventListener('mozfullscreenchange', checkFullScreen);
    document.addEventListener('MSFullscreenChange', checkFullScreen);
  
    function checkPageVisibility() {
      if (document.visibilityState === 'hidden') {
        isInFullScreen = false;
      }
    }
  
    document.addEventListener('visibilitychange', checkPageVisibility);
  
    var mapboxVectorTileOptions = {
      vectorTileLayerStyles: vectorTileStyling({
        nameLayer: vector_layers[0].id,
        searchValue,
        isAddMarker,
        setCurrentSelectPlace,
        clickk,
      }),
      
      interactive: function (event) {
        if ((event.type === "contextmenu" && isIPhone) || !isInFullScreen) {
          return false;
        }
        return true;
      },
      minZoom: isIPhone ? 18 : 18,
      getFeatureId: (feature) => {
        return `${feature.properties.SHBANDO}_${feature.properties.SHTHUA}_${feature.properties.ID}`;
      },
      ...(isIPhone ? {} : { rendererFactory: L.canvas.tile }),
    };
    
    var mapboxPbfLayer = L.vectorGrid.protobuf(mapboxUrl, mapboxVectorTileOptions).addTo(map);
    
    mapboxPbfLayer.on('load', function() {
      // Loop through each layer in the vector grid layer
      mapboxPbfLayer.eachLayer(layer => {
        if (layer instanceof L.Polygon || layer instanceof L.MultiPolygon) {
          // Get coordinates of the polygon
          const latlngs = layer.getLatLngs();
          
          // Calculate and display the length of each edge
          for (let i = 0; i < latlngs.length; i++) {
            const latlng = latlngs[i];
            for (let j = 0; j < latlng.length - 1; j++) {
              const start = latlng[j];
              const end = latlng[j + 1];
              const distance = start.distanceTo(end);
              console.log(`Edge length of polygon: ${distance} meters`);
            }
          }
        }
      });
    });
    
  
    let marker = null;
 
    let canClick = true; // Biến để kiểm tra xem có thể thực hiện click sau khi kéo map hay không

    // Sự kiện dragend được kích hoạt khi kết thúc kéo map
    map.on("dragend", () => {
      if (!canClick) { // Kiểm tra nếu biến flag là false
        const center = map.getCenter(); // Lấy tọa độ trung tâm của bản đồ sau khi kéo map
    
        const fakeClickEvent = { // Tạo sự kiện giả lập tương tự như khi click vào tọa độ mới
          target: {
            getLngLat: () => center,
            properties: {}, // Có thể cần điều chỉnh nếu cần sử dụng các thuộc tính khác
          },
          originalEvent: null, // Bạn có thể thêm các thông tin của sự kiện gốc nếu cần
        };
    
        handleFakeClick(fakeClickEvent); // Gọi hàm xử lý như khi click
        canClick = true; // Đặt lại biến flag thành true sau khi click
      }
    });
    
  
    mapboxPbfLayer.on("click", (event) => {
      const lngLat = event.latlng;
      const fakeClickEvent = {
        target: {
          getLngLat: () => lngLat,
          properties: event.layer.properties,
        },
        originalEvent: event.originalEvent,
      };
  
      handleFakeClick(fakeClickEvent);
    });
  
    function handleFakeClick(event) {
      const properties = event.target.properties;
      setToNumber(properties.SHTHUA);
      setThuaNumber(properties.SHBANDO);
      setCurrentSelectPlace({ properties });
  
      mapboxPbfLayer.removeEventListener("click", () => {}, false);
   
  
      const newCenter = event.target.getLngLat();
  
      const customDivIcon = L.divIcon({
        permanent: true,
        background: "rgba(255, 166, 0, 0)",
        color: "none"
      });
  
      if (marker) {
        map.removeLayer(marker);
      }
  
      marker = L.marker(newCenter, { icon: customDivIcon }).addTo(map);
      marker.setZIndexOffset(1000);
  
      const apiUrl = `${newCenter.lat}&lon=${newCenter.lng}`;
  
      fetch(apiUrl)
        .then((data) => {
          const popupContent = `<div style="text-align: center; font-weight: bold; font-size: 0.6rem; background: none;">${properties.SHBANDO}/${properties.SHTHUA}</div>`;
          marker.setLatLng(newCenter);
          marker.bindTooltip(popupContent, {
            permanent: true,
            direction: 'top',
            offset: [0, -15],
            background: "rgba(255, 166, 0, 0)"
          });
  
          map.panTo(newCenter);
  
          map.on('zoomend', toggleMarkerVisibility);
          function toggleMarkerVisibility() {
            const currentZoom = map.getZoom();
            if (currentZoom >= 18 && currentZoom <= 20) {
              marker.addTo(map);
            } else {
              map.removeLayer(marker);
            }
          }
  
          toggleMarkerVisibility();
        })
        .catch((error) => {
          console.error('Lỗi khi tìm kiếm địa chỉ:', error);
        });
    }
 
    // const drawnItems = new L.FeatureGroup();
    // map.addLayer(drawnItems);
    
    // const drawControl = new L.Control.Draw({
    //   edit: {
    //     featureGroup: drawnItems
    //   },
    //   draw: {
    //     polygon: false,
    //     rectangle: false,
    //     circle: false,
    //     marker: false,
    //     polyline: true 
    //   }
    // });
    // map.addControl(drawControl);
    
    // map.on(L.Draw.Event.CREATED, function (event) {
    //   var layer = event.layer;
    //   drawnItems.addLayer(layer);
    
  
    //   if (layer instanceof L.Polyline) {
    //     const latlngs = layer.getLatLngs();
     
    //     for (let i = 0; i < latlngs.length - 1; i++) {
    //       const start = latlngs[i];
    //       const end = latlngs[i + 1];
    //       const distance = start.distanceTo(end);
    //       const tooltipContent = `Segment length: ${distance.toFixed(2)} meters`;
    
    //       const midPoint = L.latLng(
    //         (start.lat + end.lat) / 2,
    //         (start.lng + end.lng) / 2
    //       );
    
    //       const segment = [start, end];
    //       const segmentPolyline = L.polyline(segment, { color: 'blue' }).addTo(map);
    //       segmentPolyline.bindTooltip(tooltipContent, {
    //         permanent: true,
    //         direction: 'center',
    //         offset: [0, -15],
    //         opacity: 0.75,
    //       }).openTooltip(midPoint);
    //     }
    //   }
    // });
    
    setCurrentSelectMapboxPbfLayer(mapboxPbfLayer);
    return mapboxPbfLayer;
  };
  
  useEffect(() => {
    createMapboxPbfLayer({ isAddMarker: props.isAddMarker });
  }, [props.isAddMarker]);
  

 

useEffect(() => {
  if (currentSelectPlace && toNumber !== null && thuaNumber !== null) {
    const tempMapboxPbfLayer = createMapboxPbfLayer({isAddMarker: props.isAddMarker});
    tempMapboxPbfLayer.setFeatureStyle(
      `${currentSelectPlace?.properties?.SHBANDO}_${currentSelectPlace?.properties?.SHTHUA}_${currentSelectPlace?.properties?.ID}`,
      SELECTED_STYLE
    );
  }
 
}, [currentSelectPlace]);





const handleCloseInfoTab = () => {
  if (currentMapboxPbfLayer) {
    currentMapboxPbfLayer.resetFeatureStyle(
      ` `
    );
  }
  setCurrentSelectPlace(null);
};

useEffect(() => {
  if (!openSearch) {
    setListSearch([]);
    setToNumber(null);
    setThuaNumber(null);
  }
}, [openSearch]);

useEffect(()=>{
  if (isHidePlaceInfo) {
    handleCloseInfoTab()
  }
}, [isHidePlaceInfo])











const searchInMapHandler = () => {
  if (toNumber === null || thuaNumber === null) {
    return;
  }
  if (searchFeatureData) {
    const newListSearch = searchFeatureData.features.filter((item) => {
      const [shbando, shthua] = item.properties.SHBANDO.replace("to ", "")
        .replace(" thua ", "_")
        .replace("my loc", "")
        .split("_");
      if (shbando === `${toNumber}` && shthua === `${thuaNumber}`) {
        return true;
      }
      return false;
    });
    setListSearch(newListSearch);
    if (newListSearch.length > 0) {
      createMapboxPbfLayer({
        searchValue: {
          SHBANDO: toNumber,
          SHTHUA: thuaNumber,
        },
        isAddMarker: props.isAddMarker
      });
      const newCenter = newListSearch[0].geometry.coordinates;
      setCurrentSelectSearchitem(newListSearch[0]);

      const label = `<div style="text-align: center; font-weight: bold;  font-size: 0.6rem;">${toNumber}/${thuaNumber}</div>`;

      // Create a custom divIcon without an icon
      const customDivIcon = L.divIcon({
        permanent: true,background:"none",color:"none"
      });
      
      // Add the marker with the custom divIcon to the map
      const marker = L.marker([newCenter[1], newCenter[0]], { icon: customDivIcon }).addTo(map);
      
      // Optionally, you can set the zIndexOffset to ensure the label appears above other map elements
      marker.setZIndexOffset(1000);
      map.on('zoomend', toggleMarkerVisibility);
      function toggleMarkerVisibility() {
        const currentZoom = map.getZoom();
        if (currentZoom >= 17 && currentZoom <= 20) {
          marker.addTo(map);
        } else {
          map.removeLayer(marker);
        }
      }

      toggleMarkerVisibility();
      
      marker.bindTooltip(label, { permanent: true, direction: 'top', offset: [0, -15] });
      map.setView(L.latLng(newCenter[1], newCenter[0]), 20, {
        animate: false,
      });
    } else {
      setOpenAlert(true); // Show alert when JSON file is not found
    }
  } else {
    setOpenAlert(true); // Show alert if searchFeatureData is null
  }
};

  const [openModel, setOpenModel] = useState(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [pinCoors, setPinCoors] = React.useState(); // State for input field value


 

  const handleOpenPinAlert = () => {
    setOpenPinAlert(true);
  };
 
  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false)
  }
  const handleOpen = () => {
    setOpenModel(true)
  }
  const handleClose = () => {
    setOpenModel(false)
  }

  const [scanPolyline, setScanPolyline] = useState([]);

  const toRadian = (degree) => {
    return degree*Math.PI/180;
}

  const getDistance = (origin, destination) => {
    // return distance in meters
    var lon1 = toRadian(origin[1]),
        lat1 = toRadian(origin[0]),
        
        lon2 = toRadian(destination[1]),
        lat2 = toRadian(destination[0]);

    var deltaLat = lat2 - lat1;
    var deltaLon = lon2 - lon1;

    var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    var EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS * 1000;
}

const clearMap = () => {
  for( var i in map._layers) {
      if(map._layers[i]._path != undefined) {
          try {
              map.removeLayer(map._layers[i]);
          }
          catch(e) {
              console.log("problem with " + e + map._layers[i]);
          }
      }
  }
}

  const handleSubmitScan = (data) => {
    var distances = [];
    var marker;
    
    clearMap()

    map.setView(data[0], 20)
    var polygon = L.polygon(data).addTo(map)
    setScanPolyline(preState=>{
      return [...preState, polygon]
    })




    var area = L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0])
    for (let index = 0; index < data.length - 1; ++index) {
      var polyline = L.polyline([data[index],data[index+1]], { className: 'my_polyline' }).addTo(map);
      setScanPolyline(preState=>{
        return [...preState, polyline]
      })
      var distance = Number(getDistance(data[index],data[index+1])).toFixed(2)
      distances.push(distance)
      
      polyline.bindTooltip((distance.toString() + ""),{permanent: true, direction:"center"}).openTooltip()
      }

      polygon.on('mouseover', function(e) {
        console.log('in');
        var text = "";
        for (let index = 0; index < distances.length; ++index) {
            text += "Cạnh " + (index + 1) + ": " + distances[index] + "m, "
        }
        text += " " + Number(area).toFixed(1) + "m²"///Diện tích:
        marker = new L.marker(data[0], { opacity: 0.00 }); //opacity may be set to zero
        marker.bindTooltip(text, {permanent: true, className: "leaflet-tooltip1", offset: [marker] });
        marker.addTo(map);
    });

    polygon.on('mouseout', function(e) {
            console.log('out');
            map.removeLayer(marker)
        });    

        // if (Number(area) > 300) {
          polygon.bindTooltip((Number(area).toFixed(1)).toString() + "m²",
   {permanent: true, direction:"center"}
  ).openTooltip()
  }

  

  const setTextSearchMap = (event) => {
    setToSearchMapText(event.target.value)
    Services.getSearchMaps(event.target.value).then(data=>{
      setListMapSearch(data.data.data)
    })
  }

  const closeSearchMap = () => {
    setOpenSearchMap(false)
  }


  const handlePinCoorsChange = (event) => {
    setPinCoors(event.target.value);
  };
  
  
  
  const handlePinMap = () => {
    const coordinateFormats = [
      /(\d+\.\d+)\s+(\d+\.\d+)/,       // Format: "10.783903988338556 106.72886329611488"
      /(\d+\.\d+)\s*,\s*(\d+\.\d+)/,    // Format: "10.783903988338556, 106.72886329611488"
      /(\d+,\d+)\s+(\d+,\d+)/,         // Format: "10,783903988338556 106,72886329611488"
      /(\d+,\d+)\s*,\s*(\d+,\d+)/      // Format: "10,783903988338556, 106,72886329611488"
    ];
  
    let latitude = null;
    let longitude = null;
  
    for (const format of coordinateFormats) {
      const match = pinCoors.match(format);
      if (match && match.length >= 3) {
        // If format has commas as decimals, replace with dots
        latitude = parseFloat(match[1].replace(',', '.'));
        longitude = parseFloat(match[2].replace(',', '.'));
        break; // Stop processing once a valid format is found
      }
    }
  
    if (latitude !== null && longitude !== null) {
      handleClose();
  
      const customIcon = L.icon({
        iconUrl: '../assets/icons/placeholder1.png',
        iconSize: [38, 38],
        iconAnchor: [18, 25],
        popupAnchor: [0, -20],
      });
  
      const coordinates = [latitude, longitude];
      const marker = L.marker(coordinates, { icon: customIcon }).addTo(map);
      marker.bindPopup('Vị trí ở đây!').openPopup();
  
      // Set the view to the new coordinates
      map.setView(coordinates, 20);
    } else {
      // If it's not valid coordinates, try to geocode the input as an address
      geocodeAddress(pinCoors, map);
    }
  };
  
  // Function to geocode an address using Nominatim Geocoding API
const geocodeAddress = (address, map) => {
  const addressQuery = encodeURIComponent(address);
  const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${addressQuery}`;

  fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      if (data.length > 0) {
        const result = data[0];
        const latitude = parseFloat(result.lat);
        const longitude = parseFloat(result.lon);
        const fullAddress = result.display_name; // Lấy địa chỉ từ dữ liệu trả về

        const customIcon = L.icon({
          iconUrl: '../assets/icons/placeholder1.png',
          iconSize: [38, 38],
          iconAnchor: [18, 25],
          popupAnchor: [0, -20],
        });

        const coordinates = [latitude, longitude];
        const marker = L.marker(coordinates, { icon: customIcon }).addTo(map);

        // Hiển thị địa chỉ trong popup
        marker.bindPopup(fullAddress).openPopup();

        // Set the view to the new coordinates
        map.setView(coordinates, 20);
      } else {
        console.error('Không tìm thấy địa chỉ.');
      }
    })
    .catch((error) => {
      console.error('Lỗi khi tìm kiếm địa chỉ:', error);
    });
};

  
  
  
  
  
  
  
  

  const customIcon = new L.Icon({
    iconUrl: "../assets/icons/pint.png",
    iconSize: new L.Point(15,15),
    maxZoom:"16",
    
  })
 
 

  const [isMarkerActive, setIsMarkerActive] = useState(false);
  const toggleMarker = () => {
    setIsMarkerActive(!isMarkerActive);
    handleCloseInfoTab();
  };

 


  const [openAlert, setOpenAlert] = useState(false)
  const [openPinAlert, setOpenPinAlert] = useState(false)

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleClosePinAlert = () => {
    setOpenPinAlert(false)
  }

 
 
   

  const handleCloseMarker = () => {
    props.setIsAddMarker(false)
    setOpenAddMarker(false)
  }

 
  const saveMarker = () => {
    Services.addMarker(this.state.markerName, new Date().getTime()/1000 + 30*24*60*60,this.state.markerDes,this.state.markerImageURL, this.state.markerYoutubeURL, this.state.markerDistrict,this.state.newMarkerCoords[1],this.state.newMarkerCoords[0],"Test").then(data=>{
      if (data.data.success){
        handleCloseMarker()
        props.setIsAddMarker(false)
        handleClickConfirmAlert()
      }
    })
  }

  const mapEvent = useMapEvents({
    click: (e) => {
      if (props.isAddMarker) {
        const { lat, lng } = e.latlng;
        setNewMarkerCoords([lat, lng])
        setOpenAddMarker(true)
      }
    },
 
  });

 
  const [openAddMarker, setOpenAddMarker] = useState(false);
  const [newMarkerCoords, setNewMarkerCoords] = useState([0,0]);
  
 
 
  
  
 

  const handleClickConfirmAlert = () => {
    setOpenConfirmAlert(true)
  };
 

  

  useEffect(() => {
    let timeoutId; // Biến để lưu ID của timeout

    // Kiểm tra nếu openAddMarker là true, sau đó đặt một timeout
    if (openAddMarker) {
      timeoutId = setTimeout(() => {
        setIsMarkerActive(false); // Tắt marker sau 2 giây
        handleCloseInfoTab();
      }, 20); // Timeout sau 2 giây
    }

    // Trả về một hàm xử lý cleanup để clear timeout khi component bị unmount hoặc openAddMarker thay đổi
    return () => {
      clearTimeout(timeoutId); // Clear timeout nếu component bị unmount hoặc openAddMarker thay đổi
    };
  }, [openAddMarker]); 


  const [open, setOpen] = useState(false);
  const [vipDialogOpen, setVipDialogOpen] = useState(false);
  const [isVip, setIsVip] = useState(false); // Giả định trạng thái VIP của người dùng

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
    setVipDialogOpen(false);
  };

  const handleSelectPlace = (placeData) => {
    // Thực hiện hành động với placeData nếu là VIP
    if (isVip) {
      console.log('Selected place:', placeData);
      handleClickOpen();
    } else {
      setVipDialogOpen(true);
    }
  };
  
  const upgradeToVip = () => {
    // Logic để nâng cấp người dùng lên VIP
    setIsVip(true);
    setVipDialogOpen(false);
    window.open("/THANH-toan1");
  };



 

  const handleButtonClick = () => {
    if (!props.isMarkerActive && localStorage.getItem("token") == null) {
      window.location.href = "/login";
    } else {
      handleCloseInfoTab();
      props.setIsAddMarker(!props.isAddMarker);
      toggleMarker();
    }
  };

  const handleOpenVipDialog = () => {
    setVipDialogOpen(true);
  };

  const handleCloseVipDialog = () => {
    setVipDialogOpen(false);
  };




 
  const handleClick = () => {
    // Giả sử currentSelectPlace là một đối tượng nào đó, bạn cần cập nhật nó khi nút được nhấn
    const selectedPlace = { /* thông tin chi tiết của địa điểm */ };
    setCurrentSelectPlace(selectedPlace);
  };

 


 
  return (




    



    
    <>
 

  
<div 
className="leaflet-control"
 style={{ 
  position: 'absolute',
  top:310,
  right:58,
 }}>
<LocateControl />
<Geoman/>
 </div>

 <div 
className="leaflet-control"
 style={{ 
  position: 'absolute',
  top:434,
  right:26,
 
 }}>

        <div>
      <button
        style={{
          height: "2.1rem",
          width: "2.1rem",
          borderRadius: "6px"
        }}
        onClick={handleClick}
        // onClick = {handleOpenVipDialog}
      >
        3D
      </button>
      
    </div>

    
 </div>
 


<div
  maxWidth="xl"
  className="leaflet-control"
  position="topleft"
  style={{...(window.innerWidth < 768 ? mobileStyle : desktopStyle),  width: "100%", padding:"10px",
 
    border: "none", top: 40,
  }}
>
  <div 
  style={{ 
 
    border: "none" ,
    margin: 'auto',
    height: 60,
    display: "flex",
    overflowX: 'auto', // Enable horizontal scrolling if spans exceed the width
    justifyContent: 'flex-start', // Align content to the left
  }}
  >
 


<span
className="span-container"
      title="Home"
      style={{
        cursor: "pointer",
        borderRadius: "20px",
       
        whiteSpace: "nowrap",
       
      }}
    >
        <span
  onClick={() => {
    map.setView(L.latLng(center[1], center[0]), center[2], {
      animate: false,
    });
  }}
  title="home"
  className="logo" 
  style={{
    width: "2.5rem",
    height: "2.5rem",
    display: "flex",
    borderRadius: "30px",
    background: `url(../assets/icons/logo512.png) no-repeat center center`,
    backgroundSize: "100%", // Đặt kích thước ảnh là 50% của kích thước của nút
    position: "relative",
    top:-7,
    margin: "2px",
  }}
></span>

    </span>



<button
      title="tìm tờ thửa"
      onClick={() => {
        if (currentSelectPlace) {
          handleCloseInfoTab();
        }
        handleOpenPinAlert(null);
      }}
      style={{
        cursor: "pointer",
        borderRadius: "20px",
        margin: "2px",
        padding: "5px 10px",
        fontSize: "0.8rem",
        height: "30px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        border: "1px solid SpringGreen",
        top:5
      }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
  </svg>
    &nbsp; 
      tờ - thửa
    </button>


 
    
    <button
      title="chọn quy hoạch"
      onClick={() => {
        if (currentSelectPlace) {
          handleCloseInfoTab();
        }
        setOpenSearchMap(true);
        setCurrentSelectPlace(null);
      }}
      style={{
        cursor: "pointer",
        borderRadius: "20px",
        margin: "2px",
        padding: "5px 10px",
        fontSize: "0.8rem",
        height: "30px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        border: "1px solid DodgerBlue",
        top:5
      }}
    > 
&nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.5.5 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103M10 1.91l-4-.8v12.98l4 .8zm1 12.98 4-.8V1.11l-4 .8zm-6-.8V1.11l-4 .8v12.98z"/>
</svg>&nbsp; 
      Chọn QH
      <br/>
    </button>



    <button
      title="quét sổ đỏ"
      onClick={() => {
        if (currentSelectPlace) {
          handleCloseInfoTab();
        }
        setOpenModel(true);
        setCurrentSelectPlace(null);
      }}


      
      //  onClick={() => {
      //   // handleButtonClick();
      //   handleOpenVipDialog();
      // }}
      style={{
        cursor: "pointer",
        borderRadius: "20px",
        margin: "2px",
        padding: "5px 10px",
        fontSize: "0.8rem",
        height: "30px",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        border: "1px solid SpringGreen",
        top:5
      }}
    >
       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-qr-code-scan" viewBox="0 0 16 16">
  <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z"/>
  <path d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z"/>
  <path d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z"/>
  <path d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z"/>
  <path d="M12 9h2V8h-2z"/>
</svg>
    &nbsp; 
      Quét sổ
    </button>

 

    <button
        title="Đăng tin BDS"
        // onClick={() => {
        //   // handleButtonClick();
        //   handleOpenVipDialog();
        // }}
        onClick={() => {
          if (!isMarkerActive && localStorage.getItem("token") == null) {
            window.location.href = "/login";
          }
          handleCloseInfoTab();
          props.setIsAddMarker(!props.isAddMarker);
          toggleMarker();
        }}
 
        style={{
          cursor: "pointer",
          borderRadius: "20px",
          margin: "2px",
          padding: "5px 10px",
          fontSize: "0.8rem",
          height: "30px",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          border: "1px solid Fuchsia",
          top: 5
        }}
      >  
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pin-map-fill" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8z"/>
          <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
        </svg>
        &nbsp; Đăng Tin
      </button>


 

 
<Dialog
        open={openPinAlert}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        style={{
          zIndex: 0,
          width: '110%',
          height: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'scroll',
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
 
         <DialogActions>
          <span onClick={handleClosePinAlert} style={{  top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} >❌</span> 



        </DialogActions>
        <DialogTitle id="alert-dialog-title" >
        <span style={{  fontSize: "1rem" ,top:10,fontWeight: "bold" }}  > nhập số tờ - số thửa</span>
        {openSearch && (
                         <div className={classes.searchControlContainer} ref={searchRef} >
                           <div className={classes.closeContainer} onClick={() => {
                               }}> 
                         
                         <InputGroup style={{ width: '100%' }}>
                    <Form.Control
                      style={{ color: 'blue', background: '#ffffff', fontSize: '0.8rem', width: '30%', height: '2.5rem',fontWeight: "bold" }}
                      className="leaflet-tooltip9"
                      type="number"
                      placeholder="Tờ"
                      InputProps={{
                        inputMode: 'numeric', type: 'number', 
                    }}
                      onChange={(event) => {
                        setToNumber(event.target.value);
                      }}
                    />

  <Form.Control
    style={{ color: 'blue', background: '#ffffff', fontSize: '0.8rem', width: '30%', height: '2.5rem',fontWeight: "bold" }}
    className="leaflet-tooltip9"
    type="number"
    InputProps={{
      inputMode: 'numeric', type: 'number', 
  }}
    placeholder="Thửa"
    onChange={(event) => {
      setThuaNumber(event.target.value);
    }}
  />





  <IconButton
    variant="primary"
    onClick={() => {
      searchInMapHandler(false);
      handleClosePinAlert();
        
    }}
    // onClick={() => {
    //   // handleButtonClick();
    //   handleOpenVipDialog();
    // }}
 style={{ color:"blue",  background:"blue", fontSize: "13px" }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
</svg>
 
  </IconButton>
  
</InputGroup>    <br/>


               
               
               
               </div>
                  {listSearch.map((item) => {
                    return (
                      <div
                        key={`${item.properties.TENCHU}_${item.properties.OBJECTID}`}
                      >
                        
                        <IconButton
                          variant={
                            `${currentSelectSearchItem.properties.TENCHU}_${currentSelectSearchItem.properties.OBJECTID}` ===
                            `${currentSelectSearchItem.properties.TENCHU}_${item.properties.OBJECTID}`
                              ? "success"
                              : "light"
                          }
               
                          onClick={() => {
                            handleClosePinAlert(false);
                            setCurrentSelectSearchitem(item);
                            const newCenter = item.geometry.coordinates;
                            const label = `<div style="text-align: center; font-weight: bold;">${toNumber}/${thuaNumber}</div>`;
 
                            const customDivIcon = L.divIcon({
                              permanent: true,background:"rgba(255, 166, 0, 0)",color:"none"
                            });
                            
                            const marker = L.marker([newCenter[1], newCenter[0]], { icon: customDivIcon }).addTo(map);
                          
                            marker.setZIndexOffset(1000);
                            
                            marker.bindTooltip(label, { permanent: true, direction: 'top', offset: [0, -15] ,background:"rgba(255, 166, 0, 0)"});
                            
                            map.setView(L.latLng(newCenter[1], newCenter[0]), 20, {
                              animate: false,
                            });
                            createMapboxPbfLayer({
                              searchValue: {
                                SHBANDO: toNumber,
                                SHTHUA: thuaNumber,
                              },
                            });
                          }}
                          style={{  border: '1px solid DodgerBlue',  color:"blue",  background:"#fff", color : "red" ,borderRadius:"30px", fontSize: "13px" }}
                        >
                          
                          {`Tờ: ${toNumber}, thửa : ${thuaNumber}`}
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
           


               )}
 
          {openControl2 && (
            <InputGroup>
        
                <Form.Control
                style={{   color : "blue" ,background : "#ffffff" , fontSize: "1rem" ,  width: 200,height: '3rem', fontSize: "0.8rem",fontWeight: "bold"}} 
                
                  type="text"
                  placeholder='nhập địa chỉ tọa độ'
                  value={pinCoors}
                  onChange={handlePinCoorsChange}
                />
                  <IconButton variant="primary"  
                  onClick={() => {
                    handlePinMap(true);
                    handleClosePinAlert(false);
                     
                  }}
      //                 onClick={() => {
      //   // handleButtonClick();
      //   handleOpenVipDialog();
      // }}
                   style={{ color:"blue",  background:"blue", fontSize: "13px" }}>
                  
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
</svg>
                </IconButton>
 
                
          </InputGroup> )}
          <br/>
          <div className="image-container" style={{
                position: "relative",
                backgroundImage: `url(https://guland.vn/bds/img/huong-dan-lay-toa-do.webp)`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "auto",
                height: "70vh",
                display: "flex",
                alignItems: "center",
                borderRadius:"5px",
                justifyContent: "center"
              }}>
                <div className="text-overlay" style={{ color: "#ffffff", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", opacity: "0.4" }}>
                  <h1>tcmap.vn</h1>
                </div>
              </div>
             
          
           
 

        </DialogTitle>

        
    

    
         
       
      </Dialog>
  </div>

</div>
 
 

 


  
<div style={{ position: 'relative', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center',borderRight:"10px" }}>
      <div 
        className="leaflet-control"
        style={{ 
          position: 'absolute',
          top: '75%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        
          {isMarkerActive ?  <span><h2  className="old-price"  style={{  borderRadius: '5px', height: '30px', fontSize: '1rem',borderRight:"10px"}}>chấm điểm trên bản đồ để đăng tin.</h2></span>  : ""}
      </div>
    </div>

     
                   
                   
      <MarkerForm 
        open={openAddMarker}
        handleClose={handleCloseMarker} 
        newMarkerCoords = {newMarkerCoords}
        handleClickConfirmAlert = {handleClickConfirmAlert}
      ></MarkerForm>
 
<PlaceInfoComponent
      currentSelectPlace={currentSelectPlace}
      handleResetSelect={handleCloseInfoTab}
    />



  

      <Snackbar open={openConfirmAlert} autoHideDuration={6000} onClose={handleCloseConfirmAlert}>
          <Alert onClose={handleCloseConfirmAlert} severity="success" sx={{ width: '100%' }}>
            điểm của bạn đã , thành công đợi trong giây lát 0799799993
          </Alert>
        </Snackbar>

 
 

      <ScanLocationModal  open={openModel} handleOpen={handleOpen} handleClose={handleClose} handleSubmit={handleSubmitScan} icon={customIcon}></ScanLocationModal>
      
      <SearchMapView listMapSearch={listMapSearch} mapSearch={toSearchMapText} open={openSearchMap} handleClose={closeSearchMap} handleMapSearchChange={setTextSearchMap} ></SearchMapView>

      <div>
     
      <Dialog
        open={vipDialogOpen}
        onClose={handleClose1}
        aria-labelledby="vip-dialog-title"
        aria-describedby="vip-dialog-description"
      >
            <span onClick={handleClose1} style={{  top: 0, right: "0", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"5px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", }} color="primary">
            ❌
          </span>
        <DialogTitle id="vip-dialog-title">
        
        </DialogTitle>
        <DialogContent>
          <p>Tính năng này cần nâng cấp lên tài khoản VIP để sử dụng</p>
          

      
          <div  onClick = {upgradeToVip}style={{
               
                backgroundImage: `url(../assets/icons/1000_F_529985028_eDc7RMZWRqT8knkwTz0RbeUgS9tlGYMM.webp)`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "2rem",
                
              }}>
          
              </div>
              <h1 onClick = {upgradeToVip} className="old-price" style={{ fontSize:"15px" }}>Nâng cấp VIP ngay</h1>

          

        </DialogContent>
   
        <DialogActions>
 
  

       


        </DialogActions>
      </Dialog>
    </div>
 


      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <DialogActions>
          {/* <span onClick={handleClose}>Disagree</span> */}
          <span onClick={handleCloseAlert} autoFocus  style={{ cursor: "pointer" , color : "red" ,background : "white" ,size: "10px" , borderRadius:"30px", margin: "3px", padding: "5px 10px", fontSize: "10px" }} >
          ❌
          </span>



        </DialogActions>
        <DialogTitle id="alert-dialog-title" >
          { <h1 style={{ cursor: "pointer" , color : "red"  ,size: "2em" , borderRadius:"30px", margin: "3px", fontSize: "1.5em" , left : "-9em" }} >THÔNG BÁO</h1>} 
          
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Không tìm thấy thửa đất này<br/>
             vui lòng nhập lại !
             
             <InputGroup style={{background : "rgba(238, 215, 5, 0.338)"}} onClick={()=> call ( )}>
             <Avatar onClick={()=> call ( )}  src="../assets/icons/call.png" style={{height: "1.5rem",  width: "1.5rem" }}  /> &nbsp;
              liên hệ : 0799799993
             </InputGroup>
             <br/>   
             <InputGroup style={{background : "rgba(238, 215, 5, 0.338)"}} onClick={()=> zalo ( )}>
             <Avatar onClick={()=> zalo ( )}  src="../assets/icons/zalo.png" style={{height: "2rem",  width: "2rem" }}  /> &nbsp;
             zalo : 0799799993 
             </InputGroup>
             <br/>   
             <InputGroup style={{background : "rgba(238, 215, 5, 0.338)" }} onClick={()=> telegram ( )}>
             <Avatar  onClick={()=> telegram ( )} src="../assets/icons/telegram.png" style={{height: "1.5rem",  width: "1.5rem" }} /> &nbsp;
             Tegagram : 0799799993 
             </InputGroup>
             <br/> Hỗ trợ bạn check Quy hoạch Miễn Phí.
          </DialogContentText>
        </DialogContent>
         
       
      </Dialog>
     
 
      {/* <MyComponent handleCurrentView={null} handleLongPress={handleLongPress}></MyComponent> */}
    </>
  );
}




const mapStateToProps = (state) => ({
  dataMapJson: state.mapBoxVectorTile.dataMapJson,
  searchFeatureData: state.mapBoxVectorTile.searchFeatureData,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MapBoxVectorTile);



