import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./NewsItem.css";
import { Carousel } from 'react-bootstrap';
 

 
const NewsItem = (props) => {
  const { title, img, shortDescription, id } = props;
  const convertTitle = (str) => {
    return str
      .replace(/\s/g, "-")
      .replace(/,/g, "")  // Remove commas
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D");
  };
  
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log('Is Intersecting:', entry.isIntersecting);
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect(); // Disconnect the observer after the first intersection
          }
        });
      },
      { threshold: 0.5, once: true } // Use the 'once' option
    );
  
    if (imageRef.current) {
      observer.observe(imageRef.current);
    }
  
    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []); // Empty dependency array
  

  
  
  return (
    <div className="news-item">
      <Link to={`/quy-hoach/${convertTitle(title) + "-" + id}`} className="link">
        
     
      {isVisible && (
           <div className="image-container" 
           ref={imageRef}
           style={{
             position: "relative",
             backgroundImage: `url(${img.split(",")[0]})`, // Take the first image URL
             backgroundSize: "contain",
             backgroundPosition: "center",
             backgroundRepeat: "no-repeat",
             width: "auto",
             height: "20vh",
             display: "flex",
             alignItems: "center",
             borderRadius: "5px",
             justifyContent: "center"
           }}
         >
             <div 
                      style={{
                        position: "relative",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "5px",
                            justifyContent: "center",
                            width: "5rem",  height: "5rem",
                              borderRadius: "30px",
                              backgroundImage: `url(../assets/icons/logo512.png)`, 
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              position: "relative" ,
                              opacity:"0.2"
                      }}
                    >    
                    </div>
            </div>
          )}

 
              <img
              ref={imageRef}
              style={{ width: "1%", height: "1%" }}
            />
          

        <h2 style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px', fontWeight: '600' }}>
          {title}
        </h2>
        <h2 style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px', fontWeight: '600' }}>
          {shortDescription}
        </h2>
      </Link>
    </div>
  );
};

export default NewsItem;
