import { useMapEvents } from "react-leaflet";
import L from "leaflet";

  const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7/dist/images/marker-shadow.png"
  });

export default function MyComponent({ openMarkerForm, isAddMarker, handleCurrentView }) {
    const map = useMapEvents({
      click: (e) => {
        if (isAddMarker) {
          const { lat, lng } = e.latlng;
          // L.marker([lat, lng], { icon }).addTo(map);
          openMarkerForm([lat, lng]);
        }
      },
      
    });
    return null;
}