import { Box, Button, Modal, Stack,IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import AdComponent from '../components/AdSenseAds';


const style = {
  position: 'absolute',
  top: {xs: "50%",md: '50%'},
  left: {xs: "50%",md: '50%'},
  transform: 'translate(-50%, -50%)',
  minWidth: {xs: "100%",md: '50%'},
  maxHeight:  {xs: "100%",md: '100%'},
  Height: {xs: "100%",md: '100%'},
  overflow: 'scroll',
  display: "block",
  border: 'none',
  borderRadius:"10px",
 
};

export default function SearchMapView2({ handleClose, open }) {

  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 100000,
  });


  const [cardinalDirection, setCardinalDirection] = useState('');
  const [cardinalDirection1, setCardinalDirection1] = useState('');
  const [cardinalDirection4, setCardinalDirection4] = useState('');

  const [locationInfo, setLocationInfo] = useState(null);
  useEffect(() => {
    if (coords) {
      locationHandler(coords);
    }
  }, [coords]);

  const locationHandler = async (coords) => {
    const { latitude, longitude } = coords;
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
      const address = data.display_name;
      setLocationInfo({ latitude, longitude, address });
    } catch (error) {
      console.log('Error fetching address information:', error);
    }
  };

  const [compassRotation, setCompassRotation] = useState(0);
  const [myLocationOpacity, setMyLocationOpacity] = useState(0);

  const startCompass = () => {
    if (window.DeviceOrientationEvent && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
      window.DeviceOrientationEvent.requestPermission()
        .then((response) => {
          if (response === 'granted') {
            window.addEventListener('deviceorientation', handleOrientation, true);
          } else {
            console.log('Permission denied for device orientation');
          }
        })
        .catch((error) => {
          console.log('Error requesting device orientation permission:', error);
        });
    } else {
      window.addEventListener('deviceorientationabsolute', handleOrientation, true);
    }
  };

  const handleOrientation = (event) => {
    const compass = event.webkitCompassHeading || Math.abs(event.alpha - 360);
    setCompassRotation(-compass);

    const direction = getCardinalDirection(compass);
    setCardinalDirection(direction);


    const direction1 = getCardinalDirection1(compass);
    setCardinalDirection1(direction1);

    const direction4 = getCardinalDirection4(compass);
    setCardinalDirection4(direction4);

  
  };

  const getCardinalDirection = (compass) => {
    if (compass >= 337.5 || compass < 22.5) {
      return 'Bắc';
    } else if (compass >= 22.5 && compass < 67.5) {
      return 'Đông Bắc';
    } else if (compass >= 67.5 && compass < 112.5) {
      return 'Đông';
    } else if (compass >= 112.5 && compass < 157.5) {
      return 'Đông Nam';
    } else if (compass >= 157.5 && compass < 202.5) {
      return 'Nam';
    } else if (compass >= 202.5 && compass < 247.5) {
      return 'Tây Nam';
    } else if (compass >= 247.5 && compass < 292.5) {
      return 'Tây';
    } else if (compass >= 292.5 && compass < 337.5) {
      return 'Tây Bắc';
    } else {
      return '';
    }
  };

  const getCardinalDirection4 = (compass) => {
    // Chuyển compass thành giá trị trong khoảng [0, 360)
    compass = (compass % 360 + 360) % 360;
  
    // Làm tròn giá trị để loại bỏ số lẻ
    compass = Math.floor(compass);
  
    return compass; // Trả về giá trị xoay chính xác, đã làm tròn
  };
  
  
  


  const getCardinalDirection1 = (compass) => {
    if (compass >= 337.5 || compass < 22.5) {
      return "Cung trạch Khảm:\n\
              Trạch Khảm là những người sinh năm:\n\
              – Nam giới: 1936, 1945, 1954, 1963, 1972, 1981, 1990, 1999, 2008, 2017…\n\
              – Nữ giới: 1932, 1941, 1950, 1959, 1968, 1977, 1986,1995, 2004, 2013…\n\
              Ý nghĩa ứng với người mệnh hướng Khảm:\n\
              Đông Nam: Đông con cháu\n\
              Chính Nam: Làm ăn phát đạt\n\
              Tây Nam: Tuyệt mệnh\n\
              Chính Đông: Đông con cháu\n\
              Chính Tây: Họa hại\n\
              Đông Bắc: Ngũ quỷ\n\
              Chính Bắc: Phục vị\n\
              Tây Bắc: Lục sát";
    }
     else if (compass >= 22.5 && compass < 67.5) {
      return 'người thuộc cung Cấn trạch\n\   Cấn trạch là những người sinh vào các năm\n\   – Nam giới: 1938, 1947, 1956, 1965,1974,1983, 1992, 2001, 2010…\n\– Nữ giới: 1936, 1945, 1948, 1954, 1957, 1966, 1972, 1981,1990, 1993, 1999, 2002, 2008, 2011…\n\Ý nghĩa ứng với người mệnh hướng Cấn\n\   + Đông Nam: Tuyệt mệnh\n\+ Chính Nam: Họa hại\n\+ Tây Nam: Nhà giàu có\n\+ Chính Đông: Lục sát\n\+ Chính Tây: Con cháu hưng vượng\n\+ Đông Bắc: Phục vị\n\+ Chính Bắc: Ngũ quỷ\n\+ Tây Bắc: Nhà giàu có';
    } else if (compass >= 67.5 && compass < 112.5) {
      return 'Nếu bạn là trạch Chấn\n\   Trạch Chấn sinh vào các năm\n\   – Nam giới và nữ giới: 1934, 1943, 1952, 1961, 1970, 1979, 1988, 1997, 2006, 2015….\n\Ý nghĩa ứng với người mệnh hướng Chấn\n\   + Đông Nam: Thăng quan, đông con cháu\n\+ Chính Nam: Sự nghiệp thăng tiến\n\+ Tây Nam: Họa hại\n\+ Chính Đông: Phục vị\n\+ Chính Tây: Tuyệt mệnh\n\+ Đông Bắc: Lục sát\n\+ Chính Bắc: Sự nghiệp thăng tiến\n\+ Tây Bắc: Ngũ quỷ';
    } else if (compass >= 112.5 && compass < 157.5) {
      return 'Cung trạch Tốn\n\   Trạch Tốn là những người sinh năm\n\   – Nam giới: 1933, 1942, 1951, 1960, 1969, 1978, 1987, 1996, 2005, 2014…\n\– Nữ giới: 1935, 1944, 1953, 1962, 1971, 1980, 1989, 1989, 1998, 2007, 2016…\n\Ý nghĩa ứng với người mệnh hướng Tốn\n\   + Đông Nam: Phục vị\n\+ Chính Nam: Giàu sang\n\+ Tây Nam: Ngũ quỷ\n\+ Chính Đông: Phát văn chương\n\+ Chính Tây: Lục sát\n\+ Đông Bắc: Tuyệt mệnh\n\+ Chính Bắc: Giàu sang\n\+ Tây Bắc: Họa hại';
    } else if (compass >= 157.5 && compass < 202.5) {
      return 'Cung trạch Ly\n\   Trạch Ly là những người sinh năm sau\n\   – Nam giới: 1937, 1946, 1955, 1964, 1973, 1982, 1991, 2000, 2009, 2018…\n\– Nữ giới: 1931, 1940, 1949, 1955, 1964, 1973, 1982, 1991, 2000,2009, 2018…\n\Ý nghĩa ứng với người mệnh hướng Ly\n\   + Đông Nam: Con hiền tài\n\+ Chính Nam: Phục vị\n\+ Tây Nam: Lục sát\n\+ Chính Đông: Con hiền tài\n\+ Chính Tây: Ngũ quỷ\n\+ Đông Bắc: Họa hại\n\+ Chính Bắc: Tích tụ tiền tài\n\+ Tây Bắc: Tuyệt mệnh';
    } else if (compass >= 202.5 && compass < 247.5) {
      return 'người cung Khôn trạch\n\   Khôn trạch là những người sinh vào các năm\n\   – Nam giới: 1932, 1935, 1941, 1944, 1950, 1953, 1959, 1962, 1968, 1971, 1977, 1980, 1986, 1995, 1998, 2004, 2007, 2013…\n\– Nữ giới: 1933, 1942, 1951, 1960, 1969, 1978, 1987, 1996, 2005, 2014…\n\Ý nghĩa ứng với người mệnh hướng Khôn\n\   + Đông Nam: Ngũ quỷ\n\+ Chính Nam: Lục sát\n\+ Tây Nam: Phục vị\n\+ Chính Đông: Họa hại\n\+ Chính Tây: Có thăng tiến\n\+ Đông Bắc: Có thăng tiến\n\+ Chính Bắc: Tuyệt mệnh\n\+ Tây Bắc: Vợ chồng hòa hợp';
    } else if (compass >= 247.5 && compass < 292.5) {
      return 'Cung mệnh Đoài trạch\n\   Đoài trạch là những người có tuổi sau\n\   – Nam giới: 1939, 1948, 1957, 1966,1975,1984, 1993, 2002, 2011…\n\– Nữ giới: 1038, 1947, 1956,1974, 1983, 1992, 2001, 2010….\n\Ý nghĩa ứng với người mệnh hướng Đoài trạch\n\   + Đông Nam: Lục sát\n\+ Chính Nam: Ngũ quỷ\n\+ Tây Nam: Phát huy vinh quang của tổ tiên\n\+ Chính Đông: Tuyệt mệnh\n\+ Chính Tây: Phục vị\n\+ Đông Bắc: Gia đạo hưng vượng, phát đạt\n\+ Tây Bắc: Phát huy vinh quang của tổ tiên';
    } else if (compass >= 292.5 && compass < 337.5) {
      return 'người cung Càn trạch\n\   Càn trạch là những người sinh vào các năm\n\   – Nam giới: 1931, 1940, 1949, 1958, 1967, 1976, 1985, 1994, 2003, 2012…\n\– Nữ giới: 1937, 1946, 1955, 1973, 1982, 1991, 2000, 2009, 2018…\n\Ý nghĩa ứng với người mệnh hướng Càn\n\   + Đông Nam: Họa hại\n\+ Chính Nam: Tuyệt mệnh\n\+ Tây Nam: Phú quý\n\+ Chính Đông: Ngũ quỷ\n\+ Chính Tây: Sang trọng\n\+ Đông Bắc: Sang trọng\n\+ Chính Bắc: Lục sát\n\+ Tây Bắc: Phục vị';
    } else {
      return '';
    }
  };
  

  return (
<Modal   BackdropProps={{ style: { backgroundColor: "transparent" } }} open={open} onClose={handleClose} style={{  overflow: "auto", zIndex: 9999999999,overflowX: "auto", overflowY: "scroll", maxHeight: "1000px" }} className="old-price">

<Box >


<Box sx={style} style={{ zIndex: 9999999999, overflowY: "scroll", overflowX: "hidden" }}>
<Box  onClick={handleClose}  style={{ height: "3rem", background:"none", zIndex: 9999,width:"100%"}}> 
<IconButton        style={{  top: 5, right:5, cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"30px",  padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", zIndex:  9999999 }} >❌</IconButton> 
</Box>

<div style={{   fontSize: '0.5rem',overflow: "auto", overflowX: "auto", overflowY: "hidden" }} > 
        {cardinalDirection4 && (
            <div  style={{   fontSize: '0.8rem', position: 'absolute', top: '10%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>{cardinalDirection4}</div>
          )}
        </div>
        <br />       <br /> 
       
      
        <br /><br /> 
        
        

        <Stack className="compass" >
       
          <div className="arrow" />
          <div
            className="compass-circle"
            style={{
              top: '50%',
              left: '50%',
              transform: `translate(-50%, -50%) rotate(${compassRotation}deg)`,
            }}
          />

        <div  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          {cardinalDirection && (
            <div  style={{   fontSize: '0.8rem', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>{cardinalDirection}</div>
          )}
        </div>


          
          <br/>
           
            <div
              className="my-point"
              style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                opacity: myLocationOpacity,
              }}
            />


          <Box
            className="compass-circle"
            style={{ top: '50%',with:"100%",
            left: '50%', transform: `translate(-50%, -50%) rotate(${compassRotation}deg)` }}
          />
            <br/>  
           <Button
              onClick={startCompass}
              style={{
                color: 'red',
                background: '#fcb332',
                fontSize: '20px',
                
                bottom: -300,
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width:"100%",
                height:50,fontweight: "bold",
              }}
            >
          Bắt đầu
        </Button>

        </Stack>

        <br/>   <br/>  
       
        <br />       <br /> 
    
        {cardinalDirection && (
          <div style={{   fontSize: '0.7rem', top: '105px', whiteSpace: 'pre-line' }}>
            {cardinalDirection}
          </div>
        )}

        {cardinalDirection1 && (
          <div style={{   fontSize: '0.7rem', top: '105px', whiteSpace: 'pre-line' ,fontweight: "bold", }}>
            {cardinalDirection1}
          </div>
        )}

        {locationInfo && (
          <div style={{   fontSize: '10px', textAlign: 'center' ,fontweight: "bold", }}>
            {locationInfo.address}
          </div>
        )}
    <AdComponent/>
</Box>


       
      </Box>
    </Modal>
  );
}

