import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css';
import { useMap } from 'react-leaflet';
 

export default function LocateControlWithButton() {

  const [isActive, setIsActive] = useState(false);
  const map = useMap();
  const locateControlRef = useRef(null);
  const [isLocating, setIsLocating] = useState(false);
  const [locateStarted, setLocateStarted] = useState(false);

  useEffect(() => {
    const locateOptions = {
      strings: {
        title: "Bạn có muốn sử dụng vị trí hiện tại có quy hoạch?"
      },
      drawCircle: false,
      showPopup: false,
      setView: 'untilPan',
      flyTo: true,
      locateOptions: {
        enableHighAccuracy: true,
        maxZoom: 17
      },
      icon: 'fa fa-location-crosshairs',
    };

    const locateControl = L.control.locate(locateOptions).addTo(map).setPosition('bottomright');
    locateControlRef.current = locateControl;

    map.on('locationfound', () => {
      setLocateStarted(true);
    });

    return () => {
      map.removeControl(locateControlRef.current);
      map.off('locationfound');
    };
  }, [map]);

  const handleLocateButtonClick = () => {
    setIsClicked(!isClicked); // T
    if (!locateStarted) {
      locateControlRef.current.start();
      setIsLocating(true);
      setLocateStarted(true);
    } else {
      locateControlRef.current.stop();
      setIsLocating(false);
      setLocateStarted(false); 
      setIsActive(true); // Đặt trạng thái là true (bật) khi click
      if (localStorage.getItem("token") === null) {
        window.location.href = "/login";  
      } else {
      }
    }
  };

  const [isClicked, setIsClicked] = useState(false);
  return (
    <>
   <div 
     title= "Bạn có muốn sử dụng vị trí hiện tại có quy hoạch?"
      style={{ 
        position: 'absolute', 
        borderRadius: "5px", 
        height: "2rem", 
        width: "2rem", 
        background: isClicked ? "#007bff" : "#fff", 
        cursor: 'pointer',
        transition: 'background-color 0.3s', // Thêm transition cho hiệu ứng màu
      }}
      onClick={handleLocateButtonClick}
      onMouseDown={(e) => e.currentTarget.style.transform = 'scale(0.9)'} // Scale down khi click
      onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'} // Scale back to normal khi nhả chuột
    >
      

      <div class="edit-post">
  {/* <span class="edit-tooltip">Định vị</span> */}
  <svg 
        title= "Bạn có muốn sử dụng vị trí hiện tại có quy hoạch?"
        xmlns="http://www.w3.org/2000/svg" 
        width="25" 
        height="25" 
        fill={isClicked ? "#fff" : "black"} // Fill màu trắng khi active, màu xanh khi không active
        viewBox="0 0 16 16"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7 7 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7 7 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7 7 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7 7 0 0 0 8.5 1.018zm-6.48 7A6 6 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6 6 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6 6 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6 6 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1zM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
      </svg>
</div>


   
      
    </div>



 
    </>
  );
}
