import React, { useEffect, useRef, memo } from "react";
import { FeatureGroup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";

 function DrawTool() {
  const map = useMap();
  const refFeature = useRef();

  function createAreaTooltip(layer) {
    if (layer.areaTooltip) {
      return;
    }

    layer.areaTooltip = L.tooltip({
      permanent: true,
      direction: "center",
      className: "leaflet-tooltip",
    });

    layer.on("remove", function (event) {
      layer.areaTooltip.remove();
    });

    layer.on("add", function (event) {
      updateAreaTooltip(layer);
      layer.areaTooltip.addTo(map);
    });

    layer.on("edit", () => {
      updateAreaTooltip(layer);
    });

    if (map.hasLayer(layer)) {
      updateAreaTooltip(layer);
      layer.areaTooltip.addTo(map);
    }
  }

  function updateAreaTooltip(layer) {
    var content = "";
    var latlng = null;
    if (layer instanceof L.Polygon) {
      var area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
      content = `Diện tích: ${L.GeometryUtil.readableArea(area, true)}`;
      latlng = layer.getCenter();
    } else if (layer instanceof L.Circle) {
      latlng = layer._latlng;
      content = `Bán kính: ${layer._mRadius.toFixed(2)} m`;
    }

    layer.areaTooltip.setContent(content).setLatLng(latlng);
  }

  function createTooltipForPolyline(layer) {
    let _latlngs;
    if (layer instanceof L.Polygon) {
      _latlngs = [...layer?._latlngs[0]]
      _latlngs.push(_latlngs[0]);
    } else if (layer instanceof L.Polyline) {
      _latlngs = [...layer?._latlngs];
    }

    if (layer.areaTooltipEdgeLine && _latlngs?.length && _latlngs?.length < 2) {
      return;
    }

    const arr = [];
    for (let i = 0; i < _latlngs.length - 1; i++) {
      arr.push(
        L.tooltip({
          permanent: true,
          direction: "center",
          className: "leaflet-tooltip"
          
        })
      );
    }

    layer.areaTooltipEdgeLine = [...arr];

    layer.on("remove", function (event) {
      layer.areaTooltipEdgeLine.forEach((item) => item.remove());
    });

    layer.on("add", function (event) {
      updateTooltipForPolyline(layer);
      layer.areaTooltipEdgeLine.forEach((item) => item.addTo(map));
    });

    layer.on("edit", () => {
      updateTooltipForPolyline(layer);
    });

    if (map.hasLayer(layer)) {
      updateTooltipForPolyline(layer);
      layer.areaTooltipEdgeLine.forEach((item) => item.addTo(map));
    }
  }

  function updateTooltipForPolyline(layer) {
    var content = "";

    let _latlngs;
    if (layer instanceof L.Polygon) {
      _latlngs = [...layer?._latlngs[0]]
      _latlngs.push(_latlngs[0]);
    } else if (layer instanceof L.Polyline) {
      _latlngs = [...layer?._latlngs];
    }

    for (let i = 1; i < _latlngs.length; i++) {
      const distance = _latlngs[i - 1].distanceTo(_latlngs[i]);
      content = `${distance.toFixed(2)} m`;
      const bounds = L.latLngBounds(_latlngs[i - 1], _latlngs[i]);
      layer.areaTooltipEdgeLine[i - 1]
        .setContent(content)
        .setLatLng(bounds.getCenter());
    }
  }

  return (
    <FeatureGroup ref={refFeature}>
      <EditControl
        position="topright"
        onEdited={(event) => {
          event.layers.getLayers().forEach(function (layer) {
            if (layer instanceof L.Polygon || layer instanceof L.Circle) {
              updateAreaTooltip(layer);
            }
            if (layer instanceof L.Polyline) {
              updateTooltipForPolyline(layer);
            }
          });
        }}
        onCreated={(event) => {
          var layer = event.layer;
          if (layer instanceof L.Polyline) {
            createTooltipForPolyline(layer);
          }
          if (layer instanceof L.Polygon || layer instanceof L.Circle) {
            createAreaTooltip(layer);
          }

          refFeature.current.addLayer(layer);
        }}
        onDeleted={() => {}}
				draw={{
					polyline: {
            //  icon: L.divIcon({
            //  iconSize: null,
            //  html: `<img src="./assets/icons/placeholder.png" style="width: 38px"/>`,
            //  className: "placeholder-marker",
						// }),
						shapeOptions: {
							guidelineDistance: 10,
							color: "#0BFB06",
							weight: 3
						}
					},
          polygon: {
          //   icon: L.divIcon({
          //   iconSize: null,
          //   html: `<img src="./assets/icons/placeholder.png" style="width: 38px"/>`,
          //   className: "placeholder-marker",
          //  }),
           shapeOptions: {
             guidelineDistance: 5,
             color: "#0BFB06",
             weight: 3
           }
         },
          
					rectangle: false,
          marker: false,
					circlemarker: false,
					circle: false
					
				}}
			/>
    </FeatureGroup>
  );
}
export default memo(DrawTool)






