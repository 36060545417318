import { SET_DATA_MAP_JSON, SET_SEARCH_FEATURE_DATA } from "./../../state/actions/mapBoxVectorTile";
const INITIAL_STATE = {
  currentSelectPlace: null,
  dataMapJson: null,
  searchFeatureData: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DATA_MAP_JSON:
      return {
        ...state,
        dataMapJson: action.payload
      };
    case SET_SEARCH_FEATURE_DATA:
      return {
        ...state,
        searchFeatureData: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
