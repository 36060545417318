import { useEffect, useState ,React} from 'react';
import { Services } from '../APIs/Services';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {InputGroup, Carousel ,FormControl } from 'react-bootstrap';
import { Box, CardMedia, Button, TextField, Modal, Stack, IconButton,  Divider, Snackbar, Alert ,Avatar ,AppBar,Toolbar} from '@mui/material';
import { Navigate ,Link ,useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
 

const style = {
  position: 'absolute',
  top: {xs: "50%",md: '50%'},
  left: {xs: "50%",md: '50%'},
  transform: 'translate(-50%, -50%)',
  minWidth: {xs: "100%",md: '50%'},
  maxHeight:  {xs: "100%",md: '100%'},
  Height: {xs: "100%",md: '100%'},
  overflow: 'scroll',
  display: "block",
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius:"10px",
 
};

const tabBarStyle = {
  height:"3rem",
  position: 'fixed',
  bottom: 0,
  width: '100%', // Default width for mobile
  zIndex: 1000,
  left: '50%', // Center horizontally on mobile
  transform: 'translateX(-50%)', // Center horizontally on mobile
};

// Override styles for desktop
tabBarStyle['@media screen and (min-width: 768px)'] = {
  width: '50%', // 50% width on desktop
  left: '50%', // Center horizontally on desktop
  transform: 'translateX(-50%)', // Center horizontally on desktop
};
 
 
const modalStyle4 = {
  background: 'none',
  border: "1px solid Yellow",
  width: "100%", // Default width for mobile
};

 

  function shareMarkerAndroid(url) {
    if (window.NativeAndroid) {
        window.NativeAndroid.share(url);
    }
  }
 


const DialogMarker = ({ marker, onClose }) => {
    const imageUrls = marker.image_url.split(',');


    // const copyPrompt2 = () => {
    //   window.open("https://www.google.com/maps/dir/?api=1&destination=" + marker.lag + "," + marker.lng + "&travelmode=driving");
    //   navigator.clipboard.writeText('AAAAAAAAA');
    // };

    const copyPrompt2 = (id) => {
      const url = new URL(window.location.href).origin + "?marker=" + marker.id;
      navigator.clipboard.writeText(url)
        .then(() => {
          console.log('Đã sao chép vào clipboard');
          // Hiển thị thông báo cho người dùng
          // alert('Đã sao chép ');
          
          // Thực hiện các hành động tiếp theo tại đây, ví dụ: mở tab mới
          window.location.href = url;
          handleOpenAlert();
        })
        .catch((error) => {
          console.error('Lỗi khi sao chép vào clipboard:', error);
        });
    };
    


 

    const [button1Playing, setButton1Playing] = useState(false);
    const [button2Playing, setButton2Playing] = useState(false);
    
    const handleButtonClick = (button) => {
        if (button === "button1") {
          setButton1Playing(true);
          setButton2Playing(false);
        } else if (button === "button2") {
          setButton1Playing(false);
          setButton2Playing(true);
        }
      };


    const [comments, setComments] = useState([])
    const [comment, setComment] = useState("")
    const [openAlert, setOpenAlert] = useState(false)

    const handleCloseAlert = () => {
        setOpenAlert(false)
    }
    const handleOpenAlert = () => {
        setOpenAlert(true)
    }

    useEffect(()=>{
        Services.getComments(marker.id).then((data)=>{
            setComments(data.data.data)
        })
    },[marker.id])

    const handleCommentChange = (event) => {
        setComment(event.target.value)
    }

    const handleAddComment = () => {
        if (comment.trim().length > 0) {
            Services.addComment(marker.id, comment).then((data)=> {
                setComment("")
                Services.getComments(marker.id).then((data)=>{
                    setComments(data.data.data)
                })
            })
        }
    }

    // const handleCopyToClipboard = async (id) => {
    //     try {
         
    //         var url = new URL(window.location.href).origin + "?marker=" + id;
    //         Android.shareFromWeb(url);
      
        
    
    //         if (navigator.share) {
    //           await navigator.share({
    //               title: `${marker.name}`,
    //               text: `${marker.description}`,
    //               url: url,
    //           });
    //         } else {
    //             console.log("Web Share API not supported. Implement custom sharing logic.");
    //         }
    
    //         handleOpenAlert();
    //     } catch (error) {
    //         console.error("Error sharing marker:", error);
    //     }
    // };

  //   const handleCopyToClipboard = (id) => {
  //     var url = new URL(window.location.href).origin + "?marker=" + id
  //     navigator.clipboard.writeText(url)
  //     copyMakerAndroid(url)
  //     handleOpenAlert()
  // }

  const handleCopyToClipboard = (id) => {
    const url = new URL(window.location.href).origin + "?marker=" + marker.id;
    navigator.clipboard.writeText(url)
      .then(() => {
        console.log('Đã sao chép vào clipboard');
        // Hiển thị thông báo cho người dùng
        alert('Đã sao chép ');
        
        // Thực hiện các hành động tiếp theo tại đây, ví dụ: mở tab mới
        window.open(url, "_blank"); // Mở URL trong tab mới
        handleOpenAlert();
      })
      .catch((error) => {
        console.error('Lỗi khi sao chép vào clipboard:', error);
      });
  };

    
    

    
    const handleButtonClick1 = () => {
      const phoneNumberRegex = /\d{10}/;
      const currentMarkerDescription = marker.description;
  
      if (phoneNumberRegex.test(currentMarkerDescription)) {
        const phoneNumber = currentMarkerDescription.match(phoneNumberRegex)[0];
        window.location.href = `tel:${phoneNumber}`;
      } else {
        console.log('Số điện thoại không hợp lệ');
      }
    };



    const handleButtonClick2 = () => {
      const phoneNumberRegex = /\d{10}/;
      const currentMarkerDescription = marker.description;
  
      if (phoneNumberRegex.test(currentMarkerDescription)) {
        const phoneNumber = currentMarkerDescription.match(phoneNumberRegex)[0];
        window.open(`https://zalo.me/${phoneNumber}`);
      } else {
        console.log('Số điện thoại không hợp lệ');
      }
    };

    
    const handleCopyToClipboard1 = (id) => {
      const url = new URL(window.location.href).origin + "?marker=" + marker.id;
      navigator.clipboard.writeText(url)
        .then(() => {
          console.log('Đã sao chép vào clipboard');
          // Hiển thị thông báo cho người dùng
          alert('Đã sao chép ');
          
          // Thực hiện các hành động tiếp theo tại đây, ví dụ: mở tab mới
          window.open(url, "_blank"); // Mở URL trong tab mới
          handleOpenAlert();
        })
        .catch((error) => {
          console.error('Lỗi khi sao chép vào clipboard:', error);
        });
    };
  
    




      const phoneRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/g;
  const gmailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;

  // Function to replace matched phone numbers and Gmail addresses with placeholders
  const hideSensitiveInfo = (description) => {
    return description
      .replace(phoneRegex, ' ')
      .replace(gmailRegex, ' ');
  };


  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login'); // Replace '/login' with the actual path to your login page
  };




    const [expandedComments, setExpandedComments] = useState([]);

  const handleReadMoreClick = (index) => {
    setExpandedComments((prevExpandedComments) => {
      const newExpandedComments = [...prevExpandedComments];
      newExpandedComments[index] = true;
      return newExpandedComments;
    });
  };



  const [zoomedUrl, setZoomedUrl] = useState(null);

  const handleZoom = (imageUrl) => {
      setZoomedUrl(imageUrl);
  };

  const handleCloseZoom = () => {
      setZoomedUrl(null);
  };


  const [selectedImage, setSelectedImage] = useState(0); // Chọn ảnh đầu tiên ban đầu

  const handleThumbnailClick = (index) => {
      setSelectedImage(index);
  };



  return (

    <Modal  open={Boolean(marker)} onClose={onClose}  CBackdropProps={{ style: { backgroundColor: "transparent" } }} >
 
    <Box open={Boolean(marker)} onClose={onClose} CBackdropProps={{ style: { backgroundColor: "transparent" } }} >


      


       
          <Box  sx={style}  > 
          <div></div>
          <Box  onClick={onClose}  style={{ height: "3rem", background:"none", zIndex: 9999,width:"100%"}}> 
          <IconButton        style={{  top: 5, right:5, cursor: "pointer" , color : "red" ,background : "#ffff" ,size: "13px" ,position : "absolute",borderRadius:"30px",  padding: "5px 10px", fontSize: "small", border: "1px solid DodgerBlue", zIndex:  9999999 }} >❌</IconButton> 
          </Box>
           
       
          <Stack style={{ zIndex: 9999999999, overflow: "auto", maxHeight: "1000px", overflowX: "hidden", overflowY: "hidden" }}   direction="column"  >

<AppBar position="sticky" style={{display: 'flex', justifyContent: 'center' ,background:"none" ,boxShadow: 'none', }} >



 
</AppBar>




                 <Carousel activeIndex={selectedImage}>
                  {imageUrls.map((imageUrl, index) => (
                      <Carousel.Item aspectRatio={16 / 9} key={index}>
                          <div
                              className="image-container"
                              style={{
                                  position: "relative",
                                  backgroundImage: `url(${imageUrl.trim()})`,
                                  backgroundSize: "contain",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  width: "auto",
                                  height: "50vh",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer"
                              }}
                              onClick={() => handleZoom(imageUrl)}
                          >
                              <div className="text-overlay" style={{ color: "#ffffff", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", opacity: "0.4" }}>
                                  <h1>tcmap.vn</h1>
                              </div>
                          </div>
                      </Carousel.Item>
                  ))}
              </Carousel> <br/>

              <Toolbar style={{ display: 'flex', justifyContent: 'center', background: "none", border: "none", margin: "auto", width: 350 }}>
              <div className="thumbnail-container" style={{ overflowX: "auto", whiteSpace: "nowrap" ,
                         margin: 'auto',
                         height: 70,
                         overflowX: 'auto', // Enable horizontal scrolling if buttons exceed the width
                         justifyContent: 'flex-start', 
                    }}> 
                        {imageUrls.map((imageUrl, index) => (
                            <img
                                key={index}
                                style={{
                                    borderRadius: "5px",
                                    width: "5rem",
                                    height: "3rem",
                                    cursor: "pointer",
                                    border: index === selectedImage ? "5px solid #fcb332" : "none",
                                    marginRight: "0.3rem",
                                    display: "inline-block",
                                    objectFit: "cover" // Maintain aspect ratio
                                }}
                                src={imageUrl}
                                alt={`Thumbnail ${index + 1}`}
                                onClick={() => handleThumbnailClick(index)}
                            />
                        ))}
                       
                    </div> 
                </Toolbar>



              {zoomedUrl && (
                  <div className="zoomed-container" onClick={handleCloseZoom}>
                      <div className="image-container" style={{ position: "relative", zIndex: 999999999999999999, backgroundImage: `url(${zoomedUrl})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat", cursor: "pointer" }} onClick={() => handleZoom(zoomedUrl)}>
                          <div className="text-overlay" style={{ color: "#ffffff", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", opacity: "0.4" }}>
                              <h1>tcmap.vn</h1>
                          </div>
                      </div>
                  </div>
              )}



  
  
  <Stack   style={{ cursor: "pointer"  ,size: "10px" ,position : "relative",borderRadius:"10px", padding: "5px 10px", fontSize: "13px", fontWeight: "bold", color : "black"}}>
 
      


{button1Playing && (
<iframe
src={ ("https://www.youtube.com/embed/"  +  marker.youtube_url)  }
    style={{
    borderRadius: "10px",
    width: "100%",
    height: "50vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    }}
    frameBorder="0"
    allow="autoplay; encrypted-media"
    allowFullScreen
    title="video1"
    borderRadius="20px"
    height="250"
/>
)}

{button2Playing && (

<iframe
class="tiktok-embed"
data-video-id="7278540119441689863"
    src={ ("https://www.tiktok.com/embed/"  +  marker.youtube_url +"?autoplay=1" )  }
    style={{
    borderRadius: "10px",
    width: "100%",
    height: "70vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    }}
    frameBorder="0"
    allow="autoplay; encrypted-media"
    allowFullScreen
    title="video2"
    borderRadius="20px"
    height="250"
/>
)}





<AppBar position="sticky" style={{ width: 350, bottom: '5%', margin: 'auto', zIndex: 99999999999999, background: "none", border: "none", boxShadow: 'none', color: "black" }}>
    <Toolbar style={{ display: 'flex', justifyContent: 'center', background: "none", border: "none" }}>


<BottomNavigation showLabels
sx={{ 
...modalStyle4, 
width: [`100%`, `100%`],
border: "none",
bottom: -10,
display: 'grid',
gridTemplateColumns: 'repeat(5, 1fr)', // Chia ra 5 cột có kích thước bằng nhau
alignItems: 'center', // Căn giữa các nút theo chiều dọc
}} >


{/* <BottomNavigationAction
style={{ borderRadius:"20px" }}
className={`codepro-custom-btn codepro-btn-58 play-button ${button2Playing ? "active" : ""}`}
onClick={() => handleButtonClick("button2")}
label='Tiktok'
icon={

<FaTiktok  style={{ height: '3rem', width: '3rem', fontSize:"20px", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)" }}/>


}
/> */}


<BottomNavigationAction
onClick={handleButtonClick2}
className={`codepro-custom-btn codepro-btn-58 play-button ${button2Playing ? "active" : ""}`}
// onClick={() => handleButtonClick("button2")}

// className={`play-button ${button2Playing ? "active" : ""}`}
style={{ borderRadius:"20px",left:10 }}

label='Chat zalo'
icon={
<div>
<svg 

onClick={() => handleButtonClick2("button1")}
xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="30" height="30"><path fill="#2962ff" d="M15,36V6.827l-1.211-0.811C8.64,8.083,5,13.112,5,19v10c0,7.732,6.268,14,14,14h10	c4.722,0,8.883-2.348,11.417-5.931V36H15z"/><path fill="#eee" d="M29,5H19c-1.845,0-3.601,0.366-5.214,1.014C10.453,9.25,8,14.528,8,19	c0,6.771,0.936,10.735,3.712,14.607c0.216,0.301,0.357,0.653,0.376,1.022c0.043,0.835-0.129,2.365-1.634,3.742	c-0.162,0.148-0.059,0.419,0.16,0.428c0.942,0.041,2.843-0.014,4.797-0.877c0.557-0.246,1.191-0.203,1.729,0.083	C20.453,39.764,24.333,40,28,40c4.676,0,9.339-1.04,12.417-2.916C42.038,34.799,43,32.014,43,29V19C43,11.268,36.732,5,29,5z"/><path fill="#2962ff" d="M36.75,27C34.683,27,33,25.317,33,23.25s1.683-3.75,3.75-3.75s3.75,1.683,3.75,3.75	S38.817,27,36.75,27z M36.75,21c-1.24,0-2.25,1.01-2.25,2.25s1.01,2.25,2.25,2.25S39,24.49,39,23.25S37.99,21,36.75,21z"/><path fill="#2962ff" d="M31.5,27h-1c-0.276,0-0.5-0.224-0.5-0.5V18h1.5V27z"/><path fill="#2962ff" d="M27,19.75v0.519c-0.629-0.476-1.403-0.769-2.25-0.769c-2.067,0-3.75,1.683-3.75,3.75	S22.683,27,24.75,27c0.847,0,1.621-0.293,2.25-0.769V26.5c0,0.276,0.224,0.5,0.5,0.5h1v-7.25H27z M24.75,25.5	c-1.24,0-2.25-1.01-2.25-2.25S23.51,21,24.75,21S27,22.01,27,23.25S25.99,25.5,24.75,25.5z"/><path fill="#2962ff" d="M21.25,18h-8v1.5h5.321L13,26h0.026c-0.163,0.211-0.276,0.463-0.276,0.75V27h7.5	c0.276,0,0.5-0.224,0.5-0.5v-1h-5.321L21,19h-0.026c0.163-0.211,0.276-0.463,0.276-0.75V18z"/></svg>


</div>


 
}

/>




<BottomNavigationAction
style={{ borderRadius:"20px" }}
className={`codepro-custom-btn codepro-btn-58 play-button ${button1Playing ? "active" : ""}`}
onClick={() => handleButtonClick("button1")}
label='Youtube'

/>




<BottomNavigationAction
style={{ borderRadius:"20px" }}
className="codepro-custom-btn codepro-btn-58"
id="contact-button" 
icon={

  <a
  onClick={handleButtonClick1}
  id="contact-button"
  style={{
    cursor: "pointer",
    color: "rgb(238, 215, 5)",
    borderRadius: "30px",
    margin: "2px",
    padding: "5px 10px",
    fontSize: "25px",
    height: "4rem",
    width:"4rem",
    fontWeight: "bold",
    top: "-50px",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
    whiteSpace: 'nowrap',
    display: 'inline-block', // Ensure the border is displayed
  }}
>
  <i  style={{
    top: "100px",
  }}
  className="fa fa-phone fa-1.5x" aria-hidden="true"></i>
</a>


}
/>



 <BottomNavigationAction
 style={{ borderRadius:"20px" }}
className="codepro-custom-btn codepro-btn-58"
onClick={copyPrompt2} 
label='Vị trí'

/>




<BottomNavigationAction
style={{ borderRadius:"20px",left:-10 }}
className="codepro-custom-btn codepro-btn-58"
onClick={handleCopyToClipboard} 
label='Chia sẻ'

/>



</BottomNavigation>


</Toolbar>
</AppBar>
<br/>

<Typography    
style={{
height: 'auto',
borderRadius: 8,
fontWeight: 'bold',
whiteSpace: 'pre-wrap',
display: 'flex',
flexDirection: 'column', // Hiển thị các phần tử theo chiều dọc
fontSize: '13px',
textAlign: 'left',
padding: '10px', // Điều chỉnh padding nếu cần
width: '99%', // Chiếm toàn bộ không gian ngang của phần tử cha
maxWidth: '100vw', // Đảm bảo không vượt quá chiều rộng của màn hình
overflowX: 'auto', // Cho phép cuộn nếu nội dung vượt quá kích thước chiều ngang
margin: 'auto' // Canh chỉnh giữa màn hình
}}
spacing={2}
>
                            <div>
                            <DialogTitle  style={{textAlign: 'center' ,fontSize:"1rem",fontWeight:"bold",padding: '5px'}}> <b>{marker.name} 
                            <hr
                              style={{
                                  opacity: "0.1",
                                  top: "5px",
                                  height: "0.5px",
                                  backgroundColor: "#000",
                                  border: "none",
                                  margin: "4px 3px 5px",
                                  fontWeight: "bold"
                              }}
                          /></b>
                         </DialogTitle>
                              <b style={{ padding: '5px' }}>Thông tin mô tả</b>
                            </div>
                            <div style={{ padding: '5px' }}>
                            {marker.description}
                            </div>
                            <hr
              style={{
                  opacity: "0.1",
                  top: "5px",
                  height: "0.5px",
                  backgroundColor: "#000",
                  border: "none",
                  margin: "4px 3px 5px",
                  fontWeight: "bold"
              }}
          />
{/* {moment(marker.exp_date * 1000).subtract(119, 'day').format('HH:mm - DD/MM/YYYY')
}  */}



<hr
              style={{
                  opacity: "0.1",
                  top: "5px",
                  height: "0.5px",
                  backgroundColor: "#000",
                  border: "none",
                  margin: "4px 3px 5px",
                  fontWeight: "bold",
                  padding: '5px'
              }}
          />
{marker.youtube_url}

                          </Typography>




</Stack>



<Stack direction="column" spacing={2} style={{padding: "20px",}}>
                          
<div style={{ position: 'relative', width: '100%' }}>
<div style={{
position: 'absolute',
left: 0,
right: 0,
margin: 'auto',
width: '70%', /* Điều chỉnh kích thước của phần tử con nếu cần */
}}>
<FormControl
value={comment}
onChange={handleCommentChange}
id="outlined-required"
fullWidth
placeholder="Viết bình luận..."
variant="outlined"
onKeyPress={(e) => {
if (e.key === 'Enter') {
  e.preventDefault();
  handleAddComment();
}
}}
className="leaflet-tooltip9"
type="search"
style={{
cursor: 'pointer',
size: '10px',
borderRadius: '10px',
fontSize: '14px',
color: 'black',
display: 'flex',
background: '#ffff',
paddingRight: '40px', // Adjusted padding to accommodate the icon
}}
isClearable
/>

{comment && (
<span
style={{
  position: 'absolute',
  right: 5, // Adjusted right position for the icon
  top: 18, // Center vertically
  transform: 'translateY(-50%)', // Center vertically
  cursor: 'pointer',
  zIndex: 1, 
  borderRadius:"30px"// Ensuring the icon is above the input field
}}
type="submit"
size="small"
color="primary"
variant="contained"
onClick={handleAddComment}
>
<svg xmlns="http://www.w3.org/2000/svg"  class="bi bi-file-arrow-up red-icon" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
</svg>


</span>
)}
</div></div> <br/>


{comment && (
<div style={{ position: 'relative', width: '100%' }}>

{localStorage.getItem("token") ? (
<span
  style={{
    position: 'absolute',
    right: '10px', // Adjusted right position for the icon
    top: '50%', // Center vertically
    transform: 'translateY(-50%)', // Center vertically
    cursor: 'pointer',
    zIndex: 1,
    borderRadius: '30px', // Ensuring the icon is above the input field
  }}
  type="submit"
  size="small"
  color="primary"
  variant="contained"
  onClick={handleAddComment}
>
</span>
) : (
<div>
  <Link to="/login">
    <span style={{ fontSize: '14px', color: 'blue', cursor: 'pointer' }}>
      Đăng nhập để bình luận
    </span>
  </Link>
</div>
)}
</div>
)}


                      

<br/><br/>
                     <Stack sx={{height: "350px", overflow: "scroll",
                              fontWeight: 'bold',
                              textAlign: 'left',}} direction="column" spacing={2}>


                        {comments.map((data, index) => (
                              <div key={index}>
                              <Stack direction="row" spacing={1}>
                                  <h style={{
                               
                               fontSize: '10px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                          }}>@{data.user.name}</h><br/>


                                  {/* <small 
                                  style={{
                                    fontSize:"8px",
                                    fontWeight:"bold"
                                }}>{moment(data.commented_date * 1000).fromNow()}</small> */}


                              </Stack>
                              <h
    style={{
      fontSize:"13px",
      fontWeight:"bold",
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginBottom: '5px',
    }}
  >
              {expandedComments[index] ? data.comment : (data.comment.length > 15 ? `${data.comment.slice(0, 15)}...` : data.comment)}
            </h>

                  {data.comment.length > 15 && !expandedComments[index] && (
                    <a
                      href="#readmore"
                      onClick={(e) => {
                        e.preventDefault();
                        handleReadMoreClick(index);
                      }}
                      style={{
                        fontSize: '10px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      Đọc tiếp
                    </a>
                  )}



                  {data.comment.length > 30 && expandedComments[index] && (
                    <a
                      href="#readless"
                      onClick={(e) => {
                        e.preventDefault();
                        setExpandedComments((prevExpandedComments) => {
                          const newExpandedComments = [...prevExpandedComments];
                          newExpandedComments[index] = false;
                          return newExpandedComments;
                        });
                      }}
                      style={{
                        fontSize: '10px',
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      Read less
                    </a>
                  )}


<hr
 style={{
   opacity: "0.1",
   top: "5px",
   height: "0.5px",
   backgroundColor: "#000",
   border: "none",
   margin: "4px 3px 5px",
 }}
/>
                            
                    
                          </div>
                          ))} 

                          
                      
{comments.map((data, index) => (
<div key={index}>
  

</div>
))}

                      </Stack>
                      </Stack>
</Stack>
          </Box>
           
        
    </Box>
    </Modal>
  );
};

export default DialogMarker;
