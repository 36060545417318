import { Utils } from '../Utils/Utils';
import { Card, CardMedia, Stack } from '@mui/material';

export default function CardMap({ data,value }) {
 
  return (
    <Card sx={{ width: 100, left: 0 }} style={{ overflow: 'visible', margin: '2px', display: 'flex', paddingLeft: '10px',border:"none" }}>

    <Stack
      direction="column" 
      spacing="10px"
      style={{
        height: '100%',
        borderRadius: '10px',
        wordBreak: 'break-word',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '10px'
        ,border:"none"
      }}
      className="'codepro-custom-btn codepro-btn-58"
    >
      <div  style={{
        position: "relative",
        backgroundImage: `url(../assets/icons/logo512.png)`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "auto",
        height: "59%",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        justifyContent: "center"
      }}>
     
          <div
                    style={{
                      width: '5rem',
                      height: '5rem',
                      left: '0',
                      borderRadius: '30px',
                      backgroundImage: `url(${data.image_url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      position: 'relative',
                    }}
                  >

                  </div>
      </div>
      <span style={{ fontSize: '12px', margin: 'auto', width: '100%',fontWeight: "bold", }}>
      &nbsp; {data.name}&nbsp;
      </span>
    </Stack>
  </Card>
  );
}
