export const SET_DATA_MAP_JSON = 'SET_DATA_MAP_JSON';
export const setDataMapJsonAction = (data) => ({
  type: SET_DATA_MAP_JSON,
  payload: data
});

export const SET_SEARCH_FEATURE_DATA = 'SET_SEARCH_FEATURE_DATA';
export const setSearchFeatureDataAction = (data) => ({
  type: SET_SEARCH_FEATURE_DATA,
  payload: data
});

export const SET_HIDE_PLACE_INFO = 'SET_HIDE_PLACE_INFO';
export const setHidePlaceInfoAction = (data) => ({
  type: SET_HIDE_PLACE_INFO,
  payload: data
});