export class Utils {
    
    // static CAN_GIUOC_URL_MAPBOX_VECTOR_TILE = 'http://34.126.158.183:2000/data/myloc/{z}/{x}/{y}.pbf';
    // static URL_SERVER = 'http://34.126.158.183:2000';
    // static DATA_JSON_NAME_FILE = 'myloc';
    static isSearched = false
    static CAN_GIUOC_URL_MAPBOX_VECTOR_TILE = '';
    static URL_SERVER = '';
    static DATA_JSON_NAME_FILE = '';
    static URL_IMAGE = '';
    static URL_IMAGE2 = '';
    static URL_SEARCH = '';
    static LAT = 0.0;
    static LNG = 0.0;
    static IS_FREE = false;
    static ID = 0;


    static validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    static isVietnamesePhoneNumber = (number) => {
        return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(number);
    }

    static formatPhoneNumber = (number) => {
        var n = number.slice(1, number.length);
        return "+84" + n
    }

    static sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static XY2BL = (x, y, kkt, mui_chieu) => {
        var X = x;
        var Y = y - 500000;

        var a = 6378137; var b = 6356752; var L0 = kkt;
        var x0 = -191.90441429; var y0 = -39.30318279; var z0 = -111.45032835;
        var w0 = -0.00928836; var k0 = 0.01975479; var e0 = -0.00427372;
        var k = 1.000000252906278; var muichieu = mui_chieu;
        //chuyen doi
        var e1 = Math.sqrt((Math.pow(a, 2) - Math.pow(b, 2)) / Math.pow(a, 2));//do lech tam thu nhat;
        var k00 = 0.9999;

        //Buoc 1: chuyen xy ve BL
        //Tinh Bx
        var a0 = 1 + 3 / 4 * Math.pow(e1, 2) + 45 / 64 * Math.pow(e1, 4) + 350 / 512 * Math.pow(e1, 6) + 11025 / 16384 * Math.pow(e1, 8);
        var k01 = 1 / 2 * (3 / 4 * Math.pow(e1, 2) + 45 / 64 * Math.pow(e1, 4) + 350 / 512 * Math.pow(e1, 6) + 11025 / 16384 * Math.pow(e1, 8));
        var k2 = -1 / 3 * (63 / 64 * Math.pow(e1, 4) + 1108 / 512 * Math.pow(e1, 6) + 58293 / 16384 * Math.pow(e1, 8));
        var k4 = 1 / 3 * (604 / 512 * Math.pow(e1, 6) + 58293 / 16384 * Math.pow(e1, 8));
        var k6 = -1 / 3 * (26328 / 16384 * Math.pow(e1, 8));
        var Bx = X / (k00 * a * a0 * (1 - Math.pow(e1, 2)));
        var B0 = Bx + Math.sin(2 * Bx) * (k01 + k2 * Math.pow(Math.sin(Bx), 2) + k4 * Math.pow(Math.sin(Bx), 4) + k6 * Math.pow(Math.sin(Bx), 6));
        var t0 = Math.tan(B0);
        var N0 = a / Math.sqrt(1 - Math.pow(e1, 2) * Math.pow(Math.sin(B0), 2));
        var M0 = (1 - Math.pow(e1, 2)) * N0 / (1 - Math.pow(e1, 2) * (Math.pow(Math.sin(B0), 2)));
        var Y00 = N0 / M0;

        var Bra = B0 - t0 * Math.pow(Y, 2) / (2 * Math.pow(k00, 2) * M0 * N0) + t0 * 1 / (k00 * M0) * Math.pow(Y, 4) * 1 / (24 * Math.pow(k00, 3) * Math.pow(N0, 3)) * (-4 * Math.pow(Y00, 2) + 9 * Y00 * (1 - Math.pow(t0, 2)) + 12 * Math.pow(t0, 2));
        var Lra = 1 / Math.cos(B0) * (Y / k00 / N0) - 1 / Math.cos(B0) * (Math.pow(Y, 3) / 6 / Math.pow(k00, 3) / Math.pow(N0, 3)) * (Y00 + 2 * Math.pow(t0, 2));

        var B = Bra * 1 / Math.PI * 180;
        var L = Lra / Math.PI * 180 + L0;
        //Buoc 1: chuyen xy ve BL

        //Buoc 2: chuyen BLH ve XYZ
        var H = 0.0;
        var N = a / Math.sqrt(1 - Math.pow(e1, 2) * Math.pow(Math.sin(B * Math.PI / 180), 2));
        var X0 = (N + H) * Math.cos(B * Math.PI / 180) * Math.cos(L * Math.PI / 180);
        var Y0 = (N + H) * Math.cos(B * Math.PI / 180) * Math.sin(L * Math.PI / 180);
        var Z0 = (N * (1 - Math.pow(e1, 2)) + H) * Math.sin(B * Math.PI / 180);
        //Buoc 2: chuyen BLH ve XYZ (xong)

        //Buoc 3: chuyen XYZ ve X'Y'Z' (theo HD 1123 ngay 26/10/2007 cua cuc do dac va ban do)
        var X1 = x0 + k * (X0 + (e0 / 3600 * Math.PI / 180) * Y0 - (k0 / 3600 * Math.PI / 180) * Z0);
        var Y1 = y0 + k * (-(e0 / 3600 * Math.PI / 180) * X0 + Y0 + (w0 / 3600 * Math.PI / 180) * Z0);
        var Z1 = z0 + k * ((k0 / 3600 * Math.PI / 180) * X0 - (w0 / 3600 * Math.PI / 180) * Y0 + Z0);
        //Buoc 3: chuyen XYZ ve X'Y'Z'(theo HD 1123 ngay 26/10/2007 cua cuc do dac va ban do) (xong)

        //Buoc 4: chuyen X'Y'Z' thanh BLH
        var solanlap = 0;
        var saiso = 1;
        var v = 0;
        //Double Blap=atan((Z1+pow(e1,2)*N*sin(B))/sqrt(pow(X1,2)+pow(Y1,2)));
        var Blap = 0.0;
        while (solanlap < 200 & saiso > Math.pow(10, -12)) {
            solanlap++;
            N = a / Math.sqrt(1 - Math.pow(e1, 2) * (Math.pow(Math.sin(Blap), 2)));
            Blap = Math.atan((Z1 + Math.pow(e1, 2) * N * Math.sin(Blap)) / Math.sqrt(Math.pow(X1, 2) + Math.pow(Y1, 2)));
            saiso = Blap - v;
            v = Blap;
        }
        var La = Blap * 180 / Math.PI;
        var Lo = 180 + Math.atan(Y1 / X1) * 180 / Math.PI;
        var caodo = 0;

        var result = new Array(La, Lo);
        return result;
    }

    static getFormattedDate = (time) => {
        var date = new Date(time)
    
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var min = date.getMinutes();
        var sec = date.getSeconds();

        day = (day < 10 ? "0" : "") + day;
        month = (month < 10 ? "0" : "") + month;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;
        // sec = (sec < 10 ? "0" : "") + sec;
    
        var str = date.getFullYear() + "-" + day + "-" + month + " lúc " +  hour + ":" + min ;
    
        /*alert(str);*/
        // + ":" + sec
    
        return str;
    }

    static checkURL = (url) => {
        return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }
    
    static checkDevtool = async () => {
        if (window.devToolStatus) {
            await Utils.sleep(500)
            debugger;
            await Utils.checkDevtool()
        }
    }

    static downscaleImage = async (dataUrl, newWidth, imageType, imageArguments) => {
        "use strict";

        var url = URL.createObjectURL(dataUrl)

        var image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;
    
        // Provide default values
        imageType = imageType || "image/jpeg";
        imageArguments = imageArguments || 0.7;
    
        
        
        // Create a temporary image so that we can compute the height of the downscaled image.
        image = new Image();
        image.src = url;
        
        await Utils.loadEvent(image)
    
        oldWidth = image.width;
        oldHeight = image.height;
        newHeight = Math.floor(oldHeight / oldWidth * newWidth)
        // Create a temporary canvas to draw the downscaled image on.
        canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        // Draw the downscaled image on the canvas and return the new data URL.
        ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
        newDataUrl = canvas.toDataURL(imageType, imageArguments);
        return newDataUrl;
        
    }

    static async loadEvent(image) {
        return new Promise(resolve => image.addEventListener("load", () => resolve()));
    }
}