import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Modal, Box, IconButton, TextField ,AppBar ,Autocomplete,Toolbar ,Avatar } from "@mui/material";
import { Services } from "../APIs/Services";
import DialogMarker from './DiaglogMarker1';  
import moment from 'moment';
import {DISTRICTS} from '../constants/maps.constant'
import AdComponent from '../components/AdSenseAds';
 
import {  FormControl, Button as ReactButton } from 'react-bootstrap';

 

const modalStyle4 = {
    position: "absolute",
    top: "97%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
    display: "block",
    maxHeight: { xs: "100%", md: "100%" },
    border: "2px solid #000",
    borderRadius: "10px",
    background: '#fff',
 
  };

 
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: { xs: "100%", md: "40%" },
    maxWidth: "100%",
    height: "100%",
    overflow: "scroll",
    display: "block",
    maxHeight: { xs: "100%", md: "100%" },
    border: "2px solid #000",
    borderRadius: "5px",
  };


export default function SearchMapView3({ handleClose, open }) {
    const [data, setData] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
  
    const handleMarkerClick = (marker) => {
      setSelectedMarker(marker);
    };
  
    const handleSearchChange = (event, value) => {
      setSearchText(value);
      setCurrentPage(1); // Reset to the first page when performing a new search
    };


 
    useEffect(() => {
      const fetchData = async () => {
        const query = new URLSearchParams(window.location.search);
        const markerId = query.get('marker');
  
        if (markerId != null) {
          try {
            const markerData = await Services.getMarker(markerId);
            setData([markerData.data.data]);
          } catch (error) {
            console.error(error);
          }
        } else {
          try {
            const markersData = await Services.getMarkers();
            setData(markersData.data.data);
          } catch (error) {
            console.error(error);
          }
        }
      };
  
      fetchData();
    }, []);
  
 


     // Filter data based on search text
  const filteredData = data.filter((item) =>
  item.name.toLowerCase().includes(searchText.toLowerCase())
);

// Calculate total number of pages
const totalPages = Math.ceil(filteredData.length / itemsPerPage);

// Calculate the index of the first item to display on the current page
const startIndex = (currentPage - 1) * itemsPerPage;

// Get the items for the current page
const currentItems = filteredData.slice(startIndex, startIndex + itemsPerPage);

 


const handleInputChange = (event) => {
  setSearchText(event.target.value);
};

useEffect(() => {
  const delay = 500; // Adjust the delay (in milliseconds) as needed
  const timeoutId = setTimeout(() => {
    // Trigger your action here, for example, updating the state or calling a function
    console.log('User finished typing:', searchText);
  }, delay);

  return () => {
    clearTimeout(timeoutId); // Clear the timeout on component unmount or when searchText changes
  };
}, [searchText]);





const [typedText, setTypedText] = useState('');
const placeholderText = 'Search tại đây !';

useEffect(() => {
  const interval = setInterval(() => {
  let currentText = typedText;
  if (currentText.length < placeholderText.length) {
    currentText = placeholderText.slice(0, currentText.length + 1);
    setTypedText(currentText);
  } else {
    clearInterval(interval);

    // Sau một khoảng thời gian, đặt lại placeholder về rỗng và bắt đầu lại.
    setTimeout(() => {
    setTypedText('');
    }, 10000); // Thay đổi thời gian chờ trước khi bắt đầu lại
  }
  }, 200); // Thay đổi tốc độ hiển thị ở đây nếu cần

  return () => {
  clearInterval(interval);
  };
}, [typedText]);
 


const [selectedDistrict, setSelectedDistrict] = useState('Miền Tây');

  return (
    <Modal  BackdropProps={{ style: { backgroundColor: "transparent" } }} open={open}>
      <Box>
 
        
        <Paper  sx={style}>
 

 
        <br/>
        <AdComponent/>
        <div>
        Nhận ký gửi, mua bán nhà đất , 0799799993.    <br/>
  ẩn quảng cáo LH 0799799993.
</div>
 
 


<ul sx={style} style={{marginLeft :-30}}>
        {currentItems
          .filter((item) => {
            return (
              selectedDistrict === 'BDS Việt Nam' ||
              (item.district && item.district === selectedDistrict)
            );
          })
          .map((item, index) => (
            <li
            key={index}
            onClick={() => handleMarkerClick(item)}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
              borderBottom: index < currentItems.length - 1 ? '1px solid #ccc' : 'none',
              borderRadius: '10px',
              border: '1px solid DodgerBlue',
              paddingLeft: 0,
              padding: 15,
              margin: 5,
            
            }}
            className="codepro-custom-btn codepro-btn-59"
          >
            <div
              loading="lazy"
              className="image-container"
              style={{
                position: 'relative',
                backgroundImage: `url(${item.image_url.split(',')[0].trim()})`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '7rem',
                height: '7rem',
                cursor: 'pointer',
                marginRight: '1rem', // Thêm khoảng cách giữa hình ảnh và nội dung
                display: 'inline-block',
                objectFit: 'cover', // Maintain aspect ratio
                backgroundSize: 'cover', // Fit image to container
                backgroundPosition: 'center', // Center the image
                borderRadius: '10px', // Rounded corners
              }}
            >
              <div className="text-overlay" style={{ fontSize: '15px', color: '#ffffff', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', opacity: '0.4' }}>
                <h1 style={{ fontSize: '15px', color: '#ffffff' }}>tcmap.vn</h1>
              </div>
            </div>
          
            <div style={{ marginLeft: '10px' }}>
              <div style={{
                borderRadius: '10px',
                color: '#3d94f6',
                backgroundColor: 'white',
                height: 'auto', // Đặt chiều cao tự động để nội dung có thể mở rộng theo chiều dọc
                fontSize: '7px',
                margin: '5px',
                marginTop: '3px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}>
                <h align="left" style={{ margin: '10' }}>
                  <h style={{
                    borderRadius: '10px',
                    color: 'black',
                    backgroundColor: 'white',
                    height: '3rem',
                    fontSize: '17px',
                    marginTop: '3px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}>
                    {item.name.slice(0, 10)}{item.name.length > 15 ? '...' : ''}
                  </h>
                  <br />
                  {item.description.slice(0, 20)}{item.description.length > 15 ? '...' : ''}
                  <br />
                  <br />
                  <h style={{
                    borderRadius: '10px',
                    color: 'black',
                    backgroundColor: 'white',
                    height: '3rem',
                    fontSize: '8px',
                    marginTop: '3px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    margin: '5px',
                  }}>
                    {moment(item.exp_date * 1000).subtract(119, 'day').format('HH:mm - DD/MM/YYYY')}
                  </h>

                  <br />
                  <br />
                  <h style={{
                    borderRadius: '10px',
                    color: 'black',
                    backgroundColor: 'white',
                    height: '3rem',
                    fontSize: '0.8rem',
                    marginTop: '3px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    margin: '5px',
                  }}>
                     {item.youtube_url.slice(0, 100)}
                  </h>

                </h>
              </div>
            </div>
          </li>
          
          ))}
      </ul>

      
 <br/>
 





 




        
        </Paper>


        
<AppBar position="sticky" style={{   bottom: 0, zIndex: 9, background: "none", border: "none", boxShadow: 'none', color: "black" ,justifyContent: 'center', }}>
  <Toolbar style={{ display: 'flex', justifyContent: 'center', background: "none", border: "none" }}>
      
 
  <div style={{ position: 'relative', display: 'inline-block' }}>
  <FormControl
        placeholder={typedText}
        variant="outlined"
        margin="dense"
        autoComplete="off"
        style={{
          
          zIndex: 9,
          cursor: 'pointer',
          color: 'blue',
          background: '#fff',
          fontSize: 'small',
          borderRadius: '30px',
          border: '1px solid DodgerBlue',
          width: 350,
          margin: 'auto',
          height:"2.5rem"
        }}
        value={searchText}
        onChange={handleInputChange}
      />

      <IconButton
        onClick={handleClose}
        style={{
          position: 'absolute',
          top: -5,
          right: 0,
          cursor: 'pointer',
          color: 'red',
          background: 'none',
          size: '13px',
          borderRadius: '5px',
          margin: '3px',
          padding: '5px 10px',
          fontSize: 'small',
          zIndex: 9999999999999999,
          height:"3rem"
        }}
      >
        ❌
      </IconButton>
    </div>
 
</Toolbar>
</AppBar>


<AppBar position="sticky" style={{ bottom: 0, zIndex: 9, background: "none", border: "none", boxShadow: 'none', color: "black" }}>
  <Toolbar style={{ display: 'flex', justifyContent: 'flex-start', background: "none", border: "none" }}>
    <div
      style={{
        margin: 'auto',
        height: 50,
        display: "flex",
        overflowX: 'auto', // Enable horizontal scrolling if buttons exceed the width
        justifyContent: 'flex-start', // Align content to the left
      }}
    >  
      {DISTRICTS.map((district, index) => (
        <IconButton
          style={{
            color: selectedDistrict === district.label ? '#ffff' : 'blue', // Chọn màu khi được chọn
            backgroundColor: selectedDistrict === district.label ? '#fcb332' : '#ffff', // Chọn màu nền khi được chọn
            borderRadius: '10px',
            margin: '2px',
            fontSize: '13px',
            height: 30,
            fontWeight: 'bold',
            border: '1px solid DodgerBlue',
          }}
          key={index}
          onClick={() => setSelectedDistrict(district.label)}
          className={selectedDistrict === district.label ? 'selected' : 'button-container'}
        >
          {district.label}
        </IconButton>
      ))}
    </div>
  </Toolbar>
</AppBar>

        
 



         
      


        
     
<AppBar sx={{ ...modalStyle4, width: [`100%`, `40%`], border: "none", height:70}} >
   
<div style={{ textAlign: 'center', marginTop: '10px' }}>
  {/* Pagination component */}
  <span
    onClick={() => setCurrentPage(1)}
    disabled={currentPage === 1}
    style={{ margin: '0 10px', cursor: 'pointer', color: "black" }}
  >
    {'◀'}
  </span>

  <span
    onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
    disabled={currentPage === 1}
    style={{ margin: '0 10px', cursor: 'pointer', color: "black" }}
  >
    {`◁`}
  </span>

  {[...Array(totalPages)].map((_, index) => {
    const pageNumber = index + 1;
    const isCurrentPage = pageNumber === currentPage;
    const isNearCurrentPage = Math.abs(currentPage - pageNumber) <= 2; // Adjusted to display 5 pages at a time

    if (isNearCurrentPage || isCurrentPage) {
      return (
        <span
          key={pageNumber}
          onClick={() => setCurrentPage(pageNumber)}
          style={{
            margin: '0 10px',
            cursor: 'pointer',
            fontWeight: isCurrentPage ? 'bold' : 'normal',
            color: isCurrentPage ? 'white' : 'black',
            backgroundColor: isCurrentPage ? '#2640d3' : 'transparent',
            padding: '5px',
            borderRadius: '30px',
          }}
        >
          {pageNumber}
        </span>
      );
    }

    return null;
  })}

  <span
    onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
    disabled={currentPage === totalPages}
    style={{ margin: '0 10px', cursor: 'pointer', color: "black" }}
  >
    {`▷`}
  </span>

  <span
    onClick={() => setCurrentPage(totalPages)}
    disabled={currentPage === totalPages}
    style={{ margin: '0 10px', cursor: 'pointer', color: "black" }}
  >
    {'▶'}
  </span>
</div>

         
          </AppBar>


        <Box>
          {selectedMarker && (
            <DialogMarker
              marker={selectedMarker}
              onClose={() => setSelectedMarker(null)}
            />
          )}
        </Box>

      </Box>
    </Modal>
  );
}
