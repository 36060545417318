import axios from 'axios';
import { Utils } from '../Utils/Utils';

export class Services {
    static NETWORK = require('./Network');

    static login = async (username, password) => {
       
        const requestOptions = {
            body: { "email": username, "password": password },
        };
        return axios.post(this.NETWORK.baseurl + this.NETWORK.login, requestOptions.body);

    };

    static register = async (username, password, name, job, phoneNumber) => {
       
        const requestOptions = {
            body: { "email": username, "password": password, "name": name, "job": job, "phone_number": phoneNumber },
        };
        return axios.post(this.NETWORK.baseurl + this.NETWORK.register, requestOptions.body);

    };

    static users = async (pageNumber, email) => {
       
        const requestOptions = {
            params: { "page": pageNumber, "email": email, "token": localStorage.getItem("token") },
        };
        return axios.get(this.NETWORK.baseurl + this.NETWORK.users, requestOptions).catch(error => {
            if (error.response.status === 403) {
                window.location.href = "/login";
            }
        });

    };

    static getOTP = async (email) => {
       
        const requestOptions = {
            params: { "email": email },
        };
        return axios.get(this.NETWORK.baseurl + this.NETWORK.emailOtp, requestOptions);

    };

    static verifyOTP = async (email, otp) => {
       
        const requestOptions = {
            body: { "email": email, 'otp': otp },
        };
        return axios.post(this.NETWORK.baseurl + this.NETWORK.emailOtp, requestOptions.body);

    };

    static resetPassword = async (email, otp) => {
       
        const requestOptions = {
            body: { "email": email, 'otp': otp },
        };
        return axios.post(this.NETWORK.baseurl + this.NETWORK.resetPassword, requestOptions.body);

    };

    static changePassword = async (userId, newPassword, oldPassword) => {
       
        const requestOptions = {
            body: { "user_id": userId, 'new_password': newPassword, 'old_password': oldPassword },
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.changePassword, requestOptions.body, paramOptions);

    };

    static user = async () => {
       
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.user, paramOptions).catch(error => {
            if (error.response.status === 403) {
                window.location.href = "/login";
            }
        });
    };

    static updateUser = async (name, job, phone_number) => {
       
        const requestOptions = {
            body: { "name": name, 'job': job, 'phone_number': phone_number },
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.user, requestOptions.body, paramOptions);
    };

    static deleteUser = async () => {
       
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.delete(this.NETWORK.baseurl + this.NETWORK.user, paramOptions);
    };

    static updateAdminUser = async (name, exp_date, job, phone_number, viewNumber, isActive, isDelete, userId) => {
       
        const requestOptions = {
            body: { "name": name, "exp_date": exp_date, 'job': job, 'phone_number': phone_number, "number_of_views": viewNumber, 'is_active': isActive, 'is_delete': isDelete, 'user_id': userId },
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.put(this.NETWORK.baseurl + this.NETWORK.admin_user, requestOptions.body, paramOptions);
    };

    static addAdminUser = async (name, exp_date, email, job, phone_number, viewNumber, isActive, isDelete) => {
       
        const requestOptions = {
            body: { "name": name, "exp_date": exp_date, "email": email, 'job': job, 'phone_number': phone_number, "number_of_views": viewNumber, 'is_active': isActive, 'is_delete': isDelete },
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.admin_user, requestOptions.body, paramOptions);
    };

    static adminResetPassword = async (userId) => {
       
        const requestOptions = {
            body: { "user_id": userId },
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.admin_reset_password, requestOptions.body, paramOptions);
    };

    static activeAccount = async (email, safety_code) => {
       
        const requestOptions = {
            body: { "email": email, "safety_code": safety_code },
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.active_account, requestOptions.body, paramOptions);
    };

    static checkAuthentication = async (redirectToLogin = true) => {
       
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.checkAuthentication, paramOptions).catch(error => {
            if (error.response.status === 403) {
                
                if (redirectToLogin) {
                    window.location.href = "/login";
                }
                
                localStorage.removeItem("token")
                localStorage.removeItem("isSuperUser")
                localStorage.removeItem("userId")
                localStorage.removeItem("name")
            }
        });
    };

    static addMarker = async (name, expDate, description,image_url, youtube_url, district, lng,lag,type) => {
       
        const requestOptions = {
            body: { "name": name, "exp_date": expDate, "description": description, 'image_url': image_url, "youtube_url": youtube_url, "district": district, "lng": lng, 'lag': lag, 'type': type },
        };
        console.log(requestOptions)
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.marker, requestOptions.body, paramOptions);
    };

    static updateMarker = async (id,name, expDate,description,image_url, youtube_url, district, lng,lag,type, exp_date, is_activate, is_delete) => {
       
        const requestOptions = {
            body: { "id": id,"name": name, "exp_date": expDate, "description": description, 'image_url': image_url, "youtube_url": youtube_url, "district": district, "lng": lng, 'lag': lag, 'type': type, 'exp_date': exp_date, 'is_activate': is_activate, 'is_delete': is_delete  },
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.put(this.NETWORK.baseurl + this.NETWORK.marker, requestOptions.body, paramOptions);
    };

    static deleteMarker = async (id) => {
       
        const requestOptions = {
            params: { "id": id, "token": localStorage.getItem("token")},
        };
        return axios.delete(this.NETWORK.baseurl + this.NETWORK.marker, requestOptions);
    };

    static getMarker = async (id) => {
       
        const requestOptions = {
            params: { "id": id },
        };
        return axios.get(this.NETWORK.baseurl + this.NETWORK.marker, requestOptions);

    };

    static getMarkers = async () => {
       
        return axios.get(this.NETWORK.baseurl + this.NETWORK.markers);
    };

    static getAdminMarkers = async (pageNumber, title) => {
       
        const requestOptions = {
            params: { "page": pageNumber, "title": title, "token": localStorage.getItem("token")},
        };
        return axios.get(this.NETWORK.baseurl + this.NETWORK.admin_markers, requestOptions);
    };

    static getCardMarkers = async (centerCoordinators) => {
       
        const requestOptions = {
            params: { "center_lng": centerCoordinators[0],"center_lag": centerCoordinators[1] },
        };
        return axios.get(this.NETWORK.baseurl + this.NETWORK.car_markers, requestOptions);
    };

    static getMaps = async (pageNumber, title, id = -1) => {
       
        const paramOptions = {
            params: {"id": id, "page": pageNumber, "title": title, "token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.maps, paramOptions);
    };

    static addMap = async (name,json_name_file,mapbox_url,server_url, image_url, image_url2,image_url3, image_url4,  search_url, lat, lng, is_free) => {
       
        const requestOptions = {
            body: { "name": name, "json_name_file": json_name_file,"mapbox_url": mapbox_url, "server_url": server_url, "image_url": image_url, "image_url2": image_url2, 
            "image_url3": image_url3, "image_url4": image_url4, "search_url": search_url, "lag": lat, "lng": lng, "is_free": is_free},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.map, requestOptions.body, paramOptions);
    };

    static updateMap = async (id,name,json_name_file,mapbox_url,server_url, image_url, image_url2, image_url3, image_url4, search_url, lat, lng, is_free) => {
       
        const requestOptions = {
            body: { "id": id,"name": name,"json_name_file": json_name_file,"mapbox_url": mapbox_url, "server_url": server_url, "image_url": image_url, "image_url2": image_url2, 
            "image_url3": image_url3, "image_url4": image_url4, 
            "search_url": search_url, "lag": lat, "lng": lng, "is_free": is_free},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.put(this.NETWORK.baseurl + this.NETWORK.map, requestOptions.body, paramOptions);
    };

    static setFreeAllMap = async (is_free) => {
       
        const requestOptions = {
            body: {"is_free": is_free},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.all_maps, requestOptions.body, paramOptions);
    };

    static getMapsLocation = async (lat, lng) => {
       
        const paramOptions = {
            params: {"lng": lng, "lag": lat, "token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.search_maps_location, paramOptions);
    };

    static deleteMap = async (id) => {
       
        const requestOptions = {
            params: { "id": id, "token": localStorage.getItem("token")},
        };
        return axios.delete(this.NETWORK.baseurl + this.NETWORK.map, requestOptions);
    };

    static getSearchMaps = async (searchText ) => {
       
        const requestOptions = {
            params: { "searchText": searchText, "token": localStorage.getItem("token") },
        };
        return axios.get(this.NETWORK.baseurl + this.NETWORK.search_maps, requestOptions);
    };

    static getSettingNewsMap = async (title) => {
       
        const paramOptions = {
            params: {"title": title,"token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.settingMap, paramOptions);
    };

    static addSettingNewsMap = async (title,map,image_url,dateUpdated, is_active) => {
       
        const requestOptions = {
            body: {"title": title,"map": map,"image_url": image_url, "dateUpdated": dateUpdated, "is_active": is_active},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.settingMap, requestOptions.body, paramOptions);
    };

    static updateSettingNewsMap = async (id,title,map,image_url,dateUpdated, is_active) => {
       
        const requestOptions = {
            body: {"id": id,"title": title,"map": map,"image_url": image_url, "dateUpdated": dateUpdated, "is_active": is_active},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.put(this.NETWORK.baseurl + this.NETWORK.settingMap, requestOptions.body, paramOptions);
    };

    static deleteSettingNewsMap = async (id) => {
       
        const requestOptions = {
            params: { "id": id, "token": localStorage.getItem("token")},
        };
        return axios.delete(this.NETWORK.baseurl + this.NETWORK.settingMap, requestOptions);
    };

    static getNews = async (title) => {
       
        const paramOptions = {
            params: {"title": title, "token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.news, paramOptions);
    };

    static addNews = async (title,img,shortDescription,description, category) => {
       
        const requestOptions = {
            body: { "title": title,"img": img, "shortDescription": shortDescription, "description": description, "category":category},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.news, requestOptions.body, paramOptions);
    };

    static updateNews = async (id,title,img,shortDescription,description, category) => {
       
        const requestOptions = {
            body: { "id": id,"title": title,"img": img, "shortDescription": shortDescription, "description": description, "category":category},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.put(this.NETWORK.baseurl + this.NETWORK.news, requestOptions.body, paramOptions);
    };

    static deleteNews = async (id) => {
       
        const requestOptions = {
            params: { "id": id, "token": localStorage.getItem("token")},
        };
        return axios.delete(this.NETWORK.baseurl + this.NETWORK.news, requestOptions);
    };

    static getNotifications = async () => {
       
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.notification, paramOptions);
    };

    static updateNotification = async (id) => {
       
        const requestOptions = {
            body: { "id": id},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.put(this.NETWORK.baseurl + this.NETWORK.notification, requestOptions.body, paramOptions);
    };

    static addComment = async (marker,comment) => {
       
        const requestOptions = {
            body: { "marker": marker,"comment": comment},
        };
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.comment, requestOptions.body, paramOptions);
    };

    static getComments = async (marker_id) => {
       
        const paramOptions = {
            params: {"marker_id": marker_id,"token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.comment, paramOptions);
    };

    static addImage = async (file) => {
       
        const formData = new FormData()
        const newFile = await Utils.downscaleImage(file,1000, "image/jpeg", 300000/file.size)
        let response = await fetch(newFile);
        let data = await response.blob();
        let metadata = {
            type: 'image/jpeg'
        };
        let file_image = new File([data], "test.jpg", metadata);
        formData.append("file", file_image);

        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.post(this.NETWORK.baseurl + this.NETWORK.image, formData, paramOptions);
    };

    static getImage = async (image_id) => {
       
        const paramOptions = {
            params: {"image_id": image_id,"token": localStorage.getItem("token")},
        }
        return axios.get(this.NETWORK.baseurl + this.NETWORK.image, paramOptions);
    };

    static updateView = async () => {
       
        const paramOptions = {
            params: {"token": localStorage.getItem("token")},
        }
        return axios.put(this.NETWORK.baseurl + this.NETWORK.change_view, null, paramOptions);
    };
}