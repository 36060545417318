import React, { useState } from "react";
import { Box, Modal, Avatar ,IconButton,Typography} from "@mui/material";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { InputGroup, FormControl } from 'react-bootstrap'; 
import axios from 'axios';
 
 

const styles = {
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  icon: {
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
  },
  backToHome: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
};

const redirect1 = () => {
  window.open('https://zalo.me/g/lclyht135');
  return null;
};

 










const TabContent = ({ tab }) => {
  const [selectedNewsImages, setSelectedNewsImages] = useState(["../assets/icons/MB.png", "../assets/icons/momo50.png", "../assets/icons/techcom.png"]);

 


  const handleImageClick = (buttonIndex) => {
    // Logic to set different images based on the button clicked
    let newImages = [];
    if (buttonIndex === 1) {
      newImages = ["url(../assets/icons/MB.png)"];
    } else if (buttonIndex === 2) {
      newImages = ["url(../assets/icons/momo50.png)"];
    } else if (buttonIndex === 3) {
      newImages = ["url(../assets/icons/techcom.png)"];
    }
  
    setSelectedNewsImages(newImages);
  };
   
  const content = `Với gói của TCmap, ${localStorage.getItem("name")} sẽ được..\n\n` +
  "✅  Không giới hạn các tính năng năng trên App và website  .\n\n" +
  "✅  Không Hiển thị quảng cáo  .\n\n" +
  "✅  Hỗ trợ đăng tin miễn phí  .\n\n" +
  "✅  Hỗ trợ kiểm tra quy hoạch miễn phí  .\n\n" +
  "✅  Xem thông tin chi tiết trong thửa đất  .\n\n" +
  "✅  Kiểm tra quy hoạch đất đai trên 63 tỉnh thành  .\n\n" +
  "✅  Xem vị trí sổ đỏ trên bản đồ, xem quy hoạch của thửa đất .\n\n" +
  "✅  Đo vẽ diện tích và kích thước thửa đất. .\n\n" +
  "✅  Tìm thửa đất theo góc ranh và toạ độ .\n\n" +
  "✅  Tìm thửa đất theo khu vực và địa chỉ .\n\n" +
  "✅  Tìm thửa đất trên sổ đỏ theo số tờ số thửa .\n\n" +
  "✅  Xem đa dạng các loại quy hoạch: QH 2030, KH 2023, QH xây dựng, QH phân khu… .\n\n" +
  "✅  Được yêu cầu hỗ trợ về bản đồ và hướng dẫn cách xem bản đồ quy hoạch theo khu vực bạn muốn tra cứu..\n\n";

 



  
const AHvrdsadsaasOZeyi = '1765258895';
const HeLkeKhJSOyaIEd0brpxr = '7064677366:AAFiPnBgK2oUFlCRbHviWr1HFtzCvrrSl44';



const [markerName, setMarkerName] = useState('');
const emailFromLocalStorage = localStorage.getItem("email");
const [markerName1, setMarkerName1] = useState(emailFromLocalStorage ? emailFromLocalStorage : '');

const [errorMessage, setErrorMessage] = useState('');
const [nameErrorMessage, setNameErrorMessage] = useState('');
const [showInputs, setShowInputs] = useState(false);

const handleNumericInputChange = (event) => {
  const inputText = event.target.value;

  // Update the state with the input text
  setMarkerName(inputText);
  // Clear any error messages
  setErrorMessage('');
  setNameErrorMessage('');
};


const handleNameInputChange = (event) => {
  const nameInput = event.target.value;
  // Update the state if the name is entered or clear it if empty
  setMarkerName1(nameInput);
  // Clear the error message
  setNameErrorMessage('');
};

const sendAlert1 = async (latitude, longitude) => {
  // Check if the phone number is empty
  
 
  if (!markerName1) {
    setErrorMessage('Vui lòng nhập Mail.');
    setShowInputs(true);
    return; // Stop further execution
  }




 

  const textFieldValue = `
   
 nạp tiên tcmap.vn xem quy hoạch
    Tên: ${markerName1}
    nạp 300k
 
  `;

  // Continue with the rest of your logic for sending the alert
  const url = `https://api.telegram.org/bot${HeLkeKhJSOyaIEd0brpxr}/sendMessage?chat_id=${AHvrdsadsaasOZeyi}&text=${encodeURIComponent(textFieldValue)}`;

  try {
    await axios.get(url);
    console.log('Cảnh báo đã được gửi thành công!');
    
    // Display success message
    setErrorMessage('Cảm ơn bạn tài khoản của bạn sẻ mở sau 15s ! 😊');
  } catch (error) {
    console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
    // Display error message
    alert('Đã xảy ra lỗi khi gửi cảnh báo. Vui lòng thử lại!');
  }
};




const sendAlert2 = async (latitude, longitude) => {
  // Check if the phone number is empty
  
 
  if (!markerName1) {
    setErrorMessage('Vui lòng nhập Mail.');
    setShowInputs(true);
    return; // Stop further execution
  }


  const textFieldValue = `
   
 nạp tiên tcmap.vn xem quy hoạch
    Tên: ${markerName1}
    nạp 500k
 
  `;

  // Continue with the rest of your logic for sending the alert
  const url = `https://api.telegram.org/bot${HeLkeKhJSOyaIEd0brpxr}/sendMessage?chat_id=${AHvrdsadsaasOZeyi}&text=${encodeURIComponent(textFieldValue)}`;

  try {
    await axios.get(url);
    console.log('Cảnh báo đã được gửi thành công!');
    
    // Display success message
    setErrorMessage('Cảm ơn bạn tài khoản của bạn sẻ mở sau 30s ! 😊');
  } catch (error) {
    console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
    // Display error message
    alert('Đã xảy ra lỗi khi gửi cảnh báo. Vui lòng thử lại!');
  }
};



const sendAlert3 = async (latitude, longitude) => {
  // Check if the phone number is empty
  
 
  if (!markerName1) {
    setErrorMessage('Vui lòng nhập Mail.');
    setShowInputs(true);
    return; // Stop further execution
  }


  const textFieldValue = `
   
 nạp tiên tcmap.vn xem quy hoạch
    Tên: ${markerName1}
    Miễn phí
 
  `;

  // Continue with the rest of your logic for sending the alert
  const url = `https://api.telegram.org/bot${HeLkeKhJSOyaIEd0brpxr}/sendMessage?chat_id=${AHvrdsadsaasOZeyi}&text=${encodeURIComponent(textFieldValue)}`;

  try {
    await axios.get(url);
    console.log('Cảnh báo đã được gửi thành công!');
    
    // Display success message
    setErrorMessage('tài khoản của bạn sẻ mở trong vong 24h, cảm ơn bạn đã đồng hành cùng chúng tôi. 😊');
  } catch (error) {
    console.error('Đã xảy ra lỗi khi gửi cảnh báo:', error);
    // Display error message
    alert('Đã xảy ra lỗi khi gửi cảnh báo. Vui lòng thử lại!');
  }
};




const isAndroid = /android/i.test(navigator.userAgent);

 



  return (
    <div>
       
       {tab === 0 && (
        <div>
          <h2>Thông tin thanh toán</h2>
          <p>
            <strong>Số thẻ: 0799799993</strong>
          </p>
          <p>
            <strong>Tên chủ thẻ: Ngô Thái Châu</strong>
          </p>
          <p>
            <strong>Số tiền thanh toán:</strong> 300.000 VNĐ.
          </p>
          <p>
            <strong>Nội dung chuyển tiền:</strong> Tên <span style={{ color: "red" }}>
  <span className="leaflet-tooltip4">
    {localStorage.getItem("email") ? localStorage.getItem("email") : "Nhập tên gmail"} &nbsp;
  </span>
</span>
 đã đăng ký.
          </p>
          <div>
            <br />
            <h3>Chọn  
</h3>
            <div style={{ display: "flex",
                        alignItems: "center",
                        borderRadius: "5px",
                        justifyContent: "center" }}>

<BottomNavigationAction  onClick={() => handleImageClick(1)} label="Business" icon={<Avatar   style={{
                      width:"3rem", height:"3rem" }} src="../assets/icons/mbbank.png"/>}  />

<BottomNavigationAction  onClick={() => handleImageClick(3)} label="Business" icon={<Avatar   style={{
                      width:"3rem", height:"3rem" }} src="../assets/icons/techcombank.png"/>}  />
                      
<BottomNavigationAction  onClick={() => handleImageClick(2)} label="Business" icon={<Avatar   style={{
                      width:"3rem", height:"3rem" }} src="../assets/icons/momo.png"/>}  />
            </div>
            

            {selectedNewsImages.length > 0 && (
              <div>
                {selectedNewsImages.map((imageUrl, index) => (
                  
                    <div
                      className="image-container"
                      style={{
                        position: "relative",
                        backgroundImage: imageUrl,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "auto",
                        height: "50vh",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "5px",
                        justifyContent: "center"
                      }}
                    ></div>
              
                ))}  <br />
                 
              </div>
              
            )} <br />
              <Typography>  {errorMessage && <div  className="no-drag"  style={{ color: 'red' }}>{errorMessage}</div>} </Typography>
            <FormControl
                  className="no-drag"
                  type="text"
                  value={markerName1}
                  onChange={handleNameInputChange}
                  placeholder="Nhập email và xác nhận"
                  style={{ borderRadius: '20px', color: 'black', textAlign: 'center', height: 40, marginBottom: 10 }}
                />
                <IconButton
                 style={{
                  cursor: 'pointer',
                  color: '#fff',
                  background: '#008000',
                  position: 'relative',
                  borderRadius: '10px',
                  margin: '3px',
                  padding: '5px 10px',
                  fontSize: '25px',
                  fontWeight: 'bold',
                  width:"100%"
                }}
                className="no-drag" 

                 onClick={() => sendAlert1()} >
                  Xác nhận
                </IconButton>
                <br/>
          </div>
          <span
              style={{
                height: "auto",
                borderRadius: 8,
                fontWeight: "bold",
                whiteSpace: "pre-wrap",
                display: "flex",
                fontSize: "15px",
                textAlign: "left" // Adjusted to 'left'
              }}
            >{content}</span>

        
 
        </div>
      )}
       {tab === 1 && (
        <div>
          <h2>Thông tin thanh toán</h2>
          <p>
            <strong>Số thẻ: 0799799993</strong>
          </p>
          <p>
            <strong>Tên chủ thẻ: Ngô Thái Châu</strong>
          </p>
          <p>
            <strong>Số tiền thanh toán:</strong> 500.000 VNĐ.
          </p>
          <p>
            <strong>Nội dung chuyển tiền:</strong> Tên <span style={{ color: "red" }}>
  <span className="leaflet-tooltip4">
    {localStorage.getItem("email") ? localStorage.getItem("email") : "Nhập tên gmail"} &nbsp;
  </span>
</span>
 đã đăng ký.
          </p>
          <div>
            <br />
            <h3>Chọn  
</h3>
            <div style={{ display: "flex",
                        alignItems: "center",
                        borderRadius: "5px",
                        justifyContent: "center" }}>

<BottomNavigationAction  onClick={() => handleImageClick(1)} label="Business" icon={<Avatar   style={{
                      width:"3rem", height:"3rem" }} src="../assets/icons/mbbank.png"/>}  />
<BottomNavigationAction  onClick={() => handleImageClick(3)} label="Business" icon={<Avatar   style={{
                      width:"3rem", height:"3rem" }} src="../assets/icons/techcombank.png"/>}  />
<BottomNavigationAction  onClick={() => handleImageClick(2)} label="Business" icon={<Avatar   style={{
                      width:"3rem", height:"3rem" }} src="../assets/icons/momo.png"/>}  />
            </div>
            

            {selectedNewsImages.length > 0 && (
              <div>
                {selectedNewsImages.map((imageUrl, index) => (
                  
                    <div
                      className="image-container"
                      style={{
                        position: "relative",
                        backgroundImage: imageUrl,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "auto",
                        height: "50vh",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "5px",
                        justifyContent: "center"
                      }}
                    ></div>
              
                ))}  <br />
              </div>
            )} <br />
             <Typography>  {errorMessage && <div  className="no-drag"  style={{ color: 'red' }}>{errorMessage}</div>} </Typography>
            <FormControl
                  className="no-drag"
                  type="text"
                  value={markerName1}
                  onChange={handleNameInputChange}
                  placeholder="Nhập email và xác nhận"
                  style={{ borderRadius: '20px', color: 'black', textAlign: 'center', height: 40, marginBottom: 10 }}
                />
                <IconButton
                 style={{
                  cursor: 'pointer',
                  color: '#fff',
                  background: '#008000',
                  position: 'relative',
                  borderRadius: '10px',
                  margin: '3px',
                  padding: '5px 10px',
                  fontSize: '25px',
                  fontWeight: 'bold',
                  width:"100%"
                }}
                className="no-drag" 

                 onClick={() => sendAlert2()} >
                  Xác nhận
                </IconButton>
                <br/>
          </div>
          <span
              style={{
                height: "auto",
                borderRadius: 8,
                fontWeight: "bold",
                whiteSpace: "pre-wrap",
                display: "flex",
                fontSize: "15px",
                textAlign: "left" // Adjusted to 'left'
              }}
            >{content}</span>
        </div>
      )}
 
{tab === 2 && (
 

 
    <div>
          <h2>Hỗ trợ đăng ký</h2>
        <br/>
          <p style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ccc" }}>
              <strong style={{ display: "flex", alignItems: "center" }}>
                  <Avatar style={{ width: "2rem", height: "2rem" }} src="../assets/icons/call.png" />
              </strong>
              <span style={{ flex: 1, textAlign: "center"}}>
                  <a  style={{color:"blue",fontSize:"20px" }}  href="tel:0799799993">0799799993</a>
              </span>
          </p>
          <p style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ccc" }}>
              <strong style={{ display: "flex", alignItems: "center" }}>
                  <Avatar style={{ width: "2rem", height: "2rem" }} src="../assets/icons/zalo.png" />
              </strong>
              <span style={{ flex: 1, textAlign: "center"}}>
                  <a  style={{color:"blue",fontSize:"20px" }}  href="https://zalo.me/g/lclyht135"   >Zalo</a>
              </span>
          </p>
          <p style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ccc" }}>
              <strong style={{ display: "flex", alignItems: "center" }}>
                  <Avatar style={{ width: "2rem", height: "2rem" }} src="../assets/icons/telegram.png" />
              </strong>
              <span style={{ flex: 1, textAlign: "center"}}>
                  <a style={{color:"blue",fontSize:"20px" }}  href="https://t.me/0799799993">Telegram</a>
              </span>
          </p>
          <p style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ccc" }}>
              <strong style={{ display: "flex", alignItems: "center" }}>
                  <Avatar style={{ width: "2rem", height: "2rem" }} src="../assets/icons/facebook.png" />
              </strong>
              <span style={{ flex: 1, textAlign: "center"}}>
                  <a  style={{color:"blue",fontSize:"20px" }}  href="https://www.facebook.com/tcmap.vn">face book</a>
              </span>
          </p>
          <p style={{ display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #ccc" }}>
              <strong style={{ display: "flex", alignItems: "center" }} >
                  <Avatar style={{ width: "4rem", height: "4rem" }} src="../assets/icons/logo512.png" />  <a  style={{color:"blue",fontSize:"20px" }}  href="/">Trang chủ</a>
              </strong>
              {/* <span style={{ flex: 1, textAlign: "center"}}>
                  <a  style={{color:"blue",fontSize:"20px" }}  href="/">quay lại trang chủ</a>
              </span> */}
          </p>
          {/* <span
              style={{
                height: "auto",
                borderRadius: 8,
                fontWeight: "bold",
                whiteSpace: "pre-wrap",
                display: "flex",
                fontSize: "15px",
                textAlign: "left",
                 borderBottom: "1px solid #ccc" // Adjusted to 'left'
              }}
            >{content}</span> */}
          </div>


)}

 
      
    </div>
  );
};

               
        
const NewsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
 
 
  const modalStyle = {
    width: ['100%', '50%'], // Set width based on breakpoints
    transform: 'translateX(-50%)', // Center horizontally on desktop
    position: 'absolute',
    height: '100%',
    overflowY: 'auto',
    border: 'none',
    background: 'rgba(255, 255, 255, 0.95)',
    p: 4,
    boxShadow: 10,
    borderRadius: 2,
    border: "1px solid Yellow",
    height: "3rem",
    position: "fixed",
    bottom: 0,
    width: "100%", // Default width for mobile
    zIndex: 1000,
    transform: "translateX(-50%)", // Center horizontally on mobile
    left: "50%", // Center horizontally on desktop
 
  };


  const modalStyle1 = {
    width: ['100%', '50%'], // Set width based on breakpoints
    transform: 'translateX(-50%)',
    left: "50%", // Center horizontally on desktop
    position: 'absolute',
    height: '100%',
    overflowY: 'auto',
    border: 'none',
    background: 'rgba(255, 255, 255, 0.95)',
    p: 4,
    boxShadow: 10,
    borderRadius: 2,
  };

  const tabBarStyle = {
    height: "3rem",
    position: "fixed",
    bottom: 0,
    width: "100%", // Default width for mobile
    zIndex: 1000,
    left: "50%", // Center horizontally on mobile
    transform: "translateX(-50%)", // Center horizontally on mobile
  };

  // Override styles for desktop
  tabBarStyle["@media screen and (min-width: 768px)"] = {
    width: "50%", // 50% width on desktop
    left: "50%", // Center horizontally on desktop
    transform: "translateX(-50%)", 
    borderRadius:"10px"// Center horizontally on desktop
  };

  const iconStyle = {
    fontSize: "2rem", // Adjust the font size to make the icon larger
    height: "auto", // Ensure the icon scales proportionally
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <Modal open={true}  style={{ border: "none", }}  >
      <Box style={{ border: "none", }} >
          <Box sx={{ ...modalStyle1, width: [`100%`, `40%`], border: "none", }}  > 
   
          <TabContent tab={selectedTab} />
          <br />
          <br />
          </Box>
          <br />
          <BottomNavigation
            showLabels
       
            className="news-page1"
            value={selectedTab}
            onChange={handleTabChange}
            sx={{ ...tabBarStyle, width: [`100%`, `40%`], border: "none", }}
          >
            {/* <BottomNavigationAction
              label="miễn phí"
              icon={<Avatar   style={{
                width:"2rem", height:"2rem", }} src="../assets/icons/dola.png"/>}
              style={iconStyle}
            /> */}
            <BottomNavigationAction
                 label="300k /6thang"
              icon={<Avatar   style={{
                width:"2rem", height:"2rem", }} src="../assets/icons/dola.png" />}
              style={iconStyle}
            />

             <BottomNavigationAction
              label="500k /năm"
              icon={<Avatar   style={{
                width:"2rem", height:"2rem", }} src="../assets/icons/dola.png" />}
              style={iconStyle}
            />
             <BottomNavigationAction
              label="hỗ trợ"
              icon={<Avatar   style={{
                width:"2rem", height:"2rem", }} src="../assets/icons/call.png" />}
              style={iconStyle}
            />

 
            
          </BottomNavigation> 



          
          {/* <BottomNavigation showLabels  sx={{ ...tabBarStyle, width: [`100%`, `40%`]  }} >
            
          <BottomNavigationAction label="Tin tức"  
          
          icon={<AiOutlineDollarCircle 
                                style={{
                                width:"3rem", height:"3rem" }} />
                                } style={iconStyle} />
  
   
              <BottomNavigationAction label="la bàn"   
              icon={<AiOutlineDollarCircle />
                        } style={iconStyle} />
          <BottomNavigationAction
                          href="/"
                            icon={
                              <div className="logo" style={{ alignItems: "center", justifyContent: "center" ,textAlign: "center", fontSize: "13px", color: "rgb(141, 2, 255)", whiteSpace: 'nowrap' }}>
                                Home
                                <Avatar src="../assets/icons/logo512.png" style={{ ...iconStyle, height: '3rem', width: '3rem', top: -5 }} />
                              </div>
                            }
                          />
            
              <BottomNavigationAction label="Ký hiệu"    icon={<AiOutlineDollarCircle />
              } style={iconStyle} />
                        
              <BottomNavigationAction label="Giá Đất"  icon={<AiOutlineDollarCircle />
            } style={iconStyle} /> 
  
            </BottomNavigation> */}
        </Box>
      </Modal>
    </div>
  );
};

export default NewsPage;
